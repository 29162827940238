const checkOrderFromRestaurants = (restaurants: any, order: any) => {
  if (restaurants && restaurants?.length > 0) {
    const restIds = restaurants.map((rest: any) => rest.id);

    return restIds.includes(order.restaurant);
  }
  return false;
};

export default checkOrderFromRestaurants;
