import types from '../types';

export const addLoading = (value: string) => ({ type: types.ADD_LOADING, payload: value });
export const removeLoading = (value: string) => ({ type: types.REMOVE_LOADING, payload: value });

export const addError = (value: { [key: string]: string }) => ({
  type: types.ADD_ERROR,
  payload: value,
});
export const removeError = (value: string) => ({ type: types.REMOVE_ERROR, payload: value });

export const resetState = () => ({ type: types.RESET_STATE });

export { default as getRestaurantData } from './getRestaurantData';
export { default as getArticles } from './getArticles';
export { default as getCategories } from './getCategories';
export { default as getArticleOptions } from './getArticleOptions';
export { default as getArticleOptionNames } from './getArticleOptionNames';
export { default as getRestaurantPanelUsers } from './getRestaurantUsers';
export { default as getRestaurantAllowedUrls } from './getRestaurantAllowedUrls';
export { default as getTags } from './getTags';
export { default as getCurrentPictureStorage } from './getCurrentPictStorage';
export { default as getCommonPictureStorage } from './getCommonPictStorage';
