import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { toast } from 'react-toastify';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { Button, InputField } from './index';
import { API } from '../api';
import { getTags } from '../store/actions';

const TagModal: FC<any> = (props) => {
  const [tag, setTag] = useState<any>('');
  const [tagId, setTagId] = useState<any>(false);
  const restId = localStorage.getItem('rest_id') as string;

  const asyncDispatch: ThunkDispatch<StoreRoot, any, AnyAction> = useDispatch();

  useEffect(() => {
    setTagId(props.tagId);
    if (typeof props.tag === 'undefined') {
      setTag('');
    } else {
      setTag(props.tag.name || '');
    }
  }, [props]);

  const changeOptionName = (e: any) => {
    setTag(e.target.value);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const tagname = e.target.elements.tagname.value;
    const id = localStorage.getItem('rest_id');

    if (props.tag === 'new') {
      await API.restaurantApi
        .createTag(
          {
            name: tagname,
          },
          id as string
        )
        .then((response) => {
          toast.success('Tag Added Successfully!', { containerId: 'B' });
          asyncDispatch(getTags(restId)).then(() => props.toggle());
        });
    } else {
      await API.restaurantApi
        .updateTag(
          {
            name: tagname,
          },
          tagId as string
        )
        .then(() => {
          toast.success('Tag Updated Successfully!', { containerId: 'B' });
          asyncDispatch(getTags(restId)).then(() => props.toggle());
        });
    }
  };

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    props.toggle && props.toggle();
  };

  return (
    <Modal isOpen={props.modal} toggle={props.toggle}>
      <ModalHeader toggle={props.toggle}>
        {props.tag === 'new' ? 'Voeg tag toe' : 'Bewerk tag'}
      </ModalHeader>
      <form onSubmit={(e) => handleSubmit(e)}>
        <ModalBody>
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group text-left">
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label htmlFor="" className="small">
                  Naam
                </label>
                <InputField
                  required
                  name="tagname"
                  placeholder="naam"
                  value={tag}
                  onChange={(e: any) => changeOptionName(e)}
                />
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button name="Cancel" btnLongWidth={false} onClick={handleCancel} type="button" />
          <Button
            name="Save"
            type="submit"
            dangerButton={true}
            btnLongWidth={false}
            // onClick={props.toggle}
          />
        </ModalFooter>
      </form>
    </Modal>
  );
};
export default TagModal;
