import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import React, { FC, useEffect, useState } from 'react';
import Select from 'react-select';
import Switch from 'react-switch';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import './style.css';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { Button, InputField } from './index';
import { API } from '../api';
import { getCategories, getArticles, getTags } from '../store/actions';

let isSold: any = false;

const DELIVERY_OPTIONS = [
  {
    id: 1,
    label: 'Delivery',
    value: 'DELIVERY',
  },
  {
    id: 2,
    label: 'Pickup',
    value: 'PICKUP',
  },
  {
    id: 3,
    label: 'Qr',
    value: 'QR',
  },
];

const TAX_OPTIONS = [
  {
    id: 1,
    label: '0%',
    value: 0,
  },
  {
    id: 2,
    label: '6%',
    value: 6,
  },
  {
    id: 3,
    label: '12%',
    value: 12,
  },
  {
    id: 4,
    label: '19%',
    value: 19,
  },
  {
    id: 5,
    label: '21%',
    value: 21,
  },
];

const EditArticleModel: FC<any> = (props) => {
  const [articleOption, setArticleOption] = useState<any>('');
  const [articleOptionid, setArticleOptionid] = useState<any>('');
  const [optionname, setoptionname] = useState<any>('');
  const [selectedCategory, setselectedCategory] = useState<any>(null);
  const [selectedCategoryName, setselectedCategoryName] = useState<any>('');
  const [multipaloptions, setmultipaloptions] = useState<any>([]);
  const [isSoldOut, setSoldOut] = useState(false);
  const [combinedingredients, setcombinedingredients] = useState<any>('');
  const [finalcombinedingredients, setfinalcombinedingredients] = useState('');
  const [tagname, setTagName] = useState<any>('');
  const [multipleTags, setMultipleTags] = useState<any>([]);
  const [selectedTagName, setSelectedTagName] = useState<any>('');
  const [deliveryType, setDeliveryType] = useState<any>(null);
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false);
  const [isAssembled, setIsAssembled] = useState<boolean>(false);
  const [optionArticles, setOptionArticles] = useState<any>([]);
  const [selectedAddIncludedArt, setSelectedAddIncludeArt] = useState<any>(null);
  const [includedArticles, setIncludedArticles] = useState<any[]>([]);

  const [pickuptaxField, setPickuptaxField] = useState<any>(null);
  const [dineintaxField, setDineintaxField] = useState<any>(null);

  const newArray = [] as any;

  const id = localStorage.getItem('rest_id') as string;

  const categorylist = useSelector(({ categories }: StoreRoot) => categories);
  const restaurant_articles = useSelector(({ articles }: StoreRoot) => articles);
  const tags = useSelector(({ tags }: StoreRoot) => tags);

  const dispatch = useDispatch();

  const asyncDispatch: ThunkDispatch<StoreRoot, any, AnyAction> = useDispatch();

  useEffect(() => {
    try {
      if (props.article) {
        const data = props.article.ingredient;
        const combined = data.map((el: any) => el.name).join(',');
        setcombinedingredients(combined);
      }
    } catch (err) {
      setcombinedingredients('');
    }

    dispatch(getCategories(id));
    dispatch(getTags(id));
    if (!props.article) {
      setArticleOption([]);
    } else {
      setArticleOption(props.article);
      setArticleOptionid(props.articleId);
      setSelectedAddIncludeArt(null);
      if (props.articleId === 'new') {
        // Preselect all delivery types by default
        setDeliveryType(DELIVERY_OPTIONS);

        setPickuptaxField(0);
        setDineintaxField(0);

        setselectedCategoryName('');
        setselectedCategory(null);
        setmultipaloptions([]);
        setMultipleTags([]);
        setIsAssembled(false);
        setIncludedArticles([]);
      } else if (props.article) {
        if (props.article?.category?.name) {
          setselectedCategoryName(props.article.category.name);
          const category = {
            value: props.article.category.name,
            label: props.article.category.name,
          };
          setselectedCategory(category);
        } else {
          setselectedCategory(null);
        }
        setSoldOut(props.article.sold_out);
        if (props.article?.delivery_type) {
          const article_types = props.article.delivery_type;
          const current_types = DELIVERY_OPTIONS.filter((item: any) =>
            article_types.includes(item.value)
          );

          setDeliveryType(current_types);
        } else {
          setDeliveryType(null);
        }

        if (props.article?.dineintax) {
          const dineinTax_var = props.article.dineintax;
          const current_tax = TAX_OPTIONS.filter((item: any) => dineinTax_var == item.value);

          setDineintaxField(current_tax);
        } else {
          setDineintaxField(null);
        }

        if (props.article?.pickuptax) {
          const pickupTax_var = props.article.pickuptax;
          const current_tax = TAX_OPTIONS.filter((item: any) => pickupTax_var == item.value);

          setPickuptaxField(current_tax);
        } else {
          setPickuptaxField(null);
        }

        if (props.article?.is_assembled) {
          setIsAssembled(props.article.is_assembled);
          const article_included = props.article.included_articles?.map((item: any) => ({
            id: item.id,
            price: item.price,
            label: item.articlename,
            value: `${item.articlename}#${item.id}`,
            descrition: item.description,
            image: item?.image?.image || '',
            count: item.count,
          }));
          setIncludedArticles(article_included);
        } else {
          setIsAssembled(false);
          setIncludedArticles([]);
        }
      }
    }
  }, [props]);

  const getArticleOptionName = async () => {
    await API.restaurantApi.getArticleOptionNameModified(id as string).then((res) => {
      setoptionname(res.data);
    });
  };

  const getPostDataForArticle = async () => {
    await API.restaurantApi.getPostDataForArticle(props.articleId as string).then((response) => {
      if (response.data.length > 0) {
        if (response.data && response.data[0].Articloption) {
          response.data.forEach((element: any) => {
            if (element && element.Articloption) {
              const newObj = {
                value: element.Articloption.label,
                label: element.Articloption.label,
              };
              newArray.push(newObj);
            }
          });

          setmultipaloptions(newArray);
        }
      }
    });
  };

  useEffect(() => {
    getArticleOptionName();
    if (props.articleId > 0) {
      setmultipaloptions([]);
      getPostDataForArticle();
    }
  }, [props, props.articleId]);

  const handleChangeCategory = (e: any) => {
    const category = [{ value: e.value, label: e.value }];
    setselectedCategoryName(e.value);
    setselectedCategory(category);
  };

  const changearticledata = (e: any) => {
    setArticleOption({
      ...articleOption,
      [e.target.name]: [e.target.value],
    });
  };

  const changeingredientdata = (e: any) => {
    setcombinedingredients(e.target.value);
    setfinalcombinedingredients(e.target.value);
  };

  const refresh = (e: any) => {
    e.preventDefault();
    props.setModal(!props.modal);
    setmultipaloptions([]);
    setMultipleTags([]);
    setIsAssembled(false);
    setSelectedAddIncludeArt(null);
    setIncludedArticles([]);
  };

  const changeOptionName = (e: any) => {
    if (e === null) {
      setmultipaloptions([]);
    } else {
      setmultipaloptions(e);
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const articleid = articleOptionid;
    const restid = localStorage.getItem('rest_id') as string;
    const ingredient = finalcombinedingredients;
    const ingredients = e.target.elements.ingredient.value;
    const articlename = e.target.elements.articlename.value;
    const price = e.target.elements.price.value;
    const category = selectedCategoryName;
    const pickuptax = e.target.elements.pickuptax.value;
    const dineintax = e.target.elements.dineintax.value;
    const description = e.target.elements.description.value;
    const index = '0';

    const submitoptionname: any = [];

    if (multipaloptions.length > 0) {
      multipaloptions.forEach((item: any) => {
        submitoptionname.push(item.value);
      });
    }

    const submitTagNames: any = [];

    if (multipleTags.length > 0) {
      multipleTags.forEach((item: any) => {
        submitTagNames.push(item.value);
      });
    }

    const formData = new FormData();

    formData.append('articleid', articleid);
    formData.append('restid', restid);
    formData.append('articlename', articlename);
    formData.append('price', price);
    formData.append('category', category);
    formData.append('pickuptax', pickuptax);
    formData.append('dineintax', dineintax);
    formData.append('description', description);
    formData.append('ingredient', ingredient);
    formData.append('sold_out', isSold);
    formData.append('index', index);

    if (!selectedCategoryName) {
      toast.error('Select category', { containerId: 'B' });
      return;
    }

    if (!deliveryType || deliveryType.length === 0) {
      formData.append('delivery_type', '');
    } else {
      deliveryType.map((item: any) => formData.append('delivery_type', item.value));
    }

    if (!submitTagNames || submitTagNames.length === 0) {
      formData.append('tags', '');
    }
    if (submitTagNames.length > 0) {
      formData.append('tags', submitTagNames);
    }

    if (submitoptionname.length > 0) {
      formData.append('optionNames', submitoptionname);
    }

    if (isAssembled && includedArticles.length > 0) {
      formData.append('is_assembled', 'true');
      let included_ids: any[] = [];
      includedArticles.forEach((item: any) => {
        const curr_ids = Array(item.count).fill(item.id);
        included_ids = [...included_ids, ...curr_ids];
      });
      included_ids.forEach((item: any) => {
        formData.append('included_articles', item);
      });
    }

    setDisableSubmit(true);

    if (articleOptionid === 'new') {
      if (!isAssembled) {
        await API.restaurantApi.createArticle(formData, restid).then((response) => {
          toast.success('Added Successfully!', { containerId: 'B' });
          asyncDispatch(getArticles(id)).then(() => {
            props.setModal(!props.modal);
            setDisableSubmit(false);
          });
          setselectedCategory(null);
          setmultipaloptions([]);
          setMultipleTags([]);
          setIsAssembled(false);
          setIncludedArticles([]);
          setSelectedAddIncludeArt(null);
        });
      } else {
        await API.restaurantApi.createAssembledArticle(formData, restid).then((response) => {
          toast.success('Added Successfully!', { containerId: 'B' });
          asyncDispatch(getArticles(id)).then(() => {
            props.setModal(!props.modal);
            setDisableSubmit(false);
          });
          setselectedCategory(null);
          setmultipaloptions([]);
          setMultipleTags([]);
          setIsAssembled(false);
          setIncludedArticles([]);
          setSelectedAddIncludeArt(null);
        });
      }
    } else {
      if (!isAssembled) {
        await API.restaurantApi
          .updateArticle(formData, articleOptionid, restid)
          .then((response) => {
            toast.success('Updated Successfully!', { containerId: 'B' });
            asyncDispatch(getArticles(id)).then(() => {
              props.setModal(!props.modal);
              setDisableSubmit(false);
            });
            setselectedCategory(null);
            setmultipaloptions([]);
            setMultipleTags([]);
          });
      } else {
        await API.restaurantApi
          .updateAssembledArticle(formData, articleOptionid, restid)
          .then((response) => {
            toast.success('Updated Successfully!', { containerId: 'B' });
            asyncDispatch(getArticles(id)).then(() => {
              props.setModal(!props.modal);
              setDisableSubmit(false);
            });
            setselectedCategory(null);
            setmultipaloptions([]);
            setMultipleTags([]);
            setIsAssembled(false);
            setIncludedArticles([]);
          });
      }
    }
  };

  isSold = isSoldOut;

  const setCurrentTags = () => {
    let options: any = [];
    if (props?.article?.tags) {
      options = props.article.tags.map((item: any) => ({
        id: item.id,
        value: item.name,
        label: item.name,
      }));
    }

    setMultipleTags(options);
  };

  useEffect(() => {
    setCurrentTags();
  }, [props]);

  const getTagsOptions = () => {
    let options: any = [];
    if (tags) {
      options = tags.map((item: any) => ({
        id: item.id,
        value: item.name,
        label: item.name,
      }));
    }

    return options;
  };

  const changeTags = (e: any) => {
    if (e === null) {
      setMultipleTags([]);
    } else {
      setMultipleTags(e);
    }
  };

  const changeDeliveryType = (val: any) => {
    setDeliveryType(val);
  };

  const changePickupTaxValue = (val: any) => {
    setPickuptaxField(val);
  };

  const changeDineinTaxValue = (val: any) => {
    setDineintaxField(val);
  };

  const toggleAssembled = (val: any) => {
    setIsAssembled(val);
  };

  useEffect(() => {
    if (restaurant_articles) {
      const not_assembled = restaurant_articles.filter((item: any) => !item.is_assembled);
      const options_articles = not_assembled.map((item: any) => ({
        id: item.id,
        price: item.price,
        label: item.articlename,
        value: `${item.articlename}#${item.id}`,
        descrition: item.description,
        image: item?.image?.image || '',
        count: 1,
      }));
      setOptionArticles(options_articles);
    }
  }, [restaurant_articles]);

  const handleSelectIncluded = (val: any) => {
    setSelectedAddIncludeArt(val);
  };

  const handleAddIncludedArticle = (e: any) => {
    const already_included_idx = includedArticles.findIndex(
      (item: any) => item.id === selectedAddIncludedArt.id
    );
    if (already_included_idx !== -1) {
      toast.error('Article already included', { containerId: 'B' });
      return;
    }
    setIncludedArticles([...includedArticles, selectedAddIncludedArt]);
  };

  const handleRemoveIncludedArticle = (id: any) => {
    let new_included = includedArticles.filter((item: any) => item.id !== id);
    setIncludedArticles(new_included);
  };

  const handleChangeIncludedCount = (e: any, id: any) => {
    const new_count = e.target.value;
    const new_included = includedArticles.map((item: any) =>
      item.id === id
        ? {
            ...item,
            count: parseInt(new_count),
          }
        : item
    );
    setIncludedArticles(new_included);
  };

  const renderIncluded = () => {
    return includedArticles.map((item: any) => (
      <li key={item.value} className="list-group-item included-article-item">
        <img width={32} height={32} src={item.image} />{' '}
        <span className="articlename">{item.label}</span>
        <InputField
          name="included_count"
          value={item.count}
          onChange={(e: any) => handleChangeIncludedCount(e, item.id)}
          placeholder="Enter count"
          disabled={true}
          type="number"
          min={1}
          max={100}
        />
        <i
          className="fa fa-times-circle"
          aria-hidden="true"
          onClick={(e) => handleRemoveIncludedArticle(item.id)}
        ></i>
      </li>
    ));
  };

  return (
    <Modal className="modal-lg" isOpen={props.modal} toggle={(e: any) => props.toggle(e)}>
      {articleOption && (
        <form onSubmit={(e) => handleSubmit(e)}>
          <ModalHeader toggle={(e: any) => refresh(e)}>
            {articleOptionid === 'new' ? 'Voeg product toe' : 'Beheer producten'}
          </ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col-sm-12">
                <div className="form-group text-left">
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label htmlFor="is_assembled" className="small">
                    <b>Assembled product:</b>
                  </label>
                  <br />
                  <Switch
                    onChange={toggleAssembled}
                    onColor="#dc3545"
                    name="is_assembled"
                    checked={isAssembled}
                    disabled={articleOptionid === 'new' ? false : true}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-4">
                <div className="form-group text-left">
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label htmlFor="" className="small">
                    <b>Naam product:</b>
                  </label>
                  <InputField
                    name="articlename"
                    value={articleOption ? articleOption.articlename || '' : ''}
                    onChange={(e: any) => changearticledata(e)}
                    placeholder="Enter Article"
                    required
                  />
                </div>
              </div>
              <div className="col-sm-4">
                <div className="form-group text-left">
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label htmlFor="" className="small">
                    <b>Prijs:</b>
                  </label>
                  <InputField
                    name="price"
                    value={articleOption ? articleOption.price : ''}
                    onChange={(e: any) => changearticledata(e)}
                    placeholder="Enter Prijs"
                    required
                    type="number"
                  />
                </div>
              </div>
              <div className="col-sm-4">
                <div className="form-group text-left">
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label htmlFor="" className="small">
                    <b>Categorie:</b>
                  </label>
                  <Select
                    name="category"
                    defaultValue={selectedCategory}
                    options={categorylist}
                    value={selectedCategory}
                    onChange={(e) => handleChangeCategory(e)}
                  />
                </div>
              </div>

              <div className="col-sm-4">
                <div className="form-group text-left">
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label htmlFor="" className="small">
                    <b>BTW % bij pickup/levering:</b>
                  </label>
                  {/* <InputField
                    name="pickuptax"
                    value={articleOption ? articleOption.pickuptax || '' : ''}
                    onChange={(e: any) => changearticledata(e)}
                    placeholder="Tax rate in percentage"
                  /> */}
                  <Select
                    name="pickuptax"
                    options={TAX_OPTIONS}
                    value={pickuptaxField}
                    onChange={(e) => changePickupTaxValue(e)}
                    isMulti={false}
                    required
                  />
                </div>
              </div>

              <div className="col-sm-4">
                <div className="form-group text-left">
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label htmlFor="" className="small">
                    <b> BTW % bij ter plaatse:</b>
                  </label>
                  {/* <InputField
                    name="dineintax"
                    value={articleOption ? articleOption.dineintax || '' : ''}
                    placeholder="Tax rate in percentage"
                    onChange={(e: any) => changearticledata(e)}
                  /> */}
                  <Select
                    name="dineintax"
                    options={TAX_OPTIONS}
                    value={dineintaxField}
                    onChange={(e) => changeDineinTaxValue(e)}
                    isMulti={false}
                    required
                  />
                </div>
              </div>
              <div className="col-sm-4">
                <div className="form-group text-left">
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label htmlFor="" className="small">
                    <b>Product Options:</b>
                  </label>
                  <Select
                    // defaultValue={multipaloptions ? multipaloptions : ''}
                    name="optionnames"
                    options={optionname}
                    value={multipaloptions}
                    onChange={(e) => changeOptionName(e)}
                    isMulti={true}
                    required
                  />
                </div>
              </div>

              <div className="col-sm-4">
                <div className="form-group text-left">
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label htmlFor="" className="small">
                    <b> Voeg Ingredienten toe (comma seprated):</b>
                  </label>
                  <InputField
                    name="ingredient"
                    id="ingredient"
                    onChange={(e: any) => changeingredientdata(e)}
                    value={articleOption ? combinedingredients : ''}
                  />
                </div>
              </div>

              <div className="col-sm-4">
                <div className="form-group text-left">
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label htmlFor="delivery_type" className="small">
                    <b>Delivery type</b>
                  </label>
                  <Select
                    name="delivery_type"
                    options={DELIVERY_OPTIONS}
                    value={deliveryType}
                    onChange={(e) => changeDeliveryType(e)}
                    isMulti={true}
                    required
                  />
                </div>
              </div>

              <div className="col-sm-4">
                <div className="form-group text-left">
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label htmlFor="tagnames" className="small">
                    <b>Tags</b>
                  </label>
                  <Select
                    name="tagnames"
                    options={getTagsOptions()}
                    value={multipleTags}
                    onChange={(e) => changeTags(e)}
                    isMulti={true}
                    required
                  />
                </div>
              </div>

              <div className="col-sm-8">
                <div className="form-group text-left">
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label htmlFor="" className="small">
                    <b>Meer informatie:</b>
                  </label>
                  <textarea
                    name="description"
                    value={articleOption?.description ? articleOption.description : ''}
                    className="form-control"
                    placeholder="Information"
                    onChange={(e) => changearticledata(e)}
                  />
                </div>
              </div>
              <div className="col-sm-3">
                <div className="form-group text-left">
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label htmlFor="" className="small">
                    <b>Product Sold Out:</b>
                  </label>
                  <div>
                    <input
                      type="radio"
                      name="sold"
                      value="Yes"
                      checked={!!isSoldOut}
                      style={{
                        marginTop: '12px',
                        marginLeft: '0px',
                        marginRight: '17px',
                      }}
                      onChange={(e) => setSoldOut(true)}
                    />
                    <span>Yes</span>{' '}
                    <input
                      type="radio"
                      name="sold"
                      value="No"
                      checked={!isSoldOut}
                      style={{
                        marginTop: '12px',
                        marginLeft: '0px',
                        marginRight: '17px',
                      }}
                      onChange={(e) => setSoldOut(false)}
                    />
                    <span>No</span>
                  </div>
                </div>
              </div>
            </div>
            {isAssembled ? (
              <div className="row">
                <div className="col-sm-12">
                  {articleOptionid === 'new' ? (
                    <h6 className="">Select included articles</h6>
                  ) : (
                    <h6 className="">Included articles</h6>
                  )}
                </div>

                <div className="col-sm-8">
                  <Select
                    name="add_included_select"
                    options={optionArticles}
                    value={selectedAddIncludedArt}
                    onChange={handleSelectIncluded}
                  />
                </div>
                <div className="col-sm-4">
                  <button
                    type="button"
                    onClick={handleAddIncludedArticle}
                    className="btn btn-primary"
                  >
                    Add
                  </button>
                </div>

                <div className="col-sm-12 mt-3">
                  <ul className="list-group">{renderIncluded()}</ul>
                </div>
              </div>
            ) : null}
          </ModalBody>
          <ModalFooter>
            <Button
              name="Cancel"
              btnLongWidth={false}
              type="button"
              onClick={(e: any) => refresh(e)}
            />

            <Button
              disabled={disableSubmit}
              name="Submit"
              dangerButton={true}
              btnLongWidth={false}
              type="submit"
            />
          </ModalFooter>
        </form>
      )}
    </Modal>
  );
};
export default EditArticleModel;
