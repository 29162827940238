import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { toast } from 'react-toastify';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { Button, InputField } from '../index';
import { API } from '../../api';
import { updateChain } from '../../api/restaurantApi.service';

// Update Chain
const EditChainModal: FC<any> = (props) => {
  const [chainId, setChainId] = useState<any>('');
  const [chainObj, setChainObj] = useState<any>(null);

  const asyncDispatch: ThunkDispatch<StoreRoot, any, AnyAction> = useDispatch();

  useEffect(() => {
    setChainId(props.chainId);
    if (typeof props.chain === 'undefined') {
      setChainObj(null);
    } else {
      setChainObj({ ...props.chain });
    }
  }, [props]);

  const changeChainName = (e: any) => {
    const new_chain = { ...chainObj };
    new_chain.name = e.target.value;
    setChainObj(new_chain);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    // Update chain here
    const formData = new FormData();
    if (chainObj?.name) {
      formData.append('name', chainObj.name);
      updateChain(chainId, formData)
        .then((res) => {
          toast.success('Chain updated', { containerId: 'B' });
          props.toggle();
        })
        .catch((err) => {
          toast.error('Error updating chain', { containerId: 'B' });
          props.toggle();
        });
    } else {
      toast.error('You need to fill in all the fields', { containerId: 'B' });
    }
  };

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    props.toggle && props.toggle();
  };

  return (
    <Modal isOpen={props.modal} toggle={props.toggle}>
      <ModalHeader toggle={props.toggle}>Bewerk netwerk</ModalHeader>
      <form onSubmit={(e) => handleSubmit(e)}>
        <ModalBody>
          <div className="row">
            <div className="col-sm-12">
              <div className="form-group text-left">
                <label htmlFor="" className="small">
                  Naam
                </label>
                <InputField
                  required
                  name="catname"
                  placeholder="naam"
                  value={chainObj?.name || ''}
                  onChange={(e: any) => changeChainName(e)}
                />
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button name="Cancel" btnLongWidth={false} onClick={handleCancel} type="button" />
          <Button name="Save" type="submit" dangerButton={true} btnLongWidth={false} />
        </ModalFooter>
      </form>
    </Modal>
  );
};
export default EditChainModal;
