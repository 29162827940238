import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { toast } from 'react-toastify';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { Button, InputField } from '../index';
import { API } from '../../api';
import { getCurrentPictureStorage, getCommonPictureStorage } from '../../store/actions';
import Masonry from 'react-masonry-css';

const PictureModal: FC<any> = (props) => {
  const [picture, setPicture] = useState<any>('');
  const [pictureId, setPictureId] = useState<any>(false);
  const restId = localStorage.getItem('rest_id') as string;
  const [pictureName, setPictureName] = useState('');
  const [pictureFile, setPictureFile] = useState<any>(null);
  const [filePreview, setFilePreview] = useState<any>(null);
  const id = localStorage.getItem('rest_id') as string;
  const current_pict_storage: any = useSelector(
    ({ currentPictureStorage }: StoreRoot) => currentPictureStorage
  );

  const dispatch = useDispatch();
  const asyncDispatch: ThunkDispatch<StoreRoot, any, AnyAction> = useDispatch();

  useEffect(() => {
    setPictureId(props.pictureId);
    if (typeof props.picture === 'undefined') {
      setPicture('');
    } else {
      setPicture(props.picture || '');
      setPictureName(props.picture?.name || '');
    }
  }, [props]);

  const changePictureName = (e: any) => {
    setPictureName(e.target.value);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (props.picture === 'new') {
      if (!pictureFile || pictureFile?.length === 0) {
        toast.error('Please, select picture files', { containerId: 'B' });
        return;
      }
      if (current_pict_storage.name === '') {
        toast.error('You need to create storage first', { containerId: 'B' });
        return;
      }
      // Create Picture here
      let formData = new FormData();

      Array.from(pictureFile).map((item: any) => {
        formData.append('picture', item);
      });
      API.restaurantApi
        .createMultiplePictures(current_pict_storage.id, formData)
        .then((res) => {
          setPictureFile(null);
          setFilePreview(null);
          toast.success('Picture created!', { containerId: 'B' });
          dispatch(getCurrentPictureStorage(id));
          dispatch(getCommonPictureStorage(id));
          props.toggle();
        })
        .catch((err) => {
          console.log('handleSubmit: err', err);
          toast.error('Error creating picture', { containerId: 'B' });
        });
    } else {
      // Update Picture here
      let formData = new FormData();

      if (pictureName) formData.append('name', pictureName);
      if (pictureFile) formData.append('picture', pictureFile[0]);
      API.restaurantApi
        .editPicture(pictureId, formData)
        .then((res) => {
          setPictureFile(null);
          setFilePreview(null);
          toast.success('Picture updated!', { containerId: 'B' });
          dispatch(getCurrentPictureStorage(id));
          dispatch(getCommonPictureStorage(id));
          props.toggle();
        })
        .catch((err) => {
          console.log('Error', err);
          toast.error('Error updating picture', { containerId: 'B' });
        });
    }
  };

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setPictureFile(null);
    setFilePreview(null);
    props.toggle && props.toggle();
  };

  const handlePictureFile = (e: any) => {
    let new_files: any[] = [];

    if (pictureFile?.length > 0) {
      // User already added some files and wants to add more
      const added_files = Array.from(e.target.files);
      for (let idx in added_files) {
        let already_there = pictureFile.findIndex(
          // @ts-ignore
          (item: any) => item.name === added_files[idx].name
        );

        if (already_there === -1) {
          new_files.push(added_files[idx]);
        }
      }
      new_files = [...pictureFile, ...new_files];
    } else {
      // Files added for the first time
      new_files = [...Array.from(e.target.files)];
    }
    setPictureFile(new_files);

    let files_urls: any[] = [];
    new_files.map((item: any) => {
      const objectUrl = URL.createObjectURL(item);
      files_urls.push(objectUrl);
    });

    setFilePreview(files_urls);
  };

  const handleRemovePictureFile = (index: any) => {
    // Find it in added pictures
    const foundItem = pictureFile[index];
    let new_picture_files = pictureFile;
    new_picture_files.splice(index, 1);
    setPictureFile(new_picture_files);
    let files_urls: any[] = [];
    new_picture_files.map((item: any) => {
      const objectUrl = URL.createObjectURL(item);
      files_urls.push(objectUrl);
    });

    setFilePreview(files_urls);
  };

  const breakpointColumnsObj = {
    default: 4,
    450: 3,
    300: 2,
    100: 1,
  };

  return (
    <Modal
      isOpen={props.modal}
      toggle={() => {
        setPictureFile(null);
        setFilePreview(null);
        props.toggle();
      }}
    >
      <ModalHeader toggle={props.toggle}>
        {props.picture === 'new' ? 'Voeg afbeelding toe' : 'Bewerk afbeelding'}
      </ModalHeader>
      <form onSubmit={(e) => handleSubmit(e)}>
        <ModalBody>
          <div className="row">
            <div className="col-sm-12">
              <label htmlFor="file_upload" className="btn btn-primary picture_upload_btn">
                <input
                  name="image"
                  type="file"
                  multiple={props.picture === 'new'}
                  onChange={(e: any) => {
                    handlePictureFile(e);
                  }}
                  id="file_upload"
                  accept="image/*"
                />
                Selecteer bestand
              </label>
            </div>
            {props.picture === 'new' ? null : (
              <div className="col-sm-12">
                <div className="form-group text-left">
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label htmlFor="" className="small">
                    Naam
                  </label>
                  <InputField
                    name="picture_name"
                    placeholder="naam"
                    value={pictureName}
                    onChange={(e: any) => changePictureName(e)}
                  />
                </div>
              </div>
            )}
            <div className="col-sm-12">
              <div className="form-group text-left">
                <label htmlFor="" className="small">
                  Image
                </label>
                <br />
                {props.picture === 'new' ? (
                  <Masonry
                    breakpointCols={breakpointColumnsObj}
                    className="my-masonry-grid"
                    columnClassName="my-masonry-grid_column"
                    style={{ width: '100%' }}
                  >
                    {filePreview?.map((item: any, i: any) => (
                      <div key={`${i} ${item.name}`} className="picture-load-item-wrapper">
                        <img
                          className="picture-load-preview picture-load-many"
                          src={item ? item : props.picture?.image}
                          alt=""
                          title={item.name}
                        />
                        <div className="picture-load-controls">
                          <i
                            onClick={(e: any) => handleRemovePictureFile(i)}
                            className="fa fa-times-circle"
                            aria-hidden="true"
                          ></i>
                        </div>
                      </div>
                    ))}
                  </Masonry>
                ) : filePreview ? (
                  <img className="picture-load-preview" src={filePreview[0]} alt="" />
                ) : (
                  <img className="picture-load-preview" src={props.picture?.image} alt="" />
                )}
                {!filePreview && !props.picture?.image ? (
                  <i className="fa fa-file-image-o" aria-hidden="true"></i>
                ) : null}
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button name="Cancel" btnLongWidth={false} onClick={handleCancel} type="button" />
          <Button name="Save" type="submit" dangerButton={true} btnLongWidth={false} />
        </ModalFooter>
      </form>
    </Modal>
  );
};
export default PictureModal;
