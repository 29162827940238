import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { Button, InputField } from './index';
import { API } from '../api';
import { getArticleOptionNames } from '../store/actions';

const ArticleOptionsModal: FC<any> = (props) => {
  const [articleOption, setArticleOption] = useState<any>('');
  const [articleOptionid, setArticleOptionid] = useState('');
  const [selectionofalias, setselectionofalias] = useState<any>([]);
  const [selectionofaliaslist, setselectionofaliaslist] = useState([]);
  const [minstate, setminstate] = useState('');
  const [maxstate, setmaxstate] = useState('');
  const [maxlimit, setmaxlimit] = useState('');

  const asyncDispatch: ThunkDispatch<StoreRoot, any, AnyAction> = useDispatch();

  const getArticleOptionNameSelection = async () => {
    await API.restaurantApi
      .getArticleOptionNameSelection(props.articleoptId)
      .then((res) => {
        setselectionofaliaslist(res.data);
      })
      .catch((err) => console.log('Error is', err.message));
  };

  const getArticleOptionForModal = async (restId: string, id: string) => {
    await API.restaurantApi
      .getArticleOptionForModal(restId, id)
      .then((res) => {
        setmaxlimit(res.data.length);
      })
      .catch((err) => console.log('Error is', err.message));
  };

  const getArticleOptionNameMinMax = async () => {
    await API.restaurantApi
      .getArticleOptionNameMinMax(props.articleopt.activetab.id)
      .then((res) => {
        setminstate(res.data[0].min);
        setmaxstate(res.data[0].max);
      })
      .catch((err) => console.log('Error is', err.message));
  };

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    typeof props.articleoptId === 'number' && getArticleOptionNameSelection();
    setselectionofalias([]);
    if (props.articleopt) {
      if (props.articleopt.activetab === null) {
        setselectionofalias([]);
        console.log('into props if', props.articleopt.activetab);
      } else if (props.articleopt !== 'undefined' && props.articleopt !== 'new') {
        const stateselection = [...selectionofalias] as any;
        console.log('selected prop', props.articleopt);
        const category = {
          value: props.articleopt.activetab.alias,
          label: props.articleopt.activetab.alias,
          id: props.articleopt.activetab.id,
        };
        stateselection.push(category);
        setselectionofalias(stateselection);

        const rest_id = localStorage.getItem('rest_id');
        getArticleOptionForModal(rest_id as string, props.articleopt.activetab.id);
        getArticleOptionNameMinMax();
        console.log(stateselection);
      }
    }

    setArticleOptionid(props.articleoptId);
    if (typeof props.articleopt === 'undefined') {
      setArticleOption('');
    } else {
      setArticleOption(props.articleopt);
      console.log(props.articleopt);
    }
  }, [props]);

  const handleChangeSelection = (e: any) => {
    const selection = [{ value: e.value, label: e.value, id: e.id }];
    setselectionofalias(selection);

    const rest_id = localStorage.getItem('rest_id');
    getArticleOptionForModal(rest_id as string, e.id);
    getArticleOptionNameMinMax();
  };

  const changeMinData = (e: any) => {
    setminstate(e.target.value);
  };

  const changeMaxData = (e: any) => {
    setmaxstate(e.target.value);
  };

  const changeOptionNamedata = (e: any) => {
    setArticleOption({
      ...articleOption,
      [e.target.name]: [e.target.value],
    });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (selectionofalias && selectionofalias.length === 0 && articleOptionid !== 'new') {
      toast.error('Please select article options', { containerId: 'B' });
      return;
    }

    const optionname = e.target.elements.optionname.value;

    const restid = localStorage.getItem('rest_id') as string;
    if (articleOptionid === 'new') {
      await API.restaurantApi
        .createArticleOptionName(
          {
            optionname,
            articleoptionnamerestaurant: restid,
          },
          restid
        )
        .then((response) => {
          toast.success('Added Successfully!', { containerId: 'B' });
          asyncDispatch(getArticleOptionNames(restid)).then(() => props.toggle());
        })
        .catch((error) => {
          window.location.reload();
        });
    } else {
      const min = e.target.elements.min.value;
      const max = e.target.elements.max.value;
      const activetab = selectionofalias[0]?.id;
      await API.restaurantApi
        .updateArticleOptionName(
          {
            articleoptionnamerestaurant: restid,
            activetab,
            optionname,
            restaurant: restid,
            optionalais: activetab,
            optionName: optionname,
            min,
            max,
            panel: 'true',
          },
          restid,
          articleOptionid
        )
        .then((response) => {
          toast.success('Updated Successfully!', { containerId: 'B' });
          asyncDispatch(getArticleOptionNames(restid)).then(() => props.toggle());
        });
    }
  };

  return (
    <Modal isOpen={props.modal} toggle={props.toggle}>
      <ModalHeader toggle={props.toggle}>
        {articleOptionid === 'new' ? 'Add New Options Group' : 'Edit Options Group'}
      </ModalHeader>
      {articleOption && (
        <form onSubmit={(e) => handleSubmit(e)}>
          <ModalBody>
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group text-left">
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label htmlFor="" className="small">
                    <b>Article Option Name:</b>
                  </label>
                  <InputField
                    name="optionname"
                    placeholder="Enter Name"
                    value={articleOption.optionname || ''}
                    onChange={(e: any) => changeOptionNamedata(e)}
                    required
                  />
                </div>
              </div>
              {props.articleoptId !== 'new' && (
                <>
                  <div className="col-sm-12">
                    <div className="form-group text-left">
                      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                      <label htmlFor="" className="small">
                        <b>Select Article Options:</b>
                      </label>
                      <Select
                        name="selectedoption"
                        defaultValue={selectionofalias}
                        options={selectionofaliaslist}
                        value={selectionofalias}
                        onChange={(e) => handleChangeSelection(e)}
                      />
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <div className="form-group text-left">
                      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                      <label htmlFor="" className="small">
                        <b>Enter Min value:</b>
                      </label>
                      <InputField
                        type="number"
                        name="min"
                        placeholder="Enter Min value :"
                        max={maxlimit || ''}
                        min={0}
                        value={minstate}
                        onChange={(e: any) => changeMinData(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <div className="form-group text-left">
                      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                      <label htmlFor="" className="small">
                        <b>Enter Max value:</b>
                      </label>
                      <InputField
                        type="number"
                        name="max"
                        placeholder="Enter Max value"
                        min={0}
                        value={maxstate}
                        max={maxlimit || ''}
                        onChange={(e: any) => changeMaxData(e)}
                        required
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              name="Cancel"
              btnLongWidth={false}
              type="button"
              onClick={(e: any) => {
                e.preventDefault();
                props.toggle();
              }}
            />

            <Button name="Submit" type="submit" dangerButton={true} btnLongWidth={false} />
          </ModalFooter>
        </form>
      )}
    </Modal>
  );
};
export default ArticleOptionsModal;
