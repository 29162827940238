import React, { useEffect, useState } from 'react';
import {
  Modal, ModalBody, ModalHeader, ModalFooter,
} from 'reactstrap';
import { toast } from 'react-toastify';
import InputField from '../../Components/InputField';
import { ITablesLocationWithCapacity, ITablesLocation } from '../../api/DTO/tablesLocation';
import { API } from '../../api';
import Button from '../../Components/Button';

interface IProps {
  restId: number;
  locationModalIsOpen: boolean;
  handleLocationModalToggle: () => void;
  handleUpdatePage: () => void;
}

export function CreateTablesLocationsModal(props: IProps) {
  const {
    locationModalIsOpen, handleLocationModalToggle, restId, handleUpdatePage,
  } = props;

  const [locations, setLocations] = useState < ITablesLocation[]>([])
  const [errorMessage, setErrorMessage] = useState('')

  const [tablesLocation, setTablesLocation] = useState<ITablesLocationWithCapacity>(
    {
      name: '', restaurant: restId, table_capacity: 0,
    },
  )

  useEffect(() => {
    let mounted = true
    API.restaurantApi.getTablesLocationsByRestId(restId)
      .then((responseTablesLocations) => {
        if (!mounted) return
        setLocations(responseTablesLocations)
      })
      .catch(() => toast.error('Getting tables location has been failed.', { containerId: 'B' }))
    return () => {
      mounted = false
    }
  }, [restId])

  const clearModal = () => {
    setTablesLocation(
      { name: '', restaurant: restId, table_capacity: 0 },
    )
  }

  const validation = () => {
    const existLocationWithSameName = locations.find((location) => location.name === tablesLocation.name)
    if (existLocationWithSameName) {
      setErrorMessage(`Location with name ${existLocationWithSameName.name} is exists.`)
      return false
    }
    return true
  }

  // handlers
  const handleCloseModal = () => {
    handleLocationModalToggle()
    clearModal()
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setErrorMessage('')
    setTablesLocation({ ...tablesLocation, [event.target.name]: event.target.value });
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const isValid = validation()
    if (!isValid) return
    API.restaurantApi.postTablesLocationWithTableGeneration(tablesLocation)
      .then((response) => {
        if (response) toast.success('Location created', { containerId: 'B' })
        handleCloseModal()
        handleUpdatePage()
      })
      .catch(() => {
        toast.error('Creating location has been failed.', { containerId: 'B' })
      })
  }

  return (
    <Modal isOpen={locationModalIsOpen} toggle={handleCloseModal}>
      <ModalHeader toggle={handleCloseModal}>
        Een nieuwe locatie aanmaken
      </ModalHeader>
      <form onSubmit={handleSubmit}>
        <ModalBody>
          <div className="row">
            <div className="col-sm-12">
              <div className="form-group text-left">
                <label htmlFor="name" className="small">
                  Naam
                </label>
                <InputField
                  required
                  placeholder="naam"
                  name="name"
                  value={tablesLocation.name}
                  onChange={handleChange}
                  errorMessage={errorMessage}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="form-group text-left">
                <label htmlFor="name" className="small">
                  Aantal tafels
                </label>
                <InputField
                  required
                  placeholder="aantal"
                  name="table_capacity"
                  value={tablesLocation.table_capacity}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button name="Cancel" btnLongWidth={false} onClick={handleCloseModal} type="button" />
          <Button
            name="Save"
            type="submit"
            dangerButton={true}
            btnLongWidth={false}
          />
        </ModalFooter>
      </form>
    </Modal>
  );
}
