import React, { FC } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Login from './views/Login';
import ForgotPassword from './views/ForgotPassword';
import AdminDashboard from './views/AdminDashboard';
import CsvUpload from './views/UploadCSV';
import EditArticle from './views/EditArticle';
import FoodCategory from './views/FoodCategory';
import ForgotPasswordReset from './views/ForgotPasswordReset';
import TablesQRCodes from './views/TablesQRCodes';
import ArticleOptions from './views/ArticleOptions';
import ArticleOptionsFiltered from './views/ArticleOptionsFiltered';
import ArticleOptionsName from './views/ArticleOptionsName';
import CreateArticleOptions from './views/CreateArticleOptions';
import Orders from './views/Orders';
import OrdersOverview from './views/OrdersOverview';
import Printers from './views/Printers';
import FinalScreen from './views/FinalScreen';
import RightsManagement from './views/RightsManagement';
import Tags from './views/Tags';
import Promocodes from './views/Promocodes';
import PictureGallery from './views/PictureGallery';
import ChangePassword from './views/ChangePassword';
import Forbidden from './Components/Forbidden';
import 'react-toastify/dist/ReactToastify.css';
import 'react-phone-input-2/lib/style.css';
import PrivateRoute from './Components/PrivateRoute';

import PickupPoints from './views/ChainManagment/PickupPoints';
import ChainOrdersOverview from './views/ChainManagment/OrdersOverview';
import ChainRoute from './Components/ChainComponents/ChainRoute';
import ChainChangePassword from './Components/ChainComponents/ChainChangePassword';
import UserTags from './views/UserTagsItems';
import MainView from './views/MainView';
import GeneralView from './views/GeneralView';
import PaymentView from './views/PaymentView';
import OrderConfirmationView from './views/OrderConfirmationView';
import WorkingHoursView from './views/WorkingHoursView';
import StatisticsView from './views/StatisticsView';

const App: FC = () => {
  return (
    <>
      <Switch>
        <Route exact path="/">
          <Redirect to="/auth/login" />
        </Route>
        <Route path="/auth/login" component={Login} />
        <PrivateRoute path="/admin/mainView" component={MainView} />
        <PrivateRoute path="/admin/printers" component={Printers} />
        <PrivateRoute path="/admin/csvUpload" component={CsvUpload} />
        <PrivateRoute path="/admin/editArticle" component={EditArticle} />
        <PrivateRoute path="/admin/foodCategory" component={FoodCategory} />
        <PrivateRoute path="/admin/articleoptions" component={ArticleOptions} />
        <PrivateRoute path="/admin/articleoption/:id" component={ArticleOptionsFiltered} />
        <PrivateRoute path="/admin/articleoptionsName" component={ArticleOptionsName} />
        <PrivateRoute path="/admin/qrdonwload/:id" component={TablesQRCodes} />
        <Route path="/admin/optiongroups/:id" component={CreateArticleOptions} />
        <PrivateRoute path="/admin/orders" component={Orders} />
        <PrivateRoute path="/admin/orders_overview" component={StatisticsView} />
        <PrivateRoute path="/admin/rightsmanagement" component={RightsManagement} />
        <PrivateRoute path="/admin/changePassword" component={ChangePassword} />
        <PrivateRoute path="/admin/tags" component={Tags} />
        <PrivateRoute path="/admin/promocodes" component={Promocodes} />
        <PrivateRoute path="/admin/gallery" component={PictureGallery} />
        <PrivateRoute path="/admin/user_tags" component={UserTags} />
        <PrivateRoute path="/admin/generalView" component={GeneralView} />
        <PrivateRoute path="/admin/paymentView" component={PaymentView} />
        <PrivateRoute path="/admin/orderConfirmation" component={OrderConfirmationView} />
        <PrivateRoute path="/admin/WorkingHours" component={WorkingHoursView} />
        {/* <PrivateRoute path="/admin/statistics" component={StatisticsView} /> */}

        <ChainRoute path="/chain/pickup_points" component={PickupPoints} />
        <ChainRoute path="/chain/orders_overview" component={ChainOrdersOverview} />
        <ChainRoute path="/chain/changePassword" component={ChainChangePassword} />

        <Route path="/auth/forgotPassword" component={ForgotPassword} />
        <Route
          path="/auth/restaurant/:restid/forgotPasswordReset/:code"
          component={ForgotPasswordReset}
        />
        <Route path="/admin/forbidden" component={Forbidden} />
      </Switch>
      <ToastContainer enableMultiContainer containerId="B" position={toast.POSITION.TOP_LEFT} />
    </>
  );
};
export default App;
