import React, { FC, useEffect, useState } from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { API_BASE_URL } from '../../services/urls';
import Button from '../Button';
import { API } from '../../api';

const ChainOrderModal: FC<any> = (props) => {
  const [Orderdata, setOrderdata] = useState<any>('');
  const [theOrder, setTheOrder] = useState<any>();
  const [orderDetails, setOrderDetails] = useState<any>(null);
  const id = props.restId;

  const getOrder = async () => {
    if (!props.orderId || !props.restId) return;
    await API.restaurantApi
      .getOrder(id, props.orderId)
      .then((res) => {
        setTheOrder(res.data.orders[0]);

        setOrderdata(res.data.Articles);

        getOrderDetails(res.data.orders[0].order_number);
      })
      .catch((err) => console.log('Error is', err.message));
  };

  const getOrderDetails = async (order_id: any) => {
    await API.restaurantApi
      .ordersDetail(order_id)
      .then((res) => {
        setOrderDetails(res);
      })
      .catch((err) => console.log('Error is', err.message));
  };

  useEffect(() => {
    getOrder();
  }, [props]);

  const refresh = (e: any) => {
    e.preventDefault();
    props.setModal(!props.modal);
  };

  interface Ctg {
    id: number;
    ctg_name: string;
    articles: any[];
  }

  const getOrderCtgs = (orderData: any): Ctg[] => {
    // Get Unique Categories For Current Order
    const ctgs: Ctg[] = [];
    orderData.map((item: any) => {
      const currCtg: Ctg = {
        id: item.article.category.id,
        ctg_name: item.article.category.name,
        articles: [],
      };
      if (!ctgs.find((i) => i.id === currCtg.id)) {
        ctgs.push(currCtg);
      }
    });

    return ctgs;
  };

  const renderArtOptions = (opt_array: any[]) => {
    if (!opt_array || opt_array.length === 0) return '';
    return (
      <div className="col-6">
        (
        {opt_array.map((item: any, index: any) => {
          if (!item) return;

          return item?.article_option ? (
            <React.Fragment key={`${item.article_option} ${index}`}>
              <span>
                {item.article_option.choice}
                {index + 1 < opt_array.length ? ', ' : ''}
              </span>
            </React.Fragment>
          ) : null;
        })}
        )
      </div>
    );
  };

  const renderOptionsForIncluded = (included: any, order_article: any) => {
    let opts_for_current: any[] = [];
    if (orderDetails?.data?.order_combo_options?.length > 0) {
      opts_for_current = orderDetails.data?.order_combo_options.filter(
        (item: any) => item.included_article === included.id && item.order_article === order_article.id,
      );
    }

    return opts_for_current?.length > 0 ? (
      <div className="col-6">
        (
        {opts_for_current.map((item: any, index: any) => (
          <span key={`${included.id} ${item.id} ${Math.random()}`}>
            {item.article_option.choice}
            {index + 1 < opts_for_current.length ? ', ' : ''}
          </span>
        ))}
        )
      </div>
    ) : null;
  };

  const renderIncludedArticles = (article: any) => {
    let combo_opts_for_current: any[] = [];
    if (orderDetails?.data?.order_combo_options) {
      combo_opts_for_current = orderDetails?.data?.order_combo_options.filter(
        (item: any) => article.id === item.order_article,
      );
    }

    return article.article?.included_articles?.map((item: any, index: any) => (
      <React.Fragment key={`${item.id} ${index}`}>
        <div className="row ml-3">
          <span className="col-6">
            {item.articlename}
            <br />
          </span>
          <span className="col-6 text-right" />
        </div>
        <div className="row ml-3">{renderOptionsForIncluded(item, article)}</div>
      </React.Fragment>
    ));
  };

  const renderCtgs = (orderData: any) => {
    const ctgs = getOrderCtgs(orderData);
    const res: any[] = [];

    return (
      <>
        <div className="row">
          <h1 className="bestelling-title">Bestelling</h1>
        </div>
        {ctgs.map((ctg: any, index: any) => {
          return (
            <React.Fragment key={`${ctg.id} ${index}`}>
              <div className="row">
                <div className="col-6">
                  <h3 className="bestelling-ctg-title">{ctg.ctg_name}</h3>
                </div>
              </div>
              {orderData.map((item: any) => {
                if (ctg.id === item.article.category.id) {
                  return (
                    <>
                      <div className="row" key={item.article.id}>
                        <span className="col-6">
                          {item.article_quantity} X {item.article.articlename}
                          <br />
                        </span>
                        <span className="col-6 text-right">€{item.article_total_prize}</span>
                      </div>
                      {item.article?.optionsname?.length > 0 && (
                        <div className="row">{renderArtOptions(item?.article_options)}</div>
                      )}

                      {renderIncludedArticles(item)}
                    </>
                  );
                }
              })}
            </React.Fragment>
          );
        })}
        <div className="row mt-5">
          <div className="col-6 font-weight-bold">Servicekosten</div>
          <div className="col-6 text-right font-weight-bold">€{theOrder?.service_cost}</div>
        </div>
        <div className="row">
          <div className="col-6 font-weight-bold">Bezorgkosten</div>
          <div className="col-6 text-right font-weight-bold">€{theOrder?.delivery_cost}</div>
        </div>
        {theOrder?.discount_value && !theOrder?.discount_userTag_value ? (
          <div className="row">
            <div className="col-6 font-weight-bold">Totale korting</div>
            <div className="col-6 text-right font-weight-bold">€{theOrder?.discount_value}</div>
          </div>
        ) : null}
        {theOrder?.discount_userTag_value ? (
          <div className="row">
            <div className="col-6 font-weight-bold">Persoonlijke korting</div>
            <div className="col-6 text-right font-weight-bold">{theOrder?.discount_userTag_value}</div>
          </div>
        ) : null}
        <div className="row">
          <div className="col-6 font-weight-bold">Totaal</div>
          <div className="col-6 text-right font-weight-bold">€{theOrder?.total_amount}</div>
        </div>
      </>
    );
  };

  return (
    <Modal className="modal-lg" isOpen={props.modal} toggle={(e: any) => props.toggle(e)}>
      {Orderdata && (
        <>
          <ModalBody>
            <div className="container">{renderCtgs(Orderdata)}</div>
          </ModalBody>
          <ModalFooter>
            <Button
              name="Cancel"
              dangerButton={true}
              btnLongWidth={false}
              onClick={(e: any) => refresh(e)}
            />
          </ModalFooter>
        </>
      )}
    </Modal>
  );
};
export default ChainOrderModal;
