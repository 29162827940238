import { API } from '../../../api';

const getChain = async (chain_id: any, setChain: (data: any) => void) => {
  API.restaurantApi
    .getChain(chain_id)
    .then((res: any) => {
      setChain(res.data.data);
    })
    .catch((err) => {
      return null;
    });
};

export default getChain;
