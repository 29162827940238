import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import {
  Spinner,
  Pagination,
  PaginationItem,
  PaginationLink,
  Form,
  FormGroup,
  Input,
} from 'reactstrap';
import {
  getCustomerByRestId,
  getUserTagByRestId,
  manageUserTagsItems,
} from '../../api/restaurantApi.service';
import { Header, Sidebar, UserTagItemsModal } from '../../Components';
import UserTagEditModal from '../../Components/UserTagEditModal';

function Thead() {
  return (
    <thead>
      <tr>
        <th className="small">Tag naam</th>
        <th className="small">Kortingtype</th>
        <th className="small">Amount</th>
        <th className="small">Actie</th>
      </tr>
    </thead>
  );
}

const nameMapping: { [key: string]: string } = {
  Product: 'Gratis product',
  Fixed: 'Vast bedrag',
  Percentage: 'Percentage',
};

const UserTags: FC = () => {
  const mainPanel = useRef<any>();
  const [userTagsItems, setUserTagsItems] = useState<UserTagItem[]>([]);
  const [editUserTagItem, setEditUserTagItem] = useState<UserTagItem | null>(null);
  const [isOpenUserTagItemsModal, setOpenUserTagItemsModal] = useState<boolean>(false);
  const [isOpenEditCustomerTagModal, setIsOpenEditCustomerTagModal] = useState<boolean>(false);

  const [customerList, setCustomerList] = useState<TCustomerMainInfo[]>([]);
  const [userTagList, setUserTagList] = useState<TUserTagRepresent[]>([]);
  const [loadingSecondTable, setLoadingSecondTable] = useState(true);

  const [editCustomerId, setEditCustomerId] = useState<number | null>(null);
  const [editUserTagId, setEditUserTagId] = useState<number | null>(null);

  const [pageCount, setPageCount] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);

  const [search, setSearch] = useState('');

  const restId = localStorage.getItem('rest_id') as string;

  const concatCustomersUserTags = (
    customerList: TCustomerMainInfo[],
    userTagListRepresent: TUserTagRepresent[]
  ): TCustomerMainInfo[] => {
    if (!customerList || !customerList.length) {
      toast.error('Empty customer list', { containerId: 'B' });
    }
    return customerList.map((customer) => {
      const userTag = userTagListRepresent.find((userTag) => userTag.customer.id === customer.id);
      if (!userTag) {
        return { ...customer };
      }
      return { ...customer, user_tag_info: userTag };
    });
  };

  useEffect(() => {
    const controller = new AbortController();
    document.title = 'Gebruikertags';
    let mounted = true;
    manageUserTagsItems(+restId, 'GET').then((response: any) => {
      mounted && setUserTagsItems(response);
    });
    return () => {
      mounted = false;
      controller.abort();
    };
  }, [isOpenUserTagItemsModal, restId]);

  useEffect(() => {
    const controller = new AbortController();
    let mounted = true;
    let userTagListRepresent: TUserTagRepresent[] = [];
    setLoadingSecondTable(true);
    getUserTagByRestId(+restId)
      .then((userTagResponse: any) => {
        if (!userTagResponse.success || !userTagResponse.data) {
          toast.error(userTagResponse.message, { containerId: 'B' });
          throw new Error(userTagResponse.message);
        }
        userTagListRepresent = [...userTagResponse.data];
        mounted && setUserTagList(userTagListRepresent);
        return getCustomerByRestId(+restId, page, pageSize, search);
      })
      .then((customerResponse: any) => {
        if (!customerResponse || customerResponse.detail) {
          toast.error('Error in search klants', { containerId: 'B' });
          throw new Error('Error in customer response');
        }
        const { count, page_size, results } = customerResponse;
        const customerList: TCustomerMainInfo[] = [...results];
        mounted && setPageSize(page_size);
        mounted && setPageCount((count - (count % page_size)) / page_size);
        mounted && setCustomerList(concatCustomersUserTags(customerList, userTagListRepresent));
        mounted && setLoadingSecondTable(false);
      })
      .catch(() => () => {
        mounted = false;
        controller.abort();
      });
    return () => {
      mounted = false;
      controller.abort();
    };
  }, [isOpenEditCustomerTagModal, page, search, userTagsItems]);

  const handleToggleUserTagsItemsModal = () => {
    setOpenUserTagItemsModal(!isOpenUserTagItemsModal);
  };

  const handleEditCustomerTag = (e: any) => {
    const id = e.target.getAttribute('data-id');
    const infoId = e.target.getAttribute('data-info-id');
    infoId ? setEditUserTagId(infoId) : setEditUserTagId(null);
    if (id) {
      setEditCustomerId(+id);
      setIsOpenEditCustomerTagModal(true);
    }
  };

  const toggleEditCustomer = () => {
    if (isOpenEditCustomerTagModal) {
      setEditCustomerId(null);
    }

    setIsOpenEditCustomerTagModal(false);
    setLoadingSecondTable(true);
  };

  const handleDeleteUserTagsItem = (id: number) => () => {
    manageUserTagsItems(+restId, 'DELETE', id)
      .then(() => {
        toast.success('UserTag deleted successfully!', { containerId: 'B' });
        return manageUserTagsItems(+restId, 'GET');
      })
      .then((response: any) => setUserTagsItems(response))
      .catch(() => new Error('Delete had been fault'));
  };

  const handleEditUserTagsItem = (id: number) => () => {
    if (!id || !userTagsItems) {
      return new Error('Where id?');
    }
    setEditUserTagItem(userTagsItems.find((item) => item.id === id) || null);
    setOpenUserTagItemsModal(true);
  };

  const clearEditMode = () => {
    setEditUserTagItem(null);
  };

  const handleChangePageClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const clickedPageNumber = (e.target as HTMLButtonElement).getAttribute('data-page');
    if (!clickedPageNumber) {
      return;
    }
    setPage(+clickedPageNumber);
    setPage(Number(clickedPageNumber));
  };

  const createPaginationItem = (i: number) => {
    return (
      <PaginationItem key={i} active={i === page}>
        <PaginationLink data-page={i}>{i}</PaginationLink>
      </PaginationItem>
    );
  };

  const createPagination = () => {
    if (!pageCount || !page || pageCount <= 1) {
      return null;
    }
    const upperCup = pageCount - 4;
    const paginationItems = [];
    paginationItems.push(
      <PaginationItem key="first">
        <PaginationLink data-page={page <= 1 ? page : page - 1}>&#10096;</PaginationLink>
      </PaginationItem>
    );
    if (pageCount > 10) {
      if (page > 5 && page < upperCup) {
        paginationItems.push(createPaginationItem(1));
        paginationItems.push(
          <PaginationItem disabled key="left_ell">
            <PaginationLink>...</PaginationLink>
          </PaginationItem>
        );
        const start = page - 4;
        const end = page + 4;
        for (let i = start; i <= end; i++) {
          paginationItems.push(createPaginationItem(i));
        }
        paginationItems.push(
          <PaginationItem disabled key="right_ell">
            <PaginationLink>...</PaginationLink>
          </PaginationItem>
        );
        paginationItems.push(createPaginationItem(pageCount));
      } else if (page <= 5) {
        const end = page + 4;
        for (let i = 1; i <= end; i++) {
          paginationItems.push(createPaginationItem(i));
        }
        paginationItems.push(
          <PaginationItem disabled key="right_ell">
            <PaginationLink>...</PaginationLink>
          </PaginationItem>
        );
        paginationItems.push(createPaginationItem(pageCount));
      } else {
        paginationItems.push(createPaginationItem(1));
        paginationItems.push(
          <PaginationItem disabled key="left_ell">
            <PaginationLink>...</PaginationLink>
          </PaginationItem>
        );
        for (let i = page - 4; i <= pageCount; i++) {
          paginationItems.push(createPaginationItem(i));
        }
      }
    } else {
      for (let i = 1; i <= pageCount; i++) {
        paginationItems.push(createPaginationItem(i));
      }
    }
    paginationItems.push(
      <PaginationItem key="last">
        <PaginationLink data-page={page === pageCount ? page : page + 1}>&#10097;</PaginationLink>
      </PaginationItem>
    );

    return paginationItems;
  };

  const renderPagination = useMemo(() => createPagination(), [pageCount, page]);

  return (
    <>
      <Sidebar />
      <div id="border-top-radius" className="container-fluid main-panel" ref={mainPanel}>
        <Header />
        <div className="row ml-lg-5 mt-5 pt-5">
          <div className="col-xl-10 offset-xl-1 col-lg-8 offset-lg-3 col-md-8 offset-md-4 col-sm-12 col-12 text-center">
            <span className="h4">Gebruiker tags</span>
            &nbsp;&nbsp;&nbsp;
            <button
              type="button"
              onClick={handleToggleUserTagsItemsModal}
              className="btn btn-yellow rounded-0 shadow-none"
            >
              + Voeg gebruikertag toe
            </button>
            <div className="table-responsive mt-4 mb-4">
              <table className="table table-striped table-bordered">
                <Thead />
                <tbody>
                  {userTagsItems.map((item) => (
                    <tr key={item.id}>
                      <td>{`${item.name}`}</td>
                      <td>{nameMapping[item.user_tag_type_name]}</td>
                      <td>{item.user_tag_concrete}</td>
                      <td>
                        <i
                          key={`e${item.id}`}
                          className="fa fa-pencil cursr"
                          aria-hidden="true"
                          onClick={handleEditUserTagsItem(item.id)}
                        />
                        <i
                          key={`d${item.id}`}
                          className="fa fa-trash pl-2 cursr"
                          aria-hidden="true"
                          onClick={handleDeleteUserTagsItem(item.id)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <span className="h4">Klants</span>
            <div className="table-responsive mt-4">
              <Form>
                <FormGroup>
                  <Input
                    id="search"
                    name="search"
                    value={search}
                    placeholder="Klant zoeken"
                    type="search"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setSearch(event.target.value);
                    }}
                  />
                </FormGroup>
              </Form>
              {loadingSecondTable ? (
                <div className="table-container">
                  <div className="loading">
                    <Spinner color="secondary" size="" />
                  </div>
                </div>
              ) : (
                <div className="table-responsive mt-4">
                  <table className="table table-striped table-bordered">
                    <thead>
                      <tr>
                        <th className="small">ID</th>
                        <th className="small">Email</th>
                        <th className="small">Klant naam</th>
                        <th className="small">Tag naam</th>
                        <th className="small">Actie</th>
                      </tr>
                    </thead>
                    <tbody>
                      {customerList.map((customer) => (
                        <tr key={customer.id}>
                          <td key={customer.id}>{customer.id}</td>
                          <td key={customer.email}>{customer.email}</td>
                          <td key={customer.full_name}>{customer.full_name}</td>
                          <td>
                            {customer.user_tag_info
                              ? customer.user_tag_info.user_tag_item.name
                              : null}
                          </td>
                          <td>
                            <i
                              className="fa fa-pencil cursr"
                              aria-hidden="true"
                              data-id={customer.id}
                              data-info-id={
                                customer.user_tag_info ? customer.user_tag_info.id : null
                              }
                              onClick={handleEditCustomerTag}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <Pagination
                    className="pagination"
                    aria-label="Page navigation"
                    onClick={handleChangePageClick}
                  >
                    {renderPagination}
                  </Pagination>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <UserTagItemsModal
        restId={restId}
        editUserTagItem={editUserTagItem}
        clearEditMode={clearEditMode}
        isOpen={isOpenUserTagItemsModal}
        toggle={handleToggleUserTagsItemsModal}
      />
      <UserTagEditModal
        isOpen={isOpenEditCustomerTagModal}
        toggle={toggleEditCustomer}
        customerId={editCustomerId}
        restId={restId}
        userTagId={editUserTagId}
      />
    </>
  );
};

export default UserTags;
