import { toast } from 'react-toastify';
import React, { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { API } from '../../api';
import { Logo, InputField, Button } from '../../Components';
import parseJwt from '../../helpers/parseJwt';

const Login: FC = () => {
  useEffect(() => {
    document.title = 'Login';
  }, []);

  const handleFormSubmission = async (event: any) => {
    event.preventDefault();
    const username = event.target.elements.username.value;
    const password = event.target.elements.password.value;
    await API.restaurantApi
      .login({ username, password })
      .then(async (response) => {
        const rest_id = response.data.data.restaurant.id;
        const jwt = response.data.data.token;
        window.localStorage.setItem('jwt', jwt);
        window.localStorage.setItem('rest_id', rest_id);

        const parsed_jwt = parseJwt(jwt);

        await API.restaurantApi
          .getRestaurantPanelUsers(rest_id)
          .then((res) => {
            // @ts-ignore
            const current_user = res.find((item: any) => parsed_jwt.user_id === item.user.id);

            window.localStorage.setItem('users_list', JSON.stringify(res));
            window.localStorage.setItem('current_user', JSON.stringify(current_user));
          })
          .catch((err) => {
            console.log('error getting users_list');
          });

        if (response.data.data.is_chain_manager) {
          const chain = response.data.data.chain;
          localStorage.setItem('is_chain_manager', 'true');
          localStorage.setItem('chain', JSON.stringify(chain));
          // @ts-ignore
          window.location = '/chain/pickup_points';
          return;
        }

        if (response.data.data) {
          // @ts-ignore
          // window.location = `/admin/dashboard/${response.data.data.restaurant.id}`;
          window.location = `/admin/MainView`;
        }
      })
      .catch((error) => {
        toast.error('Email or Password is wrong', { containerId: 'B' });
      });
  };

  return (
    <form onSubmit={(event) => handleFormSubmission(event)}>
      <div className="container mt-5">
        <div className="row align-items-center">
          <div className="col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-6 offset-sm-3 col-12">
            <Logo infoText="Log in" mutedText={false} />
          </div>
          <div className="col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-6 offset-sm-3 col-12 mt-2 mb-2">
            <InputField type="email" name="username" placeholder="E-mail" />
          </div>
          <div className="col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-6 offset-sm-3 col-12 mt-2 mb-2">
            <InputField type="password" name="password" placeholder="Wachtwoord" />
          </div>
          <div className="col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-6 offset-sm-3 col-12 mb-2 text-right">
            <Link to="/auth/forgotPassword">
              <span className="text-muted small">Forgot Password?</span>
            </Link>
          </div>
          <div className="col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-6 offset-sm-3 col-12 text-center mt-2">
            <Button
              htmlType="submit"
              name="Log in"
              dangerButton={true}
              btnLongWidth={true}
              Link={true}
            />
          </div>
        </div>
      </div>
    </form>
  );
};
export default Login;
