import React from 'react'

type Props = {
  optionsName: any;
  articleOptions: any;
}

const ArticleOptions = (props: Props) => {
  const {
    optionsName,
    articleOptions,
  } = props

  if (!articleOptions || !articleOptions.length) return null

  return (
    <div className="text-muted mb-1 small">
      (
      { optionsName.length <= 0 ? null : articleOptions.map((option: any, index: any) => (
        <span key={`${option.article_option} ${index}`}>
          {option.article_option.choice}
          {index + 1 < articleOptions.length ? ', ' : ''}
        </span>
      ))}
      )
    </div>
  )
}

export default ArticleOptions
