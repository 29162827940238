const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

// Takes options with the same name and combines their ids into
// option's 'value' array
const squashSelectOptions = (nonSquashedOptions: any[]) => {
  let squashedOpts: any[] = [];

  nonSquashedOptions.forEach((opt: any) => {
    // Find index if already in squashedOpts
    const already_idx = squashedOpts.findIndex(
      (item: any) => item.label.toLowerCase() === opt.label.toLowerCase()
    );
    if (already_idx === -1) {
      squashedOpts.push({
        id: opt.id,
        label: capitalizeFirstLetter(opt.label.toLowerCase()),
        value: [opt.id],
      });
    } else {
      squashedOpts[already_idx].value.push(opt.id);
    }
  });

  return squashedOpts;
};

export default squashSelectOptions;
