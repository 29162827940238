import React, { FC, useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { toast } from 'react-toastify';
import { Button, InputField } from '../index';
import { API } from '../../api';

// Create Chain restaurants
const ChainModal: FC<any> = (props) => {
  const [username, setUsername] = useState<string>('');
  const [restaurantName, setRestaurantName] = useState<string>('');
  const [password1, setPassword1] = useState<string>('');
  const [password2, setPassword2] = useState<string>('');

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (password1 !== password2) {
      toast.error("Password don't match", { containerId: 'B' });
      return;
    }

    if (!username || !restaurantName || !password1 || !password2) {
      toast.error('Please, fill in all the fields', { containerId: 'B' });
      return;
    }

    // Create Restaurant here
    const formData = new FormData();

    formData.append('username', username);
    formData.append('password', password1);
    formData.append('restaurant_name', restaurantName);

    API.restaurantApi
      .createChainRestaurant(props.chain_id, formData)
      .then((res) => {
        toast.success('Restaurant created', { containerId: 'B' });
        clearFields();
        props.toggle();
      })
      .catch((err) => {
        console.log('err', err);
        toast.error('Restaurant not created', { containerId: 'B' });
      });
  };

  const clearFields = () => {
    setUsername('');
    setRestaurantName('');
    setPassword1('');
    setPassword2('');
  };

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    clearFields();
    props.toggle && props.toggle();
  };

  return (
    <Modal isOpen={props.modal} toggle={handleCancel}>
      <ModalHeader toggle={props.toggle}>Bewerk point</ModalHeader>
      <form onSubmit={(e) => handleSubmit(e)}>
        <ModalBody>
          <div className="form-group text-left">
            <label htmlFor="" className="small">
              E-mail
            </label>
            <InputField
              required
              type="email"
              name="username"
              placeholder="email"
              value={username}
              onChange={(e: any) => setUsername(e.target.value)}
            />
          </div>

          <div className="form-group text-left">
            <label htmlFor="" className="small">
              Naam restaurant
            </label>
            <InputField
              required
              type="text"
              name="restaurant_name"
              placeholder="Naam restaurant"
              value={restaurantName}
              onChange={(e: any) => setRestaurantName(e.target.value)}
            />
          </div>

          <div className="form-group text-left">
            <label htmlFor="" className="small">
              Wachtwoord
            </label>
            <InputField
              required
              type="password"
              name="password1"
              placeholder="Wachtwoord"
              pattern=".{8,}"
              value={password1}
              onChange={(e: any) => setPassword1(e.target.value)}
            />
            <small id="password_help" className="form-text text-muted">
              minimum 8 characters
            </small>
          </div>

          <div className="form-group text-left">
            <label htmlFor="" className="small">
              Bevestig wachtwoord
            </label>
            <InputField
              required
              type="password"
              name="password2"
              placeholder="Bevestig wachtwoord"
              pattern=".{8,}"
              value={password2}
              onChange={(e: any) => setPassword2(e.target.value)}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button name="Cancel" btnLongWidth={false} onClick={handleCancel} type="button" />
          <Button name="Save" type="submit" dangerButton={true} btnLongWidth={false} />
        </ModalFooter>
      </form>
    </Modal>
  );
};
export default ChainModal;
