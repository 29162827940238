import React, { FC, useRef } from 'react';
import { Header, Sidebar } from '.';

const Forbidden: FC = (props) => {
  const mainPanel = useRef<any>();

  return (
    <>
      <Sidebar />
      <div id="border-top-radius" className="container-fluid main-panel" ref={mainPanel}>
        <Header />
        <div className="row ml-lg-5 mt-5 pt-5">
          <div className="col-xl-10 offset-xl-1 col-lg-8 offset-lg-3 col-md-8 offset-md-4 col-sm-12 col-12 text-center">
            <h2>You're forbidden to view requested page!</h2>
            <p>
              Please log in using account with all the necessary access rights or ask administrator
              to assign desired rights to your account
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
export default Forbidden;
