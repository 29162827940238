import PerfectScrollbar from 'perfect-scrollbar';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { API } from '../../api';
import Sidebar from '../../Components/Sidebar';
import Header from '../../Components/Header';
import moment from 'moment';
import getRestaurantData from '../../store/actions/getRestaurantData';

import boxIcon from '../../assets/images/box.svg';
import euroIcon from '../../assets/images/euro-yellow.svg';
import boxYellow from '../../assets/images/box-yellow.svg';

import {
  Chart,
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  CategoryScale,
  BarElement,
} from 'chart.js';
import { Bar, Doughnut, Line } from 'react-chartjs-2';

Chart.register(
  BarElement,
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  CategoryScale
);

interface MonthlyData {
  month: string;
  value: number;
}

let ps: any;
const StatisticsView: FC = (props) => {
  const [OrderData, setOrderData] = useState<any>([]);

  const [startDate, setStartDate] = useState<any>(new Date());
  const [endDate, setEndDate] = useState<any>(new Date());
  const [is_loading, setIsLoading] = useState<boolean>(false);
  const [isAutoAcceptOrders, setAutoAcceptOrders] = useState<boolean>(false);

  //states for order info and charts
  const [uniqueClientsData, setUniqueClientsData] = useState<Array<MonthlyData>>([]);
  const [ordersMadeData, setOrdersMadeData] = useState<Array<MonthlyData>>([]);
  const [revenueData, setRevenueData] = useState<Array<MonthlyData>>([]);

  const dispatch = useDispatch();
  const mainPanel = useRef<any>();
  const restaurant = useSelector(({ restaurantData }: StoreRoot) => restaurantData);

  const id = localStorage.getItem('rest_id') as string;

  useEffect(() => {
    document.title = 'Orders';
    // @ts-ignore
    // document.getElementById('orders_colorchange').style.color = 'darkred';
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(mainPanel.current);
      document.body.classList.toggle('perfect-scrollbar-on');
    }
    return () => {
      if (navigator.platform.indexOf('Win') > -1) {
        ps.destroy();
        document.body.classList.toggle('perfect-scrollbar-on');
      }
    };
  }, []);

  const getAllOrdersList = async (id: string) => {
    const currentDate = new Date();
    currentDate.setFullYear(currentDate.getFullYear() - 2);

    const sDate = moment(currentDate).format('YYYY-MM-DD');
    const eDate = moment(endDate.getTime(Date.now())).format('YYYY-MM-DD');

    setIsLoading(true);

    await API.restaurantApi
      .getAllOrdersList(id, sDate, eDate)
      .then((res: any) => {
        setIsLoading(false);
        const temCSVData = res.map((d: any) => ({
          OrderID: `${d.order_number}`,
          Statusbestelling: String(d.order_status),
          Betalingstatus: String(d.payment_status),
          Tafelnummer: d.tableid === -1 ? '' : `${d.tableid}`,
          OpmerkingKlant: d.user_note,
          NaamKlant: d.customer_name,
          E_mail: d.email,
          GSM: d.phoneNumber,
          Adres: d.address,
          Bedrag: d.total_amount,
          Voorwanneer:
            d.delivery_time && d.tableid === -1
              ? moment(d.delivery_time.split('+')[0]).format('MMMM, DD YYYY HH:mm')
              : '',
        }));
        // setCsvData(temCSVData);
        setOrderData(res);
      })
      .catch((err) => toast.error(`Error is ${err.message}`, { containerId: 'B' }));
  };

  useEffect(() => {
    const rest_id = localStorage.getItem('rest_id') as string;

    getAllOrdersList(rest_id);
    dispatch(getRestaurantData(rest_id));
  }, [id]);

  useEffect(() => {
    // @ts-ignore
    setAutoAcceptOrders(restaurant.auto_accept_orders);
  }, [restaurant]);

  // Transform and aggregate data whenever 'orders' state changes
  useEffect(() => {
    // Transform data for Line Chart (Unique Clients gained last 6 months)
    const uniqueClientsTransformedData = Array.from({ length: 6 }, (_, i) => {
      const date = new Date();
      date.setMonth(date.getMonth() - i);
      const monthLabel = date.toLocaleString('default', { month: 'long' });
      const monthValue = OrderData.filter(
        (order: any) => new Date(order.ordertime).getMonth() === date.getMonth()
      ).map((order: any) => order.email).length;
      return { month: monthLabel, value: monthValue };
    }).reverse();

    // Transform data for Bar Chart (Orders made last 6 months)
    const ordersMadeTransformedData = Array.from({ length: 6 }, (_, i) => {
      const date = new Date();
      date.setMonth(date.getMonth() - i);
      const monthLabel = date.toLocaleString('default', { month: 'long' });
      const monthValue = OrderData.filter(
        (order: any) => new Date(order.ordertime).getMonth() === date.getMonth()
      ).length;
      return { month: monthLabel, value: monthValue };
    }).reverse();

    // Transform data for Bar Chart (Revenue gained last 12 months)
    const revenueTransformedData = Array.from({ length: 12 }, (_, i) => {
      const date = new Date();
      date.setMonth(date.getMonth() - i);
      const monthLabel = date.toLocaleString('default', { month: 'long' });
      const monthValue = OrderData.filter(
        (order: any) => new Date(order.ordertime).getMonth() === date.getMonth()
      ).reduce((total: any, order: any) => total + Number(order.total_amount), 0);
      return { month: monthLabel, value: monthValue };
    }).reverse();

    setUniqueClientsData(uniqueClientsTransformedData);
    setOrdersMadeData(ordersMadeTransformedData);
    setRevenueData(revenueTransformedData);
  }, [OrderData]);

  //TODO: remove this finction and replace with the state where needed
  function getTotalAmount(filteredOrders: any) {
    const sum = filteredOrders.reduce((total: any, item: any) => {
      return total + Number(item.total_amount);
    }, 0);

    return sum.toFixed(2);
  }

  // function testOrderData(e: any, data: any) {
  //   console.log(data);
  //   const eDate = moment(endDate.getTime(Date.now())).format('YYYY-MM-DD');

  //   console.log(countUniqueCustomers(OrderData));

  //   console.log(uniqueClientsData);
  //   console.log(ordersMadeData);
  //   console.log(revenueData);
  // }

  //TODO:remove this and replace with the state
  function countUniqueCustomers(data: any) {
    const uniqueCustomers = new Set();
    data.forEach((order: any) => {
      uniqueCustomers.add(order.customer_name);
    });
    return uniqueCustomers.size;
  }

  const testData = {
    labels: ['Label 1', 'Label 2', 'Label 3'],
    datasets: [
      {
        label: 'My Chart',
        data: [12, 19, 3],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
        ],
        borderColor: ['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)', 'rgba(255, 206, 86, 1)'],
        borderWidth: 1,
      },
    ],
  };

  const uniqueClientsChartData = {
    labels: uniqueClientsData.map((data) => data.month),
    datasets: [
      {
        label: 'Unique Clients',
        data: uniqueClientsData.map((data) => data.value),
        backgroundColor: 'rgb(37, 45, 121)',
        borderColor: 'rgb(37, 45, 121)',
        tension: 0.1,
      },
    ],
  };

  // Bar Chart Data (Orders made last 6 months)
  const ordersMadeChartData = {
    labels: ordersMadeData.map((data) => data.month),
    datasets: [
      {
        label: 'Orders Made',
        data: ordersMadeData.map((data) => data.value),
        backgroundColor: 'rgb(37, 45, 121)',
        borderColor: 'rgb(37, 45, 121)',
        borderWidth: 1,
      },
    ],
  };

  // Bar Chart Data (Revenue gained last 12 months)
  const revenueChartData = {
    labels: revenueData.map((data) => data.month),
    datasets: [
      {
        label: 'Revenue',
        data: revenueData.map((data) => data.value),
        backgroundColor: 'rgb(255, 210, 4)',
        borderColor: 'rgb(255, 210, 4)',
        borderWidth: 1,
      },
    ],
  };

  return (
    <>
      <Sidebar />
      <div id="border-top-radius" className="container-fluid main-panel" ref={mainPanel}>
        <Header />
        <div className="container pt-5 pl-5 mt-5">
          <div className="d-flex justify-content-between">
            {/* <button onClick={(e) => testOrderData(e, OrderData)}>Console log order data</button> */}
            <div
              className="card text-white rounded"
              style={{ width: '18rem', backgroundColor: '#ffd204' }}
            >
              <div className="card-body">
                <h6 className="card-subtitle mb-2 text-muted">
                  <img src={boxIcon} alt="box-icon" style={{ height: '30px', width: '30px' }} />
                </h6>
                <h5 className="card-title">Totaal # Bestellingen</h5>
                <h3 className="card-text font-weight-bold">{OrderData.length || 0}</h3>
              </div>
            </div>
            <div
              className="card text-white rounded"
              style={{ width: '18rem', backgroundColor: '#252d79' }}
            >
              <div className="card-body">
                <h6 className="card-subtitle mb-2 text-muted">
                  <img src={euroIcon} alt="box-icon" style={{ height: '30px', width: '30px' }} />
                </h6>
                <h5 className="card-title">Bruto Totaal</h5>
                <h3 className="card-text font-weight-bold">€{getTotalAmount(OrderData)}</h3>
              </div>
            </div>
            <div
              className="card text-white rounded"
              style={{ width: '18rem', backgroundColor: '#252d79' }}
            >
              <div className="card-body">
                <h6 className="card-subtitle mb-2 text-muted">
                  <img src={boxYellow} alt="box-icon" style={{ height: '30px', width: '30px' }} />
                </h6>
                <h5 className="card-title">Totaal Openstaande</h5>
                <h3 className="card-text font-weight-bold">
                  {OrderData.filter((order: any) => order.order_status == 'Pending').length}
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div className="container pt-2 pl-5">
          <div className="d-flex justify-content-between">
            <div className="card rounded" style={{ width: '18rem' }}>
              <div className="card-body">
                <h5 className="card-title" style={{ color: '#252d79' }}>
                  Totaal # Klanten
                </h5>
                <h3 className="card-text font-weight-bold" style={{ color: '#252d79' }}>
                  {countUniqueCustomers(OrderData)}
                </h3>
              </div>
            </div>
            {/* <div>
                    <Bar 
                      data={{
                        labels: ['A', 'B', 'C'],
                        datasets: [{
                          label: 'Revenue',
                          data: [200, 300, 400]
                        }]
                      }}/>
                </div> */}
            <div className="mt-3">
              <Bar data={uniqueClientsChartData} />
              <p className="text-center mt-2 font-weight-bold" style={{ color: '#252d79' }}>
                Clients gained each month
              </p>
            </div>
            <div className="mt-3">
              <Line data={ordersMadeChartData} />
              <p className="mt-2 font-weight-bold text-center" style={{ color: '#252d79' }}>
                Orders per month
              </p>
            </div>
          </div>
          <div className="mt-5">
            {/* <p>Revenue per month</p> */}
            <Bar data={revenueChartData} />
            <h2 className="text-center mt-3 pb-5 font-weight-bold" style={{ color: '#252d79' }}>
              Revenue per month
            </h2>
          </div>
        </div>
      </div>
    </>
  );
};
export default StatisticsView;
