import React from 'react';
import ArticleOptions from './ArticleOptions';
import { IncludedArticles } from './IncludedArticles';

export interface ICategoriesProps {
  orderData: any | null;
  categories: any | null;
  orderDetails: any | null;
}

export const Categories = (props: ICategoriesProps) => {
  const { orderData, categories, orderDetails } = props;
  if (!categories) return null;

  return categories.map((category: any, index: any) => (
    <div key={`${category.id} ${index}`} className="mb-1">
      <h6 className="bestelling-ctg-title text-left">{category.ctg_name}</h6>
      {orderData.map((item: any, indexData: any) => {
        if (category.id !== item.article.category.id) return null;
        return (
          <div className="text-left ml-4 mb-1 small" key={`${item.article.id} ${indexData}`}>
            <div className="row">
              <div className="col-8">
                {item.article_quantity} X {item.article.articlename}
              </div>
              <div className="col-4 text-right">
                €{item.article_total_prize} (with {item.article.pickuptax}% TAX)
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <ArticleOptions
                  articleOptions={item.article_options}
                  optionsName={item.article.optionsname}
                />
              </div>
            </div>
            <IncludedArticles article={item} orderDetails={orderDetails} />
          </div>
        );
      })}
    </div>
  ));
};
