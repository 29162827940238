import React from 'react'
import QRCode from 'qrcode.react';
import { ITablesLocation } from '../api/DTO/tablesLocation';
import { ITablesObject } from '../views/TablesQRCodes';

type Props = {
  tablesLocations: ITablesLocation[];
  tables: ITablesObject;
  MAIN_APP_URL?: string;
  restaurantSlug: string;
}

export const TmpQR = (props: Props) => {
  const {
    tablesLocations, tables, MAIN_APP_URL, restaurantSlug,
  } = props

  if (!MAIN_APP_URL) {
    return null
  }

  const renderQR = () => {
    return tablesLocations.map((l: ITablesLocation) => {
      if (tables[l.id]) {
        return tables[l.id].map((table) => (
          <QRCode
            key={table.id}
            id={`qr-code-${table.id}`}
            value={`${MAIN_APP_URL}/restaurant/${restaurantSlug}/${table.name}`}
            size={290}
            level="H"
            includeMargin={true}
          />
        ))
      }
      return 1
    })
  }

  return (
    <div>
      {renderQR()}
    </div>
  )
}
