import PerfectScrollbar from 'perfect-scrollbar';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { TagModal, Header, Sidebar } from '../../Components';
import { API } from '../../api';
import { getTags } from '../../store/actions';
import DeleteModalComponent from '../../Components/DeleteModalComponent';

let ps: any;
const Tags: FC = (props) => {
  const [modal, setModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const [tagId, setTagId] = useState(0);
  const [tagobj, setTagobj] = useState(0);
  const mainPanel = useRef<any>();
  const id = localStorage.getItem('rest_id') as string;

  const tags = useSelector(({ tags }: StoreRoot) => tags);

  const dispatch = useDispatch();

  useEffect(() => {
    document.title = 'Tags';
    // @ts-ignore
    // document.getElementById('tags_colorchange').style.color = 'darkred';
    dispatch(getTags(id));
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(mainPanel.current);
      document.body.classList.toggle('perfect-scrollbar-on');
    }
    return () => {
      if (navigator.platform.indexOf('Win') > -1) {
        ps.destroy();
        document.body.classList.toggle('perfect-scrollbar-on');
      }
    };
  }, [modal, id]);

  const toggle = (id: any, tag: any) => {
    if (id?.preventDefault) {
      id.preventDefault();
    }

    setModal(!modal);
    setTagId(id);
    setTagobj(tag);
  };

  const toggleDeleteModal = (id: any, articleopt: any) => {
    setDeleteModal(!deleteModal);
    setTagId(id);
    setTagobj(articleopt);
  };

  const deleteTag = async (id: any) => {
    const restid = localStorage.getItem('rest_id') as string;
    await API.restaurantApi
      .removeTag(id)
      .then((response) => {
        toast.success('Deleted Successfully!', { containerId: 'B' });
        dispatch(getTags(restid));
      })
      .catch((error) => {
        toast.error('Error deleting tag!', { containerId: 'B' });
        dispatch(getTags(restid));
      });
  };

  return (
    <>
      <Sidebar />
      <div id="border-top-radius" className="container-fluid main-panel" ref={mainPanel}>
        <Header />
        {tags && (
          <div className="row ml-lg-5 mt-5 pt-5">
            <div className="col-xl-10 offset-xl-1 col-lg-8 offset-lg-3 col-md-8 offset-md-4 col-sm-12 col-12 text-center">
              <span className="h4">Tags</span>
              &nbsp;&nbsp;&nbsp;
              <button
                type="button"
                onClick={() => toggle('new', 'new')}
                className="btn btn-yellow shadow-none"
              >
                + Voeg tag toe
              </button>
              <div className="table-responsive mt-4">
                <table className="table table-striped table-bordered">
                  <thead>
                    <tr className="d-flex justify-content-center">
                      <th className="small">Naam</th>
                      <th className="small">Actie</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tags.map((tag: any) => {
                      return (
                        <tr key={tag.id} className="d-flex justify-content-center">
                          {/* <td>{cat.id}</td> */}
                          <td>{tag.name}</td>
                          <td>
                            <i
                              className="fa fa-pencil cursr"
                              aria-hidden="true"
                              id={tag.id}
                              onClick={() => toggle(tag.id, tag)}
                            />
                            <i
                              className="fa fa-trash pl-2 cursr"
                              aria-hidden="true"
                              // onClick={() => deleteTag(tag.id)}
                              onClick={() => toggleDeleteModal(tag.id, tagobj)}
                              id={tag.id}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
      <TagModal tag={tagobj} modal={modal} toggle={toggle} tagId={tagId} />
      <DeleteModalComponent
        articleopt={tagobj}
        isOpen={deleteModal}
        toggle={toggleDeleteModal}
        articleoptId={tagId}
        deleteFunction={deleteTag}
      />
    </>
  );
};
export default Tags;
