const checkOrderContainsProducts = (products: any[], order: any) => {
  const products_ids = products.map((item: any) => item.id).flat();
  const order_articles_ids: number[] = [];
  order.article.forEach((art: any) => {
    if (art.article.is_assembled) {
      art.article.included_articles.forEach((incl: any) => order_articles_ids.push(incl.id));
    }
    order_articles_ids.push(art.article.id);
  });

  return products_ids.some((prod_id: any) => order_articles_ids.includes(prod_id));
};

export default checkOrderContainsProducts;
