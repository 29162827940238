import React, { useState, useEffect } from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap'

import { toast } from 'react-toastify';
import { ITable } from '../../api/DTO/table';

import InputField from '../../Components/InputField';
import Button from '../../Components/Button';
import { API } from '../../api';
import { ITablesObject } from '.';

type Props = {
  locationId: number;
  isOpen: boolean;
  handleCloseModal: () => void;
  handleUpdate: () => void;
  tables: ITablesObject;
}

const CreateTableModal = (props: Props) => {
  const {
    locationId,
    isOpen,
    handleCloseModal,
    handleUpdate,
    tables,
  } = props

  // States
  const [form, setForm] = useState<Omit<ITable, 'id'>>({ name: '', location: locationId })
  const [errorMessage, setErrorMessage] = useState('')

  // Effects
  useEffect(() => {
    setForm({ ...form, location: locationId })
  }, [locationId])

  // Handlers
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    const locationWithTableSameName = Object.keys(tables).find((locationKey) => {
      const existTable = tables[+locationKey].find((table) => table.name === form.name);
      if (existTable) return true
      return false
    })
    if (locationWithTableSameName) {
      setErrorMessage('A table with that name already exists')
      return
    }
    API.restaurantApi.postTable(form)
      .then(() => {
        toast.success('Added Successfully!', { containerId: 'B' });
      })
      .catch(() => {
        toast.error('Cant create table.')
      })
      .finally(() => {
        handleUpdate()
        handleCloseModal()
      })
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newTable = { ...form, name: event.target.value };
    setErrorMessage('')
    setForm(newTable)
  }

  return (
    <Modal isOpen={isOpen} toggle={handleCloseModal}>
      <ModalHeader toggle={handleCloseModal}>
        Een nieuwe tabel maken
      </ModalHeader>
      <form onSubmit={handleSubmit}>
        <ModalBody>
          <div className="form-group text-left">
            <label htmlFor="name" className="small">Naam</label>
            <InputField
              required
              name="name"
              placeholder="naam"
              value={form.name}
              onChange={handleChange}
              errorMessage={errorMessage}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button name="Cancel" btnLongWidth={false} onClick={handleCloseModal} type="button" />
          <Button
            name="Save"
            type="submit"
            dangerButton={true}
            btnLongWidth={false}
          />
        </ModalFooter>
      </form>
    </Modal>
  )
}

export default CreateTableModal
