import React, { useEffect, useState } from 'react';
import './style.css';

const PicturesTable: any = ({
  storage,
  currImg,
  setCurrImg,
  enablePreview,
  enableEdit,
  enablePictSelect,
  handlePreview,
  handleEdit,
  handleDelete,
}: any) => {
  const [images, setImages] = useState<any[]>([]);

  useEffect(() => {
    if (!storage?.storage_pictures) return;
    setImages(storage.storage_pictures);
  }, [storage]);

  const handleSetCurrentImage = (e: any, image: any) => {
    if (currImg && currImg.id === image.id) {
      setCurrImg(null);
      return;
    }
    setCurrImg(image);
  };

  const handleExpand = (e: any, image: any) => {
    e.preventDefault();
    e.stopPropagation();
    setCurrImg(image);
    handlePreview(e, image);
  };

  const handleEditPicture = (e: any, image: any) => {
    e.preventDefault();
    e.stopPropagation();
    setCurrImg(image);
    handleEdit(image);
  };

  const handleDeletePicture = (e: any, image: any) => {
    e.preventDefault();
    e.stopPropagation();
    setCurrImg(image);
    handleDelete(image);
  };

  return (
    <div className="picture-table">
      {images.map((image: any) => (
        <div className="picture" key={image.id}>
          <div className="picture-controls">
            {image.id === currImg?.id && enablePictSelect ? (
              <i className="fa fa-check-circle" aria-hidden="true"></i>
            ) : null}
          </div>
          <div
            className={`rounded picture-image ${image.id === currImg?.id ? 'picture-active' : ''}`}
            onClick={(e: any) => handleSetCurrentImage(e, image)}
            style={{ backgroundImage: `url(${image.image})` }}
          >
            {enablePreview || enableEdit ? (
              <div className="picture-controls-sub rounded">
                {enablePreview ? (
                  <i
                    onClick={(e: any) => handleExpand(e, image)}
                    className="fa fa-expand"
                    style={{ cursor: 'pointer' }}
                    aria-hidden="true"
                  ></i>
                ) : null}
                {enableEdit ? (
                  <>
                    <i
                      onClick={(e: any) => handleEditPicture(e, image)}
                      className="fa fa-pencil"
                      style={{ cursor: 'pointer' }}
                      aria-hidden="true"
                    ></i>
                    <i
                      onClick={(e: any) => handleDeletePicture(e, image)}
                      className="fa fa-trash"
                      style={{ cursor: 'pointer' }}
                      aria-hidden="true"
                    ></i>
                  </>
                ) : null}
              </div>
            ) : null}
          </div>
          <span>{image.name}</span>
        </div>
      ))}
    </div>
  );
};

export default PicturesTable;
