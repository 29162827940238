import React, { FC, useEffect } from 'react';
import moment from 'moment';

interface OrdersOverviewRowProps {
  order: any;
  toggle: any;
}

const ChainOrdersOverviewRow: FC<OrdersOverviewRowProps> = ({ order, toggle }) => {
  return (
    <React.Fragment>
      <tr>
        <td className="small">{order.order_number}</td>
        <td className="small">{order.order_status}</td>
        <td className="small">{String(order.payment_status)}</td>
        <td className="small">{order.tableid === -1 ? '' : order.tableid}</td>
        <td className="small">{order.user_note ? order.user_note : ''}</td>
        <td className="small">{order.customer_name ? order.customer_name : ''}</td>
        <td className="small">{order.email ? order.email : ''}</td>
        <td className="small">{order.phoneNumber ? order.phoneNumber : ''}</td>
        <td className="small">{order.address ? order.address : ''}</td>
        <td className="small">{order.total_amount}</td>
        <td className="small">
          {order.delivery_time && order.tableid === -1
            ? `${moment(order.delivery_time.split('+')[0]).format('MMMM, DD YYYY HH:mm')}`
            : ''}
        </td>
        <td className="small">
          <i
            className="fa fa-info-circle"
            aria-hidden="true"
            onClick={() => toggle(order.order_number, order.restaurant)}
          />
        </td>
      </tr>
    </React.Fragment>
  );
};

export default ChainOrdersOverviewRow;
