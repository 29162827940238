import { API } from '../../../api';

const getChainArticles = async (chain_id: any, setArticles: (data: any) => void) => {
  API.restaurantApi
    .getAllArticlesListChain(chain_id)
    .then((res: any) => {
      setArticles(res);
    })
    .catch((err) => {
      return null;
    });
};

export default getChainArticles;
