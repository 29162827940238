import { API } from '../../../api';

const getChainCtgs = async (chain_id: any, setCtgs: (data: any) => void) => {
  API.restaurantApi
    .getAllCategoriesListChain(chain_id)
    .then((res: any) => {
      setCtgs(res);
    })
    .catch((err) => {
      return null;
    });
};

export default getChainCtgs;
