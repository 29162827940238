import PerfectScrollbar from 'perfect-scrollbar';
import QRCode from 'qrcode.react';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { toast } from 'react-toastify';

import { API } from '../../api';
import { MAIN_APP_URL } from '../../services/urls';

import { ITablesLocation } from '../../api/DTO/tablesLocation';
import { ITable } from '../../api/DTO/table';

import { Header, Sidebar } from '../../Components';
import { TablesLocationsModal } from './TablesLocationsModal';
import { CreateTablesLocationsModal } from './CreateTablesLocationModal';
import { TmpQR } from '../../Components/TmpQR';
import { MoveTableModal } from './MoveTableModal';
import CreateTableModal from './CreateTableModal';

let ps: any;

export interface ITablesObject {
  [key: number]: ITable[];
}

const TablesQRCodes: FC = () => {
  const mainPanel = useRef<any>();
  const [modal, setModal] = useState(false);
  const [locationModalIsOpen, setLocationModalIsOpen] = useState(false);
  const [createLocationModalIsOpen, setCreateLocationModalIsOpen] = useState(false);
  const [restaurantData, setRestaurantData] = useState<any>([]);

  const [tablesLocations, setTablesLocations] = useState<ITablesLocation[]>([]);
  const [tablesLocationId, setTablesLocationId] = useState<number | null>(null);
  const [update, setUpdate] = useState<Record<string, never>>({});

  const [tables, setTables] = useState<ITablesObject>({});
  const [currentTableId, setCurrentTableId] = useState<number>(NaN);
  const [currentTableName, setCurrentTableName] = useState('');
  const [currentLocationId, setCurrentLocationId] = useState<number>(NaN);
  const [moveTableModalIsOpen, setMoveTableModalIsOpen] = useState(false);
  const [createTableModalIsOpen, setCreateTableModalIsOpen] = useState(false);
  const [locationMoveFromId, setLocationMoveFromId] = useState<number>(NaN);

  const { id } = useParams<any>();

  // TODO
  const getRestaurant = async () => {
    await API.restaurantApi
      .getRestaurant(id)
      .then((res) => {
        setRestaurantData(res.data[0]);
      })
      .catch((err) => console.log('Error is', err.message));
  };

  useEffect(() => {
    let mounted = true;
    document.title = 'QR Download';
    // @ts-ignore
    // document.getElementById('restaurant_qrdownload').style.color = 'darkred';
    getRestaurant(); // TODO

    const tablesLocationsPromise = API.restaurantApi.getTablesLocationsByRestId(id);

    tablesLocationsPromise.then((responseTablesLocations) => {
      if (!mounted) return;
      responseTablesLocations && setTablesLocations(responseTablesLocations);

      const tablesPromises = responseTablesLocations.map((location: ITablesLocation) => {
        return API.restaurantApi.getTablesByLocationId(location.id);
      });
      Promise.all(tablesPromises).then((responses) => {
        if (!mounted) return;
        if (responses) {
          const convertedTables = responses.reduce<ITablesObject>((acc, tableArray) => {
            if (tableArray.length) {
              return { ...acc, [tableArray[0].location]: [...tableArray] };
            }
            return acc;
          }, {});
          setTables(convertedTables);
        }
      });
    });

    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(mainPanel.current);
      document.body.classList.toggle('perfect-scrollbar-on');
    }
    return () => {
      mounted = false;
      if (navigator.platform.indexOf('Win') > -1) {
        ps.destroy();
        document.body.classList.toggle('perfect-scrollbar-on');
      }
    };
  }, [modal, id, update]);

  // Handlers========================================

  const handleUpdatePage = () => {
    setUpdate({});
  };

  const handleLocationModalToggle = () => {
    setLocationModalIsOpen(!locationModalIsOpen);
  };

  const handleCreateLocationModalToggle = () => {
    setCreateLocationModalIsOpen(!createLocationModalIsOpen);
  };

  const handleLocationEditClick = (id: number) => () => {
    setTablesLocationId(id);
    handleLocationModalToggle();
  };

  const handleLocationNewClick = () => {
    setTablesLocationId(null);
    handleCreateLocationModalToggle();
  };

  const handleDeleteLocationClick = (id: number) => () => {
    API.restaurantApi
      .deleteTablesLocation(id)
      .then((response) => {
        response && toast.success('Delete success', { containerId: 'B' });
        handleUpdatePage();
      })
      .catch(() => {
        toast.error('Delete fail', { containerId: 'B' });
      });
  };

  const handleOpenNewTableModal = () => {
    setCreateTableModalIsOpen(true);
  };

  const handleCloseNewTableModal = () => {
    setCreateTableModalIsOpen(false);
  };

  const handleCreateTable = (location_id: number) => () => {
    setCurrentLocationId(location_id);
    handleOpenNewTableModal();
  };

  const handleDeleteTableClick = (id: number) => () => {
    API.restaurantApi
      .deleteTable(id)
      .then((response) => {
        response && toast.success('Delete success', { containerId: 'B' });
        handleUpdatePage();
      })
      .catch(() => {
        toast.error('Delete fail', { containerId: 'B' });
      });
  };
  const handleMoveTableClick = (locationId: number, tableId: number) => () => {
    setLocationMoveFromId(locationId);
    setCurrentTableId(tableId);
    setMoveTableModalIsOpen(true);
  };

  const toggle = (id: number, name: string) => () => {
    setModal(!modal);
    setCurrentTableId(id);
    setCurrentTableName(name);
  };

  const closeQRModal = () => {
    setModal(false);
    setCurrentTableId(NaN);
  };

  const handleCloseTableModal = () => {
    setMoveTableModalIsOpen(false);
    setCurrentTableId(NaN);
  };

  const downloadQR = () => {
    const zip = new JSZip();
    const canvas = document.getElementById('qr-code-modal') as any;
    const pngUrl = canvas.toDataURL('image/png');
    zip.file(`qr_table_${currentTableName}.png`, pngUrl.split(',').slice(1).join(','), {
      base64: true,
    });
    const promise = zip.generateAsync({ type: 'blob' });
    promise.then((content) => {
      saveAs(content, `table_${currentTableName}.zip`);
    });
  };

  const handleDownloadLocationQR = (location: ITablesLocation) => () => {
    const tablesByLocationId = tables[location.id];
    if (!tablesByLocationId) {
      toast.error('No tables in that location', { containerId: 'B' });
      return;
    }
    const zip = new JSZip();
    tablesByLocationId.forEach((table) => {
      const canvas = document.getElementById(`qr-code-${table.id}`) as any;
      const pngUrl = canvas.toDataURL('image/png');
      zip.file(
        `qr_location_${location.name}_table_${table.name}.png`,
        pngUrl.split(',').slice(1).join(','),
        { base64: true }
      );
    });
    zip.generateAsync({ type: 'blob' }).then((content) => {
      saveAs(content, `location_${location.name}.zip`);
    });
  };

  const handleDownloadLocationsQRAll = () => {
    const zip = new JSZip();
    tablesLocations.forEach((location) => {
      const tablesInLocation = tables[location.id];
      if (!tablesInLocation) return;
      tablesInLocation.forEach((table) => {
        const canvas = document.getElementById(`qr-code-${table.id}`) as any;
        const pngUrl = canvas.toDataURL('image/png');
        zip.file(
          `qr_location_${location.name}_table_${table.name}.png`,
          pngUrl.split(',').slice(1).join(','),
          { base64: true }
        );
      });
    });
    zip.generateAsync({ type: 'blob' }).then((content) => {
      saveAs(content, 'all_tables.zip');
    });
  };

  const renderLocationQR = (location: ITablesLocation) => {
    const renderTable = (table: ITable) => {
      return (
        <tr key={table.id}>
          <td>Tafelnummer: {table.name}</td>
          <td>
            <Button
              className="mr-4 btn-yellow btn-qr-code border-0"
              onClick={toggle(table.id, table.name)}
            >
              Maak qr code
            </Button>
            <i
              className="fa fa-pencil cursr"
              aria-hidden="true"
              onClick={handleMoveTableClick(location.id, table.id)}
            />
            <i
              className="fa fa-trash pl-2 cursr"
              aria-hidden="true"
              onClick={handleDeleteTableClick(table.id)}
            />
          </td>
        </tr>
      );
    };
    if (!Object.keys(tables).length || !location || !tables[location.id]) {
      return null;
    }
    return tables[location.id].map(renderTable);
  };

  const renderTables = (isDefaultRender: boolean) => {
    let locations: ITablesLocation[] | undefined[];
    if (isDefaultRender) {
      const defaultLocation = tablesLocations.find((location) => location.name === 'default_empty');
      if (!defaultLocation) {
        return null;
      }
      locations = [defaultLocation];
    } else {
      locations = tablesLocations.filter((location) => location.name !== 'default_empty');
    }
    if (!locations) return null;

    // console.log(locations);

    return locations.map((location) => (
      <div key={location.id}>
        <div className="row mb-4">
          <div className="col-md-1" />
          <div className="col-md-3">
            <span className="h5" style={{ lineHeight: '38px' }}>
              {isDefaultRender ? 'Tafels zonder locatie' : `${location.name}`}
            </span>
          </div>
          <div className="col-md-4 text-center">
            <button
              type="button"
              className="btn btn-yellow shadow-none"
              onClick={handleCreateTable(location.id)}
            >
              + Voeg tafel toe
            </button>
          </div>
          <div className="col-md-3 text-right">
            <button
              type="button"
              className="cursor btn btn-success border-0"
              onClick={handleDownloadLocationQR(location)}
            >
              Download qr codes
            </button>
          </div>
          <div className="col md-1" />
        </div>
        <div className="row mb-4">
          <div className="col-md-1" />
          <div className="col-md-10">
            <table className="table table-striped table-bordered text-center">
              <thead>
                <tr>
                  <th>Tafelnummer</th>
                  <th className="text-center" style={{ width: 300 }}>
                    Actie
                  </th>
                </tr>
              </thead>
              <tbody>{renderLocationQR(location)}</tbody>
            </table>
          </div>
          <div className="col-md-1" />
        </div>
      </div>
    ));
  };

  return (
    <>
      <Sidebar />
      <div id="for-tmp-qr-codes" className="display-none">
        <TmpQR
          tablesLocations={tablesLocations}
          tables={tables}
          MAIN_APP_URL={MAIN_APP_URL}
          restaurantSlug={restaurantData.slug}
        />
      </div>
      <div id="border-top-radius" className="container-fluid main-panel" ref={mainPanel}>
        <Header />
        <div className="row ml-lg-5 mt-5 pt-5" />
        <div className="container">
          <div className="row">
            <div className="col-md-12 mb-4 text-center">
              <span className="h4">Locaties</span>
              &nbsp;&nbsp;&nbsp;
              <button
                type="button"
                className="btn btn-yellow shadow-none"
                onClick={handleLocationNewClick}
              >
                + Voeg locatie toe
              </button>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-md-1" />
            <div className="col-md-10">
              <table className="table table-striped table-bordered text-center">
                <thead>
                  <tr>
                    <th className="small">Locatie naam</th>
                    <th className="small">Actie</th>
                  </tr>
                </thead>
                <tbody>
                  {tablesLocations.map(
                    (location) =>
                      location.name !== 'default_empty' && (
                        <tr key={location.id}>
                          <td>{location.name}</td>
                          <td>
                            <i
                              className="fa fa-pencil cursr"
                              aria-hidden="true"
                              onClick={handleLocationEditClick(location.id)}
                            />
                            <i
                              className="fa fa-trash pl-2 cursr"
                              aria-hidden="true"
                              onClick={handleDeleteLocationClick(location.id)}
                            />
                          </td>
                        </tr>
                      )
                  )}
                </tbody>
              </table>
            </div>
            <div className="col-md-1" />
          </div>
          <div className="row">
            <div className="col-md-12 mb-4 text-center">
              <span className="h4">QR Codes</span>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-md-12 text-center">
              <button
                type="button"
                className="cursor btn btn-success border-0"
                onClick={handleDownloadLocationsQRAll}
              >
                Download alle qr codes
              </button>
            </div>
          </div>
          {renderTables(false)}
          {renderTables(true)}
        </div>
      </div>

      <Modal isOpen={modal} toggle={closeQRModal}>
        <ModalHeader toggle={closeQRModal} />
        <ModalBody>
          <div className="text-center">
            <QRCode
              id="qr-code-modal"
              value={`${MAIN_APP_URL}/restaurant/${restaurantData.slug}/${currentTableName}`}
              size={290}
              level="H"
              includeMargin={true}
            />
            <button
              type="button"
              className="cursor btn btn-success ml-1 border-0"
              onClick={downloadQR}
            >
              {' '}
              Download QR{' '}
            </button>
          </div>
        </ModalBody>
      </Modal>

      <CreateTablesLocationsModal
        restId={id}
        locationModalIsOpen={createLocationModalIsOpen}
        handleLocationModalToggle={handleCreateLocationModalToggle}
        handleUpdatePage={handleUpdatePage}
      />
      <TablesLocationsModal
        restId={id}
        tablesLocationId={tablesLocationId}
        handleLocationModalToggle={handleLocationModalToggle}
        handleUpdatePage={handleUpdatePage}
        locationModalIsOpen={locationModalIsOpen}
      />
      <MoveTableModal
        tablesLocations={tablesLocations}
        tables={tables}
        tableId={currentTableId}
        locationId={locationMoveFromId}
        handleUpdatePage={handleUpdatePage}
        handleCloseModal={handleCloseTableModal}
        moveTableModalIsOpen={moveTableModalIsOpen}
      />
      <CreateTableModal
        tables={tables}
        isOpen={createTableModalIsOpen}
        handleUpdate={handleUpdatePage}
        handleCloseModal={handleCloseNewTableModal}
        locationId={currentLocationId}
      />
    </>
  );
};
export default TablesQRCodes;
