import { Route, Redirect, RouteProps } from 'react-router-dom';

const checkIfAllowed = (destination: any) => {
  const not_parsed_flag = localStorage.getItem('is_chain_manager');
  if (not_parsed_flag) {
    const is_chain_manager = JSON.parse(not_parsed_flag);
    if (is_chain_manager) {
      return true;
    } else {
      return false;
    }
  }

  return false;
};

function ChainRoute({ component: Component, ...rest }: any) {
  return (
    <Route
      {...rest}
      render={(props) =>
        // @ts-ignore
        checkIfAllowed(rest.location.pathname) ? (
          <Component {...props} />
        ) : (
          <Redirect to="/admin/forbidden" />
        )
      }
    />
  );
}

export default ChainRoute;
