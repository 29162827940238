import PerfectScrollbar from 'perfect-scrollbar';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { CategoryModal, Header, Sidebar } from '../../Components';
import { API } from '../../api';
import { getCategories } from '../../store/actions';
import DeleteModalComponent from '../../Components/DeleteModalComponent';

let ps: any;
const FoodCategory: FC = (props) => {
  const [modal, setModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const [categoryId, setCategoryId] = useState(0);
  const [Categoryobj, setCategoryobj] = useState(0);
  const mainPanel = useRef<any>();
  const id = localStorage.getItem('rest_id') as string;

  const category = useSelector(({ categories }: StoreRoot) => categories);

  const dispatch = useDispatch();

  useEffect(() => {
    document.title = 'Food Categories';
    // @ts-ignore
    const needToBeChanged = document.getElementById('foodcategories_colorchange');
    needToBeChanged ? (needToBeChanged.style.color = 'darkred') : null;
    dispatch(getCategories(id));
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(mainPanel.current);
      document.body.classList.toggle('perfect-scrollbar-on');
    }
    return () => {
      if (navigator.platform.indexOf('Win') > -1) {
        ps.destroy();
        document.body.classList.toggle('perfect-scrollbar-on');
      }
    };
  }, [modal, id]);

  const toggle = (id: any, cat: any) => {
    if (id?.preventDefault) {
      id.preventDefault();
    }

    setModal(!modal);
    setCategoryId(id);
    setCategoryobj(cat);
  };

  const toggleDeleteModal = (id: any, articleopt: any) => {
    setDeleteModal(!deleteModal);
    setCategoryId(id);
    setCategoryobj(articleopt);
  };

  const deletecat = async (id: any) => {
    const restid = localStorage.getItem('rest_id') as string;
    await API.restaurantApi
      .deleteCategory(id, restid as string)
      .then((response) => {
        toast.success('Deleted Successfully!', { containerId: 'B' });
        dispatch(getCategories(restid));
      })
      .catch((error) => {
        toast.success('Deleted Successfully!', { containerId: 'B' });
        dispatch(getCategories(restid));
      });
  };

  return (
    <>
      <Sidebar />
      <div id="border-top-radius" className="container-fluid main-panel" ref={mainPanel}>
        <Header />
        {category && (
          <div className="row ml-lg-5 mt-5 pt-5">
            <div className="col-xl-10 offset-xl-1 col-lg-8 offset-lg-3 col-md-8 offset-md-4 col-sm-12 col-12 text-center">
              <span className="h4">Categorieën</span>
              &nbsp;&nbsp;&nbsp;
              <button
                type="button"
                onClick={() => toggle('new', 'new')}
                className="btn btn-yellow shadow-none"
              >
                + voeg categorie toe
              </button>
              <div className="table-responsive mt-4">
                <table className="table table-striped table-bordered">
                  <thead>
                    <tr className="d-flex justify-content-center">
                      {/* <th className='small'>Category ID</th> */}
                      <th className="small">Naam</th>
                      <th className="small">Actie</th>
                    </tr>
                  </thead>
                  <tbody>
                    {category.map((cat: any) => {
                      return (
                        <tr key={cat.id} className="d-flex justify-content-center">
                          {/* <td>{cat.id}</td> */}
                          <td>{cat.value}</td>
                          <td>
                            <i
                              className="fa fa-pencil cursr"
                              aria-hidden="true"
                              id={cat.id}
                              onClick={() => toggle(cat.id, cat)}
                            />
                            <i
                              className="fa fa-trash pl-2 cursr"
                              aria-hidden="true"
                              // onClick={() => deletecat(cat.id)}
                              onClick={() => toggleDeleteModal(cat.id, cat)}
                              id={cat.id}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
      <CategoryModal category={Categoryobj} modal={modal} toggle={toggle} catid={categoryId} />
      <DeleteModalComponent
        articleopt={Categoryobj}
        isOpen={deleteModal}
        toggle={toggleDeleteModal}
        articleoptId={categoryId}
        deleteFunction={deletecat}
      />
    </>
  );
};
export default FoodCategory;
