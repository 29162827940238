import React, { FC, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import './styles.css';
import { API_BASE_URL, MAIN_APP_URL } from '../../services/urls';
import { Button } from '../../Components';

const VisitUrl: FC<any> = () => {
  const [restaurantData, setRestaurantData] = useState<any>([]);
  const restaurant = useSelector(({ restaurantData }: StoreRoot) => restaurantData);

  useEffect(() => {
    setRestaurantData(restaurant);
  }, [restaurant]);

  return (
    <div className="visit-url-buttons d-flex justify-content-end p-3 fixed-bottom w-25">
      <Button name="Opslaan" htmlType="submit" />
      <a href={`${MAIN_APP_URL}/restaurant/${restaurantData.slug}`}>Bezoek jouw webshop</a>
    </div>
  );
};

export default VisitUrl;
