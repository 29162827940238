import React, { FC } from 'react';
import './style.css';

const InputField: FC<any> = (props) => {
  return (
    <>
      <input
        type={props.type}
        className="form-control"
        placeholder={props.placeholder}
        onChange={props.onChange}
        value={props.value}
        name={props.name}
        max={props.max}
        min={props.min}
        required={props.required}
        minLength={props.minlength}
        disabled={props.disabled}
        pattern={props.pattern}
      />
      {props.errorMessage && (<div style={{ color: 'red' }}>{props.errorMessage}</div>) }
    </>

  );
};

export default InputField;
