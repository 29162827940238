import * as React from 'react';
import Select, { OptionTypeBase, ValueType } from 'react-select';

export interface ISelectWithValidateProps {
  label: string;
  value: OptionTypeBase | null;
  className?: string;
  outerClassName?: string;
  isSearchable: boolean;
  onChange: (value: any) => void;
  options: OptionTypeBase[];
  errorMessage?: string;
}

export function SelectWithValidate(props: ISelectWithValidateProps) {
  const {
    label,
    className,
    outerClassName,
    value,
    isSearchable,
    onChange,
    options,
    errorMessage,
  } = props;

  // Handlers
  const changeHandler = (value: ValueType<OptionTypeBase, false>): void => {
    onChange(value);
  };

  // Renders
  return (
    <div className={outerClassName}>
      <label htmlFor="input" className="form-label">
        {label}
      </label>
      <Select
        className={className}
        classNamePrefix="select"
        value={value || null}
        options={options}
        isSearchable={isSearchable}
        onChange={changeHandler}
        name="input"
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            // primary: '#be0a28a8',
            // primary25: '#be0a283a',
            // primary50: '#be0a2879',

          },
        })}

      />
      {errorMessage && <span className="text-danger">{errorMessage}</span>}
    </div>
  );
}
