import React, { FC } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Button } from './index';

const DeleteModalComponent: FC<any> = (props) => {
  return (
    <Modal
      articleopt={props.articleopt}
      isOpen={props.isOpen}
      toggle={props.toggle}
      articleoptNameId={props.articleoptNameId}
      deleteFunction={props.deleteFunction}
    >
      <ModalHeader toggle={props.toggle}></ModalHeader>
      <ModalBody>Are you sure you want to delete this?</ModalBody>
      <ModalFooter>
        <Button
          dangerButton={true}
          name="Delete"
          btnLongWidth={false}
          type="button"
          // onClick={() => props.deleteFunction(props.articleoptNameId)}
          onClick={(e: any) => {
            e.preventDefault();
            props.deleteFunction(props.articleoptId);
            props.toggle();
          }}
        >
          Delete
        </Button>{' '}
        <Button name="Cancel" btnLongWidth={false} type="button" onClick={props.toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};
export default DeleteModalComponent;
