import React from 'react';

type Props = {
  serviceCost: any;
  deliveryCost: any;
  discountValue: any;
  discountUserTagValue: any;
  totalAmount: any;
};

const TotalingThings = (props: Props) => {
  const { serviceCost, deliveryCost, discountUserTagValue, discountValue, totalAmount } = props;

  return (
    <div className="text-left">
      <div className="row">
        <div className="col-6">Servicekosten</div>
        <div className="col-6 text-right">€{serviceCost}</div>
      </div>
      <div className="row">
        <div className="col-6">Bezorgkosten</div>
        <div className="col-6 text-right">€{deliveryCost}</div>
      </div>
      {discountValue && !discountUserTagValue ? (
        <div className="row">
          <div className="col-6">Totale korting</div>
          <div className="col-6 text-right">€{discountValue}</div>
        </div>
      ) : null}
      {discountUserTagValue ? (
        <div className="row">
          <div className="col-6">Persoonlijke korting</div>
          <div className="col-6 text-right">{discountUserTagValue}</div>
        </div>
      ) : null}
      <div className="row">
        <div className="col-6 font-weight-bold">Totaal</div>
        <div className="col-6 text-right font-weight-bold">€{totalAmount}</div>
      </div>
    </div>
  );
};

export { TotalingThings };
