import React, { FC, useEffect } from 'react';

const Footer: FC = () => {
    return(
        <div className='footer vw-100 d-flex justify-content-center py-3 px-5'>
            <a href="mailto:info@easypayments.be" className='px-3' style={{color:'rgb(254,212,20)'}}>info@easypayments.be</a>
            <a href="https://served.easypayments.be/" className='px-3' style={{color:'rgb(254,212,20)'}}>Our Website</a>
        </div>
    )
}

export default Footer;