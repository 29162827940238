import PerfectScrollbar from 'perfect-scrollbar';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Switch from 'react-switch';
import { getRestaurantData } from '../../store/actions';
import { API } from '../../api';
import { API_BASE_URL, MAIN_APP_URL } from '../../services/urls';
import {
  Button,
  DownloadQR,
  Header,
  InputField,
  Sidebar,
  WorkingHoursModal,
} from '../../Components';
import './style.css';
import DeleteModalComponent from '../../Components/DeleteModalComponent';
import VisitUrl from '../../Components/VisitUrl/VisitUrl';

let ps: any;

const Dashboard: FC = () => {
  const [modal, setModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const [qrmodal, setQrModal] = useState(false);
  const [restaurantData, setRestaurantData] = useState<any>([]);
  const [workinghoursData, setworkinghoursData] = useState([]);
  const [workinghourId, setworkinghourId] = useState(0);
  const [workinghourobj, setworkinghourobj] = useState<any>(0);
  const { id } = useParams<any>();
  const restid = localStorage.getItem('rest_id') as string;
  const dutchWeekDays = new Map([
    ['Monday', 'Maandag'],
    ['Tuesday', 'Dinsdag'],
    ['Wednesday', 'Woensdag'],
    ['Thursday', 'Donderdag'],
    ['Friday', 'Vrijdag'],
    ['Saturday', 'Zaterdag'],
    ['Sunday', 'Zondag'],
  ]);
  const mainPanel = useRef<any>();

  const [chosenBanner, setChosenBanner] = useState<any>(null);
  const [successBanner, setSuccessBanner] = useState<any>('');
  const [chosenLogo, setChosenLogo] = useState<any>(null);
  const [successLogo, setSuccessLogo] = useState<any>('');

  const restaurant = useSelector(({ restaurantData }: any) => restaurantData);

  const dispatch = useDispatch();

  const getWorkingHours = () => {
    API.restaurantApi
      .getRestaurantWorkingHours(restid)
      .then((res) => {
        setworkinghoursData(res.data);
      })
      .catch((err) => console.log('Error is', err.message));
  };

  useEffect(() => {
    setRestaurantData(restaurant);
  }, [restaurant]);

  useEffect(() => {
    // @ts-ignore
    document.getElementById('dashboard_colorchange').style.color = 'darkred';
    dispatch(getRestaurantData(restid));
    getWorkingHours();
    // console.log('this is second useEffect restaurantData', restaurantData);

    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(mainPanel.current);
      document.body.classList.toggle('perfect-scrollbar-on');
    }
    return () => {
      if (navigator.platform.indexOf('Win') > -1) {
        ps.destroy();
        document.body.classList.toggle('perfect-scrollbar-on');
      }
    };
  }, [qrmodal, modal, id, restid]);

  useEffect(() => {
    restaurant?.Logo && setSuccessLogo(restaurant?.Logo);
    restaurant?.image && setSuccessBanner(restaurant?.image);
  }, [restaurant]);

  const handleLogoChange = (e: any) => {
    setChosenLogo(URL.createObjectURL(e.target.files[0]));
  };

  const handleBannerChange = (e: any) => {
    setChosenBanner(URL.createObjectURL(e.target.files[0]));
  };

  const handleRemoveLogo = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('pk', restid);
    formData.append('Logo', '');
    await API.restaurantApi
      .partiallyUpdateRestaurantData(formData, restid)
      .then((res) => {
        toast.success('Updated Successfully!', { containerId: 'B' });
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((err) => {
        window.location.reload();
        console.log('err', err);
      });
  };

  const handleRemoveBanner = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('pk', restid);
    formData.append('image', '');
    await API.restaurantApi
      .partiallyUpdateRestaurantData(formData, restid)
      .then((res) => {
        toast.success('Updated Successfully!', { containerId: 'B' });
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((err) => {
        window.location.reload();
        console.log('err', err);
      });
  };

  const renderLogoImage = () => {
    if (chosenLogo) {
      return <img className="img-thumbnail mb-3" src={chosenLogo} alt="" />;
    } else if (successLogo) {
      return <img className="img-thumbnail mb-3" src={successLogo} alt="" />;
    } else {
      return (
        <div>
          <p>Er is geen logo ingesteld</p>
          <img
            className="restaurant_images"
            id="blah"
            src={
              chosenLogo
                ? chosenLogo
                : 'https://res.cloudinary.com/updivision/image/upload/v1/media/Storage/file-upload-image_cmfw18'
            }
            alt="logo image"
          />
        </div>
      );
    }
  };

  const renderBannerImage = () => {
    if (chosenBanner) {
      return <img className="img-thumbnail mb-3" src={chosenBanner} alt="" />;
    } else if (successBanner) {
      return <img className="img-thumbnail mb-3" src={successBanner} alt="" />;
    } else {
      return (
        <div>
          <p>Er is geen banner ingesteld</p>
          <img
            className="restaurant_images"
            id="blah"
            src={
              chosenBanner
                ? chosenBanner
                : 'https://res.cloudinary.com/updivision/image/upload/v1/media/Storage/file-upload-image_cmfw18'
            }
            alt="banner image"
          />
        </div>
      );
    }
  };

  const toggle = (id: any, workinghour: any) => {
    setModal(!modal);
    setworkinghourId(id);
    setworkinghourobj(workinghour);
    getWorkingHours();
  };
  const toggleDeleteModal = (id: any, articleopt: any) => {
    setDeleteModal(!deleteModal);
    setworkinghourId(id);
    setworkinghourobj(articleopt);
  };

  const deleteWorkinghour = async (id: any) => {
    await API.restaurantApi.deleteRestaurantWorkingHours(id).then((res) => {
      toast.success('Deleted Successfully!', { containerId: 'B' });
      getWorkingHours();
    });
  };

  const changerestaurantdata = (e: any) => {
    setRestaurantData({
      ...restaurantData,
      [e.target.name]: [e.target.value],
    });
  };

  const handleCheckboxChange = (e: any) => {
    console.log(restaurantData);
    console.log(restaurantData[e.target.name]);

    setRestaurantData({
      ...restaurantData,
      [e.target.name]: !restaurantData[e.target.name],
    });
  };

  const handleChangeStatus = () => {
    let valueA;
    if (restaurantData.Availability === 'OFFLINE') {
      valueA = 'ONLINE';
    } else {
      valueA = 'OFFLINE';
    }
    setRestaurantData({
      ...restaurantData,
      Availability: valueA,
    });
  };

  const handleChangeDelivery = (e: any) => {
    let valueA;
    if (restaurantData.pickup_option === false) {
      valueA = false;
    } else {
      valueA = true;
    }
    setRestaurantData({
      ...restaurantData,
      delivery_option: e,
    });
  };

  const handleChangePickup = (e: any) => {
    let valueA;

    if (restaurantData.pickup_option === false) {
      valueA = false;
    } else {
      valueA = true;
    }
    setRestaurantData({
      ...restaurantData,
      pickup_option: e,
    });
  };

  const SubmitRestaurantdata = async (e: any) => {
    e.preventDefault();
    // e.stopPropagation();
    const restname = e.target.elements.RestaurantName.value;
    const location = e.target.elements.Location.value;
    const zipcode = e.target.elements.zipcode.value;

    let logo = null;
    if (e.target.elements.logo.files[0]) {
      logo = e.target.elements.logo.files[0];
    }

    let banner = null;
    if (e.target.elements.banner.files[0]) {
      banner = e.target.elements.banner.files[0];
    }

    let availibilty;
    const pickup_option = true as any;
    const delivery_option = true as any;
    const paynowbutton = e.target.elements.paynowbutton.checked;
    const paylaterbutton = e.target.elements.paylaterbutton.checked;
    const askforbillbutton = e.target.elements.askforbillbutton.checked;
    const askforservicebutton = e.target.elements.askforservicebutton.checked;
    // 12March
    const enable_email_field = e.target.elements.enable_email_field.checked;
    const enable_gsm_field = e.target.elements.enable_gsm_field.checked;
    const enable_password_field = e.target.elements.enable_password_field.checked;
    const extra_field_1 = e.target.elements.extra_field_1.value;
    const extra_field_2 = e.target.elements.extra_field_2.value;

    const payment_method_type = e.target.elements.payment_method_type.value || 'PAYNL';
    const paynl_service_id = e.target.elements.paynl_service_id?.value || '';
    const paynl_token_code = e.target.elements.paynl_token_code?.value || '';
    const apikey = e.target.elements.apikey.value;
    // const slug = e.target.elements.slug.value;
    const enable_sms = e.target.elements.enable_sms.checked;
    const max_order = e.target.elements.max_order.value;
    const time_interval = e.target.elements.time_interval.value;
    const delivery_cost = e.target.elements.delivery_cost.value as any;
    const min_amount_for_free_delivery = e.target.elements.min_amount_for_free_delivery.value;
    let availablity_zone_zipcodes;
    if (
      e.target.elements.availablity_zone_zipcodes.value.replaceAll(/,/g, ' ').split(' ').pop() ===
      ''
    ) {
      availablity_zone_zipcodes = e.target.elements.availablity_zone_zipcodes.value
        .replaceAll(/,/g, ' ')
        .split(' ')
        .slice(0, -1);
    } else {
      availablity_zone_zipcodes = e.target.elements.availablity_zone_zipcodes.value
        .replaceAll(/,/g, ' ')
        .split(' ');
    }
    const service_cost_fixed_amount = e.target.elements.service_cost_fixed_amount.value;
    const service_cost_percentage = e.target.elements.service_cost_percentage.value;
    const service_cost_type = e.target.elements.service_cost_type.value;
    const title_ = e.target.elements.page_title.value;
    const defaultMinutes_ = e.target.elements.defaultMinutes.value;
    const customer_msg_ = e.target.elements.customer_msg.value;
    let meta_titles = [];
    if (e.target.elements.meta_titles.value.replaceAll(/,/g, ' ').split(' ').pop() === '') {
      meta_titles = e.target.elements.meta_titles.value
        .replaceAll(/,/g, ' ')
        .split(' ')
        .slice(0, -1);
    } else {
      meta_titles = e.target.elements.meta_titles.value.replaceAll(/,/g, ' ').split(' ');
    }
    let meta_descriptions = [];
    if (e.target.elements.meta_descriptions.value.replaceAll(/,/g, ' ').split(' ').pop() === '') {
      meta_descriptions = e.target.elements.meta_descriptions.value
        .replaceAll(/,/g, ' ')
        .split(' ')
        .slice(0, -1);
    } else {
      meta_descriptions = e.target.elements.meta_descriptions.value
        .replaceAll(/,/g, ' ')
        .split(' ');
    }
    let meta_keywords = [];
    if (e.target.elements.meta_keywords.value.replaceAll(/,/g, ' ').split(' ').pop() === '') {
      meta_keywords = e.target.elements.meta_keywords.value
        .replaceAll(/,/g, ' ')
        .split(' ')
        .slice(0, -1);
    } else {
      meta_keywords = e.target.elements.meta_keywords.value.replaceAll(/,/g, ' ').split(' ');
    }

    const email_restaurant = e.target.elements.email_restaurant.value;
    const phone_restaurant = e.target.elements.phone_restaurant.value;

    // Service cost type
    let modifiedServiceCostFixedAmount;
    let modifiedServiceCostPercentage;

    if (service_cost_type === 'none') {
      modifiedServiceCostFixedAmount = 0;
      modifiedServiceCostPercentage = 0;
    } else {
      modifiedServiceCostFixedAmount = parseFloat(service_cost_fixed_amount).toFixed(2);
      modifiedServiceCostPercentage = parseFloat(service_cost_percentage).toFixed(2);
    }

    //04/29 options for delay 1 day for delivery/pickup
    const delay_collect = e.target.elements.delay_collect.checked;
    // const delay_delivery = e.target.elements.delay_delivery.checked;

    const formData = new FormData();

    formData.append('RestaurantName', restname);
    formData.append('Location', location);

    if (e.target.elements.Availability.checked === true) {
      availibilty = 'ONLINE';
    } else {
      availibilty = 'OFFLINE';
    }

    if (logo) {
      formData.append('Logo', logo);
    }

    if (banner) {
      formData.append('image', banner);
    }

    formData.append('askforbillbutton', askforbillbutton);
    formData.append('askforservicebutton', askforservicebutton);
    formData.append('enable_sms', enable_sms);
    formData.append('paynowbutton', paynowbutton);
    formData.append('paylaterbutton', paylaterbutton);

    // 12March
    formData.append('enable_email_field', enable_email_field);
    formData.append('enable_gsm_field', enable_gsm_field);
    formData.append('enable_password_field', enable_password_field);
    formData.append('extra_field_1', extra_field_1);
    formData.append('extra_field_2', extra_field_2);

    // formData.append('delay_delivery', delay_delivery);
    formData.append('delay_collect', delay_collect);

    formData.append('Availability', availibilty);
    formData.append('pickup_option', pickup_option);
    formData.append('delivery_option', delivery_option);

    formData.append('apikey', apikey);
    formData.append('payment_method_type', payment_method_type);
    if (payment_method_type === 'PAYNL') {
      formData.append('paynl_service_id', paynl_service_id);
      formData.append('paynl_token_code', paynl_token_code);
    } else {
      formData.append('paynl_service_id', '');
      formData.append('paynl_token_code', '');
    }

    // formData.append('slug', slug);
    formData.append('max_order', max_order);
    formData.append('time_interval', time_interval);
    formData.append('zipcode', zipcode);

    formData.append('delivery_cost', parseFloat(String(+delivery_cost)).toFixed(2));
    formData.append(
      'min_amount_for_free_delivery',
      parseFloat(String(+min_amount_for_free_delivery)).toFixed(2)
    );
    formData.append('availablity_zone_zipcodes', JSON.stringify(availablity_zone_zipcodes));
    formData.append('meta_titles', JSON.stringify(meta_titles));
    formData.append('meta_keywords', JSON.stringify(meta_keywords));

    formData.append('meta_descriptions', JSON.stringify(meta_descriptions));

    // formData.append('service_cost_fixed_amount', service_cost_fixed_amount);
    // formData.append('service_cost_percentage', service_cost_percentage);

    formData.append('service_cost_fixed_amount', String(modifiedServiceCostFixedAmount));
    formData.append('service_cost_percentage', String(modifiedServiceCostPercentage));
    formData.append('service_cost_type', service_cost_type);

    formData.append('page_title', title_);

    formData.append('defaultMinutes', defaultMinutes_);
    formData.append('customer_msg', customer_msg_);

    formData.append('email_restaurant', email_restaurant);
    formData.append('phone_restaurant', phone_restaurant);

    await API.restaurantApi
      .updateRestaurantData(formData, restid)
      .then(() => {
        toast.success('Updated Successfully!', { containerId: 'B' });
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch(() => {
        window.location.reload();
      });
  };

  const handleChangePaymentMethod = (e: any) => {
    // Change method and clear fields
    setRestaurantData({
      ...restaurantData,
      payment_method_type: e.target.value,
      apikey: '',
      paynl_service_id: '',
      paynl_token_code: '',
    });
  };

  return (
    <>
      <Sidebar />
      <div id="border-top-radius" className="container-fluid main-panel" ref={mainPanel}>
        <Header />

        <div className="row mr-md-5 mt-5 pt-5">
          <VisitUrl />
          <div className="col-xl-10 offset-xl-1 col-lg-9 offset-lg-3 col-md-8 offset-md-4 col-sm-12 col-12 offsetMd5 text-center">
            <span className="h4 mb-3">Bewerk webshop</span>
          </div>
          <div className="col-xl-10 offset-xl-1 col-lg-8 offset-lg-4 col-md-8 offset-md-4 col-sm-12 col-12 mt-3 offsetMd5">
            <form onSubmit={(e) => SubmitRestaurantdata(e)} className="d-flex flex-wrap">
              <div className="admin-dashboard-left">
                <label htmlFor="sel1" className="control-label font-weight-bold m-0 mb-2 mt-3">
                  Naam restaurant:
                </label>
                <InputField
                  name="RestaurantName"
                  placeholder="Bv. Bodega"
                  value={restaurantData.RestaurantName || ''}
                  onChange={(e: any) => changerestaurantdata(e)}
                />
                <label htmlFor="sel1" className="control-label font-weight-bold m-0 mb-2 mt-3">
                  Locatie:
                </label>
                <textarea
                  className="form-control mt-1"
                  rows={1}
                  name="Location"
                  placeholder="Bv. Vlamstraat 7"
                  value={restaurantData.Location}
                  onChange={(e) => changerestaurantdata(e)}
                />
                <label htmlFor="sel1" className="control-label font-weight-bold m-0 mb-2 mt-3">
                  Beschikbaarheidszone :(komma gescheiden)
                </label>
                <textarea
                  className="form-control mt-1"
                  rows={1}
                  name="availablity_zone_zipcodes"
                  placeholder="Postcodes van de beschikbaarheidszone van restaurants"
                  value={
                    restaurantData.availablity_zone_zipcodes
                      ? restaurantData.availablity_zone_zipcodes
                      : ''
                  }
                  onChange={(e) => changerestaurantdata(e)}
                />
                <label htmlFor="sel1" className="control-label font-weight-bold m-0 mb-2 mt-3">
                  Postcode:
                </label>
                <input
                  className="form-control mt-1"
                  name="zipcode"
                  placeholder="Bv. 9450"
                  value={restaurantData.zipcode || ''}
                  onChange={(e) => changerestaurantdata(e)}
                />

                <div className="image-upload-holder d-flex flex-wrap">
                  <div className="image-upload">
                    <label htmlFor="banner">
                      <div className="hint-holder d-flex justify-content-center mb-2 mt-2 align-items-center">
                        <div className="hint-wrapper position-relative">
                          <p className="hint-symbol mr-2 d-flex align-items-center justify-content-center">
                            ?
                          </p>
                          <p className="hint-text position-absolute">
                            Dit is de afbeelding die bovenaan uw webshop verschijnt. De aanbevolen
                            grootte is maximaal 2 MB.
                          </p>
                        </div>
                        <h6 className="hint-label">Banner:</h6>
                      </div>
                      {renderBannerImage()}
                    </label>
                    <button
                      type="button"
                      onClick={(e) => handleRemoveBanner(e)}
                      name="remove_logo"
                      className="btn text-danger"
                    >
                      verwijderen
                    </button>
                    <input
                      onChange={handleBannerChange}
                      id="banner"
                      name="banner"
                      type="file"
                      className="form-control"
                    />
                  </div>

                  <div className="image-upload">
                    <label htmlFor="logo">
                      <div className="hint-holder d-flex justify-content-center mb-2 mt-2 align-items-center">
                        <div className="hint-wrapper position-relative">
                          <p className="hint-symbol mr-2 d-flex align-items-center justify-content-center">
                            ?
                          </p>
                          <p className="hint-text position-absolute">
                            Dit is de afbeelding die wordt gebruikt als uw profielfoto. De
                            aanbevolen grootte is maximaal 2 MB.
                          </p>
                        </div>
                        <h6 className="hint-label">Logo:</h6>
                      </div>
                      {renderLogoImage()}
                    </label>
                    <button
                      type="button"
                      onClick={(e) => handleRemoveLogo(e)}
                      name="remove_logo"
                      className="btn text-danger"
                    >
                      verwijderen
                    </button>
                    <input
                      onChange={handleLogoChange}
                      id="logo"
                      name="logo"
                      type="file"
                      className="form-control"
                    />
                  </div>
                </div>

                <div className="form-group d-none">
                  <label
                    htmlFor="availibility"
                    className="control-label font-weight-bold m-0 mb-2 mt-3"
                  >
                    Status restaurant:
                  </label>
                  <div className="form-group">
                    <Switch
                      onChange={() => handleChangeStatus()}
                      onColor="#dc3545"
                      name="Availability"
                      checked={restaurantData.Availability === 'ONLINE'}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label
                    htmlFor="defaultMinutes"
                    className="control-label font-weight-bold m-0 mb-2 mt-3"
                  >
                    Bereidingstijd:
                  </label>
                  <div className="form-group form-select">
                    <div className="select-holder position-relative">
                      <select
                        name="defaultMinutes"
                        className="form-control"
                        value={restaurantData.defaultMinutes || ''}
                        onChange={(e) => changerestaurantdata(e)}
                      >
                        <option value="">Select minutes</option>
                        <option value="5">5 minutes</option>
                        <option value="10">10 minutes</option>
                        <option value="15">15 minutes</option>
                        <option value="20">20 minutes</option>
                        <option value="25">25 minutes</option>
                        <option value="30">30 minutes</option>
                        <option value="35">35 minutes</option>
                        <option value="40">40 minutes</option>
                        <option value="45">45 minutes</option>
                        <option value="50">50 minutes</option>
                        <option value="55">55 minutes</option>
                        <option value="60">60 minutes</option>
                      </select>
                      <img
                        src="https://res.cloudinary.com/updivision/image/upload/v1/media/Storage/arrow-down_nwzekl"
                        alt="arrow-down"
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label
                    htmlFor="customer_msg"
                    className="control-label font-weight-bold m-0 mb-2 mt-3"
                  >
                    Bericht indien gesloten:
                  </label>
                  <div className="form-group">
                    <input
                      name="customer_msg"
                      className="form-control"
                      type="input"
                      id="customer_msg"
                      placeholder="Bv. Wij zijn gesloten voor de feestdagen en zijn weer terug op 7 januari!"
                      value={restaurantData.customer_msg || ''}
                      onChange={(e) => changerestaurantdata(e)}
                    />
                  </div>
                </div>

                {/* Hidden until another payment methods added */}
                <div className="form-group d-none">
                  <label htmlFor="paynow" className="control-label font-weight-bold m-0 mb-2 mt-3">
                    Choose payment method
                  </label>
                  <select
                    value={restaurantData.payment_method_type}
                    onChange={(e) => handleChangePaymentMethod(e)}
                    name="payment_method_type"
                    className="form-control"
                    id="payment_method_type"
                  >
                    <option value="PAYNL">Pay.nl</option>
                  </select>
                </div>

                <div className="form-group d-none">
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label className="control-label font-weight-bold m-0 mb-2 mt-3">
                    <span style={{ textTransform: 'capitalize' }}>
                      {restaurantData.payment_method_type}
                    </span>{' '}
                    online betalingen API:
                  </label>
                  <input
                    name="apikey"
                    className="form-control"
                    type="input"
                    id="api_id"
                    placeholder="Enter api key"
                    value={restaurantData.apikey || ''}
                    onChange={(e) => changerestaurantdata(e)}
                  />
                </div>

                <div className="form-group d-none">
                  <label className="control-label font-weight-bold m-0 mb-2 mt-3">
                    Paynl service id (SL-....-....)
                  </label>
                  <input
                    name="paynl_service_id"
                    className="form-control"
                    type="input"
                    id="paynl_service_id"
                    placeholder="Enter service id"
                    value={restaurantData.paynl_service_id || ''}
                    onChange={(e) => changerestaurantdata(e)}
                  />
                </div>

                <div className="form-group d-none">
                  <label className="control-label font-weight-bold m-0 mb-2">
                    Paynl token code (AT-....-....)
                  </label>
                  <input
                    name="paynl_token_code"
                    className="form-control"
                    type="input"
                    id="paynl_token_code"
                    placeholder="Enter token code"
                    value={restaurantData.paynl_token_code || ''}
                    onChange={(e) => changerestaurantdata(e)}
                  />
                </div>

                <div className="form-group form-checkbox mt-2">
                  <label htmlFor="paynow" className="control-label font-weight-bold m-0 mt-3">
                    Mogelijkheid om online te betalen
                  </label>
                  <input
                    type="checkbox"
                    checked={restaurantData.paynowbutton}
                    onChange={(e) => handleCheckboxChange(e)}
                    id="paynow"
                    name="paynowbutton"
                    className="mr-2 mt-3"
                  />
                </div>
                <div className="form-group form-checkbox">
                  <label htmlFor="paynow" className="control-label font-weight-bold m-0 mt-3">
                    Mogelijkheid om ter plaatse te betalen
                  </label>
                  <input
                    type="checkbox"
                    checked={restaurantData.paylaterbutton}
                    onChange={(e) => handleCheckboxChange(e)}
                    id="paynow"
                    name="paylaterbutton"
                    className="mr-2 mt-3"
                  />
                </div>
                <div className="form-group form-checkbox">
                  <label htmlFor="paynow" className="control-label font-weight-bold m-0 mt-3">
                    Mogelijkheid om een ober aan tafel te roepen
                  </label>
                  <input
                    type="checkbox"
                    checked={restaurantData.askforservicebutton}
                    onChange={(e) => handleCheckboxChange(e)}
                    id="askforservicebutton"
                    name="askforservicebutton"
                    className="mr-2 mt-3"
                  />
                </div>

                <div className="form-group form-checkbox">
                  <label htmlFor="paynow" className="control-label font-weight-bold m-0 mt-3">
                    Mogelijkheid om de rekening te vragen
                  </label>
                  <input
                    type="checkbox"
                    checked={restaurantData.askforbillbutton}
                    onChange={(e) => handleCheckboxChange(e)}
                    id="askforbillbutton"
                    name="askforbillbutton"
                    className="mr-2 mt-3"
                  />
                </div>
                <div className="form-group form-checkbox mb-2">
                  <label htmlFor="enable_sms" className="control-label font-weight-bold m-0 mt-3">
                    Mogelijkheid om een sms te ontvangen indien bestelling klaar is
                  </label>
                  <input
                    type="checkbox"
                    checked={restaurantData.enable_sms}
                    onChange={(e) => handleCheckboxChange(e)}
                    id="enable_sms"
                    name="enable_sms"
                    className="mr-2 mt-3"
                  />
                </div>

                <label htmlFor="sel1" className="control-label font-weight-bold m-0 mb-2 mt-3">
                  Email restaurant:
                </label>
                <InputField
                  name="email_restaurant"
                  type="email"
                  placeholder="Bv. info@bodega.be"
                  value={restaurantData.email_restaurant || ''}
                  onChange={(e: any) => changerestaurantdata(e)}
                />
                <label htmlFor="sel1" className="control-label font-weight-bold m-0 mb-2 mt-3">
                  Tel restaurant:
                </label>
                <InputField
                  name="phone_restaurant"
                  type="tel"
                  pattern="[0-9]+"
                  placeholder="Bv.  +32 479 00 00 00"
                  value={restaurantData.phone_restaurant || ''}
                  onChange={(e: any) => changerestaurantdata(e)}
                />
                {/* ADDING OPTIONS FOR 1 DAY DELAY IN PICKUP */}
                <div className="form-group form-checkbox mt-2">
                  <label
                    htmlFor="delay_collect"
                    className="control-label font-weight-bold m-0 mt-3"
                  >
                    Stel de ophaling met 1 dag uit
                  </label>
                  <input
                    type="checkbox"
                    checked={restaurantData.delay_collect}
                    onChange={(e) => handleCheckboxChange(e)}
                    id="delay_collect"
                    name="delay_collect"
                    className="mr-2 mt-3"
                  />
                </div>
              </div>

              <div className="admin-dashboard-right">
                <div className="form-group form-select">
                  <label
                    htmlFor="service_cost_type"
                    className="control-label font-weight-bold m-0 mb-2 mt-3"
                  >
                    Type servicekosten:
                  </label>
                  <div className="select-holder position-relative">
                    <select
                      value={restaurantData.service_cost_type}
                      onChange={(e) => changerestaurantdata(e)}
                      name="service_cost_type"
                      className="form-control"
                      id="service_cost_type"
                    >
                      <option value="fixed_amount">Vaste hoeveelheid</option>
                      <option value="percentage">Percentage</option>
                      <option value="none">Geen</option>
                    </select>
                    <img
                      src="https://res.cloudinary.com/updivision/image/upload/v1/media/Storage/arrow-down_nwzekl"
                      alt="arrow-down"
                    />
                  </div>
                </div>
                <label
                  htmlFor="service_cost_fixed_amount"
                  className="control-label font-weight-bold m-0 mb-2 mt-3"
                >
                  Vast bedrag:
                </label>
                <InputField
                  disabled={
                    (restaurantData.service_cost_type &&
                      restaurantData.service_cost_type[0] === 'percentage') ||
                    (restaurantData.service_cost_type &&
                      restaurantData.service_cost_type[0] === 'none') ||
                    restaurantData.service_cost_type === 'percentage' ||
                    restaurantData.service_cost_type === 'none'
                  }
                  name="service_cost_fixed_amount"
                  placeholder="€"
                  value={restaurantData.service_cost_fixed_amount || ''}
                  onChange={(e: any) => changerestaurantdata(e)}
                />
                <label
                  htmlFor="service_cost_percentage"
                  className="control-label font-weight-bold m-0 mb-2 mt-3"
                >
                  Servicekosten %:
                </label>
                <InputField
                  disabled={
                    (restaurantData.service_cost_type &&
                      restaurantData.service_cost_type[0] === 'fixed_amount') ||
                    (restaurantData.service_cost_type &&
                      restaurantData.service_cost_type[0] === 'none') ||
                    restaurantData.service_cost_type === 'fixed_amount' ||
                    restaurantData.service_cost_type === 'none'
                  }
                  name="service_cost_percentage"
                  placeholder="%"
                  value={restaurantData.service_cost_percentage || ''}
                  onChange={(e: any) => changerestaurantdata(e)}
                />
                <label
                  htmlFor="delveryCost"
                  className="control-label font-weight-bold m-0 mb-2 mt-3"
                >
                  Leveringskosten:
                </label>
                <InputField
                  name="delivery_cost"
                  placeholder="Price"
                  value={restaurantData.delivery_cost || ''}
                  onChange={(e: any) => changerestaurantdata(e)}
                />
                <label
                  htmlFor="freeDelivery"
                  className="control-label font-weight-bold m-0 mb-2 mt-3"
                >
                  Gratis levering indien de bestelling boven het volgende bedrag is:
                </label>
                <InputField
                  name="min_amount_for_free_delivery"
                  placeholder="Price"
                  value={restaurantData.min_amount_for_free_delivery || ''}
                  onChange={(e: any) => changerestaurantdata(e)}
                />
                <label htmlFor="slug" className="control-label font-weight-bold m-0 mb-2 mt-3">
                  Page Title:
                </label>
                <InputField
                  name="page_title"
                  placeholder="Page Title"
                  value={restaurantData.page_title || ''}
                  onChange={(e: any) => changerestaurantdata(e)}
                />
                <label
                  htmlFor="sel1"
                  className="control-label font-weight-bold m-0 mb-2 mt-3 d-none"
                >
                  Meta Titles(comma seperated)
                </label>
                <textarea
                  className="form-control mt-1 d-none"
                  rows={4}
                  name="meta_titles"
                  placeholder="Meta titles "
                  value={restaurantData.meta_titles}
                  onChange={(e) => changerestaurantdata(e)}
                />
                <label
                  htmlFor="sel1"
                  className="control-label font-weight-bold m-0 mb-2 mt-3 d-flex align-items-center"
                >
                  <p className="hint-label">Meta omschrijving:</p>
                  <div className="hint-wrapper position-relative">
                    <p className="hint-symbol ml-2 d-flex align-items-center justify-content-center">
                      ?
                    </p>
                    <p className="hint-text position-absolute">
                      Een meta omschrijving is een korte beschrijving van de webpagina die je in
                      Google ziet. Geef in maximaal 160 karakters kort weer waarover de webshop
                      gaat.
                    </p>
                  </div>
                </label>
                <textarea
                  className="form-control mt-1"
                  rows={4}
                  name="meta_descriptions"
                  placeholder="Bv. Bodega is een frituur die met hart en ziel allerlei frituursnacks klaarmaakt. Voor honger, groot of klein, moet je bij Bodega zijn!"
                  value={restaurantData.meta_descriptions}
                  onChange={(e) => changerestaurantdata(e)}
                />
                <label
                  htmlFor="sel1"
                  className="control-label font-weight-bold m-0 mb-2 mt-3 d-flex align-items-center"
                >
                  <p className="hint-label">Meta keywords (komma gescheiden):</p>
                  <div className="hint-wrapper position-relative">
                    <p className="hint-symbol ml-2 d-flex align-items-center justify-content-center">
                      ?
                    </p>
                    <p className="hint-text position-absolute">
                      Meta keywords zijn tags die je kunt gebruiken om zoekmachines meer informatie
                      te geven over de inhoud van jouw webshop. Ze staan in de HTML-broncode van een
                      webpagina en zijn niet zichtbaar voor bezoekers.
                    </p>
                  </div>
                </label>
                <textarea
                  className="form-control mt-1"
                  rows={4}
                  name="meta_keywords"
                  placeholder="Bv. frituur, snackbar, bodega, restaurant,afhaal, levering"
                  value={restaurantData.meta_keywords}
                  onChange={(e) => changerestaurantdata(e)}
                />
                <label
                  htmlFor="slug"
                  className="control-label font-weight-bold m-0 mb-2 mt-3 d-none"
                >
                  Slug
                </label>
                <label
                  htmlFor="slug"
                  className="control-label font-weight-bold m-0 mb-2 mt-3 d-flex align-items-center"
                >
                  <p className="hint-label">Max aantal bestellingen:</p>
                  <div className="hint-wrapper position-relative">
                    <p className="hint-symbol ml-2 d-flex align-items-center justify-content-center">
                      ?
                    </p>
                    <p className="hint-text position-absolute">
                      Hier vul je in hoeveel bestellingen je aan kan per dag tot jouw stock op is.
                    </p>
                  </div>
                </label>
                <InputField
                  name="max_order"
                  placeholder="Bv. 10"
                  value={restaurantData.max_order || ''}
                  onChange={(e: any) => changerestaurantdata(e)}
                />
                <label
                  htmlFor="slug"
                  className="control-label font-weight-bold m-0 mb-2 mt-3 d-flex align-items-center"
                >
                  <p className="hint-label">Per tijdsinterval (in minuten):</p>
                  <div className="hint-wrapper position-relative">
                    <p className="hint-symbol ml-2 d-flex align-items-center justify-content-center">
                      ?
                    </p>
                    <p className="hint-text position-absolute">
                      Hier vul je in hoeveel tijd tussen elke bestelling moet zitten.
                    </p>
                  </div>
                </label>
                <InputField
                  name="time_interval"
                  placeholder="Bv. 30"
                  value={restaurantData.time_interval || 'z'}
                  onChange={(e: any) => changerestaurantdata(e)}
                />

                {/* 12March */}
                <div>
                  <div className="form-group form-checkbox mt-2">
                    <label
                      htmlFor="enable_email_field"
                      className="control-label font-weight-bold m-0 mt-3"
                    >
                      Vraag klant om e-mail
                    </label>
                    <input
                      type="checkbox"
                      checked={restaurantData.enable_email_field}
                      onChange={(e) => handleCheckboxChange(e)}
                      id="enable_email_field"
                      name="enable_email_field"
                      className="mr-2 mt-3"
                    />
                  </div>

                  <div className="form-group form-checkbox">
                    <label
                      htmlFor="enable_gsm_field"
                      className="control-label font-weight-bold m-0 mt-3"
                    >
                      Vraag klant om telefoonnummer
                    </label>
                    <input
                      type="checkbox"
                      checked={restaurantData.enable_gsm_field}
                      onChange={(e) => handleCheckboxChange(e)}
                      id="enable_gsm_field"
                      name="enable_gsm_field"
                      className="mr-2 mt-3"
                    />
                  </div>

                  <div className="form-group form-checkbox">
                    <label
                      htmlFor="enable_password_field"
                      className="control-label font-weight-bold m-0 mt-3"
                    >
                      Vraag klant om wachtwoord/registratie
                    </label>
                    <input
                      type="checkbox"
                      checked={restaurantData.enable_password_field}
                      onChange={(e) => handleCheckboxChange(e)}
                      id="enable_password_field"
                      name="enable_password_field"
                      className="mr-2 mt-3"
                    />
                  </div>
                </div>

                <label
                  htmlFor="extra_field_1"
                  className="control-label font-weight-bold m-0 mb-2 mt-3"
                >
                  Extra vereist veld 1:
                </label>
                <InputField
                  name="extra_field_1"
                  type="extra_field_1"
                  placeholder="Vraag jouw klanten naar specifieke informatie."
                  value={restaurantData.extra_field_1 || ''}
                  onChange={(e: any) => changerestaurantdata(e)}
                />
                <label
                  htmlFor="extra_field_2"
                  className="control-label font-weight-bold m-0 mb-2 mt-3"
                >
                  Extra vereist veld 2:
                </label>
                <InputField
                  name="extra_field_2"
                  type="extra_field_2"
                  placeholder="Vraag jouw klanten naar specifieke informatie."
                  value={restaurantData.extra_field_2 || ''}
                  onChange={(e: any) => changerestaurantdata(e)}
                />
              </div>

              {/* <div className="form-group form-checkbox mt-2">
                  <label htmlFor="delay_delivery" className="control-label font-weight-bold m-0 mt-3">
                    Delay delivery with 1 day
                  </label>
                  <input
                    type="checkbox"
                    checked={restaurantData.delay_delivery}
                    onChange={(e) => handleCheckboxChange(e)}
                    id="delay_delivery"
                    name="delay_delivery"
                    className="mr-2 mt-3"
                  />
                </div> */}
              {/* ================= */}

              <VisitUrl />
            </form>
          </div>
        </div>
      </div>

      <WorkingHoursModal
        workinghourobj={workinghourobj}
        modal={modal}
        toggle={toggle}
        workinghourid={workinghourId}
      />
      <DownloadQR />
      <DeleteModalComponent
        articleopt={workinghourobj}
        isOpen={deleteModal}
        toggle={toggleDeleteModal}
        articleoptId={workinghourId}
        deleteFunction={deleteWorkinghour}
      />
    </>
  );
};
export default Dashboard;
