import axios, { AxiosRequestConfig } from 'axios';
import { API_BASE_URL } from '../services/urls';

const configure = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=utf-8',
  },
  baseURL: API_BASE_URL,
} as AxiosRequestConfig;

const api = axios.create(configure);

api.interceptors.request.use((config) => {
  if (
    config.url?.includes('/restaurant/api/login/') ||
    config.url?.includes('/restaurant/forget-password/')
  )
    return config;

  if (!config.headers.common.Autorization && localStorage.getItem('jwt')) {
    config.headers.common.Authorization = `JWT ${localStorage.getItem('jwt')}`;
  }

  return config;
});

api.interceptors.response.use((response) => {
  return response.data;
});

export default api;

export function CreateServiceUrl(baseUrl: string) {
  return (paths: TemplateStringsArray, url?: string): string => {
    return `/${baseUrl}/${paths[0]}${url || ''}`;
  };
}
