import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardTitle } from 'reactstrap';

const MainViewCard: FC<any> = (props) => {
  return (
    <div className="card text-center m-3">
      <Link to={props.link} className="card-body d-flex flex-column justify-content-center align-items-center">
        <img src={props.iconUrl} className="pb-3" alt="..." width="50%" height="auto" />
        <h5 className="card-title font-weight-bold" style={{ color: 'rgb(1, 1, 122)' }}>
          {props.title}
        </h5>
      </Link>
    </div>
  );
};

export default MainViewCard;
