import axios from 'axios';
import PerfectScrollbar from 'perfect-scrollbar';
import React, { FC, useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Nav } from 'reactstrap';
import easyservedLogo from '../../assets/images/logo.png';
import line from '../../assets/images/line.png';
import { API_BASE_URL } from '../../services/urls';
import '../style.css';

let ps: any;

const ChainSidebar: FC<any> = (props) => {
  const [openOptions, setOpenOptions] = useState(false);
  const rest_id = localStorage.getItem('rest_id');
  const sidebar = useRef<any>();

  useEffect(() => {
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(sidebar.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
    return () => {
      if (navigator.platform.indexOf('Win') > -1) {
        ps.destroy();
      }
    };
  });

  const closeSidebar = () => {
    document.documentElement.classList.remove('nav-open');
  };

  const logout = () => {
    localStorage.removeItem('rest_id');
    localStorage.removeItem('jwt');
    localStorage.removeItem('users_list');
    localStorage.removeItem('current_user');
    localStorage.removeItem('is_chain_manager');
    localStorage.removeItem('chain');

    axios
      .get(`${API_BASE_URL}/restaurant/api/logout/`)
      .catch((err) => console.log('Error is', err.message));
  };

  const onClickArticleOptions = () => {
    closeSidebar();
    setOpenOptions(!openOptions);
  };

  return (
    <div className="sidebar" data-color={props.bgColor} data-active-color={props.activeColor}>
      <div className="logo d-none d-sm-none d-md-block d-lg-block">
        <NavLink to="/chain" className="simple-text logo-normal">
          <img src={easyservedLogo} className="headerLogo" alt="" />
          {'\u00A0'}
          Netwerk paneel
        </NavLink>
      </div>
      <div className="sidebar-wrapper mt-5" ref={sidebar}>
        <Nav>
          <li onClick={closeSidebar}>
            <NavLink
              to="/chain/pickup_points"
              className="nav-link"
              activeClassName="active"
              id="pickup_points_colorchange"
            >
              <img src={line} alt="" width="40px" height="2px" />
              {'\u00A0'}
              {'\u00A0'}
              <span className="font-weight-bold">Pickuplocatie</span>
            </NavLink>
          </li>

          <li onClick={closeSidebar}>
            <NavLink
              to="/chain/orders_overview"
              className="nav-link"
              activeClassName="active"
              id="chain_orders_overview_colorchange"
            >
              <img src={line} alt="" width="40px" height="2px" />
              {'\u00A0'}
              {'\u00A0'}
              <span className="font-weight-bold">Bestellingen Overzicht</span>
            </NavLink>
          </li>

          <li onClick={closeSidebar}>
            <NavLink
              to="/chain/changePassword"
              className="nav-link"
              activeClassName="active"
              id="changePassword"
            >
              <img src={line} alt="" width="40" height="2px" />
              {'\u00A0'}
              {'\u00A0'}
              <span className="font-weight-bold">Pas wachtwoord aan</span>
            </NavLink>
          </li>

          <li onClick={logout}>
            <NavLink
              to="/auth/login"
              className="nav-link"
              activeClassName="active"
              id="logout_colorchange"
            >
              <img src={line} alt="" width="40" height="2px" />
              {'\u00A0'}
              {'\u00A0'}
              <span className="font-weight-bold">Logout</span>
            </NavLink>
          </li>
        </Nav>
      </div>
    </div>
  );
};
export default ChainSidebar;
