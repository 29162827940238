import React, { FC, useEffect, useRef, useState } from 'react';
import { Header, OrderModel, Sidebar } from '../../Components';
import PerfectScrollbar from 'perfect-scrollbar';
import { API } from '../../api';
import Switch from 'react-switch';
import { toast } from 'react-toastify';
import Loader from '../../Components/Loader';
import PrinterModal from '../../Components/Printers/PrinterModal';
import StarTab from '../../Components/Printers/StarTab';
import EpsonTab from '../../Components/Printers/EpsonTab';
import { API_BASE_URL } from '../../services/urls';
import EpsonEditModal from '../../Components/Printers/EpsonEditModal';
import StarEditModal from '../../Components/Printers/StarEditModal';
import { useDispatch, useSelector } from 'react-redux';

const { getPrintersList, getPrinterUrl, partialUpdatePrinter, removePrinter, getCategories } =
  API.restaurantApi;

let ps: any;

const Printers = () => {
  const [printersList, setPrintersList] = useState<any[]>([]);
  const [modal, setModal] = useState(false);
  const [epsonEditModal, setEpsonEditModal] = useState<boolean>(false);
  const [starEditModal, setStarEditModal] = useState<boolean>(false);
  const [currId, setCurrId] = useState(null);
  const [currPrinter, setCurrPrinter] = useState(null);
  const [activStatuses, setActiveStatuses] = useState<any[]>([]);
  const [printer_url, setPrinterUrl] = useState<string>('');
  const [isStarTab, setIsStarTab] = useState<boolean>(true);
  const [categories, setCategories] = useState<any[]>([]);
  const mainPanel = useRef<any>();
  const id = localStorage.getItem('rest_id') as string;
  const dispatch = useDispatch();

  const getRestPrintersList = async (restid: string) => {
    await getPrintersList(restid)
      .then((res: any) => {
        setPrintersList(res);
        // @ts-ignore
        let newActivStatuses = [];
        res.map((item: any) => {
          newActivStatuses.push({
            id: item.id,
            activity_status: item.activity_status === 'online',
          });
        });
        // @ts-ignore
        setActiveStatuses(newActivStatuses);
      })
      .catch((err: any) => {
        // Add Toast
      });
  };

  const getRestaurantCategories = async (restid: string) => {
    await getCategories(restid)
      .then((res: any) => {
        setCategories(res.data);
      })
      .catch((err: any) => {
        console.log('err', err);
      });
  };

  const getUrlForPrinter = async (restid: string) => {
    await getPrinterUrl(restid)
      .then((res: any) => {
        setPrinterUrl(res.printer_url);
      })
      .catch((err: any) => console.log('Error', err));
  };

  useEffect(() => {
    getRestPrintersList(id);
    getUrlForPrinter(id);
    getRestaurantCategories(id);
  }, []);

  useEffect(() => {
    document.title = 'Printers';
    // @ts-ignore
    // document.getElementById('printers_colorchange').style.color = 'darkred';
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(mainPanel.current);
      document.body.classList.toggle('perfect-scrollbar-on');
    }
    return () => {
      if (navigator.platform.indexOf('Win') > -1) {
        ps.destroy();
        document.body.classList.toggle('perfect-scrollbar-on');
      }
    };
  }, [modal]);

  const toggle = (id: any, printer: any) => {
    if (id?.preventDefault) {
      id.preventDefault();
    }

    setModal(!modal);
    setCurrId(id);
    setCurrPrinter(printer);
  };

  const toggleEpsonEditModal = (id: any, printer: any) => {
    if (id?.preventDefault) {
      id.preventDefault();
    }

    setEpsonEditModal(!epsonEditModal);
    setCurrId(id);
    setCurrPrinter(printer);
  };

  const toggleStarEditModal = (id: any, printer: any) => {
    if (id?.preventDefault) {
      id.preventDefault();
    }

    setStarEditModal(!starEditModal);
    setCurrId(id);
    setCurrPrinter(printer);
  };

  const deleteSelectedPrinter = async (id: any) => {
    const cont_id = 'RemovePrinter';
    await removePrinter(id)
      .then((res: any) => {
        toast.success('Printer Removed!', { containerId: cont_id });
      })
      .catch((err: any) => {
        toast.success('Printer Not Removed!', { containerId: cont_id });
      });
  };

  const deleteprinter = async (id: any) => {
    const restid = localStorage.getItem('rest_id') as string;
    deleteSelectedPrinter(id);
  };

  const getActivityStatus = (id: any) => {
    if (activStatuses && activStatuses.length > 0) {
      return activStatuses.find((item: any) => item.id === id).activity_status;
    }
    return false;
  };

  const partialUpdateSelectedPrinter = async (printer_id: any, data: any) => {
    await partialUpdatePrinter(printer_id, data)
      .then((res) => {
        toast.success('Printer updated!', { containerId: 'B' });
        getRestPrintersList(id);
      })
      .catch((err) => {
        toast.error('Error updating printer!', { containerId: 'B' });
        getRestPrintersList(id);
      });
  };

  const handleActivityStatus = (id: string) => {
    const val = getActivityStatus(id);
    let newActivStatuses = activStatuses.map((item: any) => {
      if (item.id === id) {
        return {
          ...item,
          activity_status: !val,
        };
      }
      return item;
    });
    setActiveStatuses(newActivStatuses);
    const formData = new FormData();
    formData.append('activity_status', val ? 'offline' : 'online');
    partialUpdateSelectedPrinter(id, formData);
  };

  const getStarPrintersList = () => {
    return printersList.filter((item: any) => item.printer_type !== 'Epson');
  };
  const getEpsonPrintersList = () => {
    return printersList.filter((item: any) => item.printer_type === 'Epson');
  };

  return (
    <>
      <Sidebar />
      <div id="border-top-radius" className="container-fluid main-panel" ref={mainPanel}>
        <Header />
        <div className="row mt-5 pt-5">
          <div className="col col-sm-6">
            <button
              onClick={(e) => setIsStarTab(true)}
              className={`btn ${isStarTab ? 'btn-primary' : 'btn-outline-primary'}`}
            >
              Star printers
            </button>
          </div>
          <div className="col col-sm-6 text-right">
            <button
              onClick={(e) => setIsStarTab(false)}
              className={`btn ${isStarTab ? 'btn-outline-primary' : 'btn-primary'}`}
            >
              Epson printers
            </button>
          </div>
          {isStarTab ? (
            <StarTab
              printer_url={printer_url}
              printersList={getStarPrintersList()}
              handleActivityStatus={handleActivityStatus}
              getActivityStatus={getActivityStatus}
              toggle={toggle}
              toggleStarEditModal={toggleStarEditModal}
              deleteprinter={deleteprinter}
            />
          ) : (
            <EpsonTab
              printer_url={`/restaurant/epson/printer/${id}`}
              printersList={getEpsonPrintersList()}
              handleActivityStatus={handleActivityStatus}
              getActivityStatus={getActivityStatus}
              toggle={toggle}
              toggleEpsonEditModal={toggleEpsonEditModal}
              deleteprinter={deleteprinter}
            />
          )}
        </div>
      </div>
      <PrinterModal
        printer={currPrinter}
        modal={modal}
        toggle={toggle}
        printerId={currId}
        categories={categories}
      />
      <StarEditModal
        printer={currPrinter}
        modal={starEditModal}
        toggle={toggleStarEditModal}
        updatePrinter={partialUpdateSelectedPrinter}
        printerId={currId}
        categories={categories}
      />
      <EpsonEditModal
        printer={currPrinter}
        modal={epsonEditModal}
        toggle={toggleEpsonEditModal}
        updatePrinter={partialUpdateSelectedPrinter}
        printerId={currId}
        categories={categories}
      />
    </>
  );
};

export default Printers;
