import PerfectScrollbar from 'perfect-scrollbar';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Switch from 'react-switch';

import { getRestaurantData } from '../../store/actions';
import { API } from '../../api';
import { API_BASE_URL, MAIN_APP_URL } from '../../services/urls';
import {
  Button,
  DownloadQR,
  Header,
  InputField,
  Sidebar,
  WorkingHoursModal,
} from '../../Components';
import './style.css';
import DeleteModalComponent from '../../Components/DeleteModalComponent';
import VisitUrl from '../../Components/VisitUrl/VisitUrl';

let ps: any;

const WorkingHoursView: FC = () => {
  const [modal, setModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isSABrestaurantName, setSABrestaurantName] = useState(false);
  const [qrmodal, setQrModal] = useState(false);
  const [restaurantData, setRestaurantData] = useState<any>([]);
  const [workinghoursData, setworkinghoursData] = useState([]);
  const [workinghourId, setworkinghourId] = useState(0);
  const [workinghourobj, setworkinghourobj] = useState<any>(0);
  const { id } = useParams<any>();
  const restid = localStorage.getItem('rest_id') as string;
  const dutchWeekDays = new Map([
    ['Monday', 'Maandag'],
    ['Tuesday', 'Dinsdag'],
    ['Wednesday', 'Woensdag'],
    ['Thursday', 'Donderdag'],
    ['Friday', 'Vrijdag'],
    ['Saturday', 'Zaterdag'],
    ['Sunday', 'Zondag'],
  ]);
  const mainPanel = useRef<any>();

  const restaurant = useSelector(({ restaurantData }: StoreRoot) => restaurantData);

  const dispatch = useDispatch();

  const getWorkingHours = () => {
    API.restaurantApi
      .getRestaurantWorkingHours(restid)
      .then((res) => {
        setworkinghoursData(res.data);
      })
      .catch((err) => console.log('Error is', err.message));
  };

  useEffect(() => {
    setRestaurantData(restaurant);
  }, [restaurant]);

  useEffect(() => {
    // @ts-ignore
    if (restaurantData.RestaurantName === 'Eetfestijn SAB Denderhoutem') {
      setSABrestaurantName(true);
    }
  }, [restaurant]);

  useEffect(() => {
    // @ts-ignore
    document.getElementById('dashboard_colorchange').style.color = 'darkred';
    dispatch(getRestaurantData(restid));
    getWorkingHours();

    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(mainPanel.current);
      document.body.classList.toggle('perfect-scrollbar-on');
    }
    return () => {
      if (navigator.platform.indexOf('Win') > -1) {
        ps.destroy();
        document.body.classList.toggle('perfect-scrollbar-on');
      }
    };
  }, [qrmodal, modal, id, restid]);

  const toggle = (id: any, workinghour: any) => {
    setModal(!modal);
    setworkinghourId(id);
    setworkinghourobj(workinghour);
    getWorkingHours();
  };
  const toggleDeleteModal = (id: any, articleopt: any) => {
    setDeleteModal(!deleteModal);
    setworkinghourId(id);
    setworkinghourobj(articleopt);
  };

  const deleteWorkinghour = async (id: any) => {
    await API.restaurantApi.deleteRestaurantWorkingHours(id).then((res) => {
      toast.success('Deleted Successfully!', { containerId: 'B' });
      getWorkingHours();
    });
  };

  const changerestaurantdata = (e: any) => {
    setRestaurantData({
      ...restaurantData,
      [e.target.name]: [e.target.value],
    });
  };

  const handleChangeStatus = () => {
    let valueA;
    if (restaurantData.Availability === 'OFFLINE') {
      valueA = 'ONLINE';
    } else {
      valueA = 'OFFLINE';
    }
    setRestaurantData({
      ...restaurantData,
      Availability: valueA,
    });
  };

  const handleChangeDelivery = (e: any) => {
    let valueA;
    if (restaurantData.pickup_option === false) {
      valueA = false;
    } else {
      valueA = true;
    }
    setRestaurantData({
      ...restaurantData,
      delivery_option: e,
    });
  };

  const handleChangePickup = (e: any) => {
    let valueA;

    if (restaurantData.pickup_option === false) {
      valueA = false;
    } else {
      valueA = true;
    }
    setRestaurantData({
      ...restaurantData,
      pickup_option: e,
    });
  };

  const SubmitRestaurantdata = async (e: any) => {
    e.preventDefault();
    const payment_method_type = e.target.elements.payment_method_type.value || 'PAYNL';
    const paynl_service_id = e.target.elements.paynl_service_id?.value || '';
    const paynl_token_code = e.target.elements.paynl_token_code?.value || '';
    const apikey = e.target.elements.apikey.value;
    const slug = e.target.elements.slug.value;

    let meta_titles = [];
    if (e.target.elements.meta_titles.value.replaceAll(/,/g, ' ').split(' ').pop() === '') {
      meta_titles = e.target.elements.meta_titles.value
        .replaceAll(/,/g, ' ')
        .split(' ')
        .slice(0, -1);
    } else {
      meta_titles = e.target.elements.meta_titles.value.replaceAll(/,/g, ' ').split(' ');
    }

    const formData = new FormData();

    formData.append('apikey', apikey);
    formData.append('payment_method_type', payment_method_type);
    if (payment_method_type === 'PAYNL') {
      formData.append('paynl_service_id', paynl_service_id);
      formData.append('paynl_token_code', paynl_token_code);
    } else {
      formData.append('paynl_service_id', '');
      formData.append('paynl_token_code', '');
    }
    formData.append('slug', slug);
    formData.append('meta_titles', JSON.stringify(meta_titles));

    await API.restaurantApi
      .updateRestaurantData(formData, restid)
      .then(() => {
        toast.success('Updated Successfully!', { containerId: 'B' });
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch(() => {
        window.location.reload();
      });
  };

  const handleChangePaymentMethod = (e: any) => {
    // Change method and clear fields
    setRestaurantData({
      ...restaurantData,
      payment_method_type: e.target.value,
      apikey: '',
      paynl_service_id: '',
      paynl_token_code: '',
    });
  };

  return (
    <>
      <Sidebar />
      <div id="border-top-radius" className="container-fluid main-panel" ref={mainPanel}>
        <Header />
        <VisitUrl />
        <div className="row mr-md-5 mt-5 pt-5">
          <div className="col-xl-10 offset-xl-1 col-lg-9 offset-lg-3 col-md-8 offset-md-4 col-sm-12 col-12 offsetMd5 text-center">
            <span className="h4 mb-3">Bewerk openingsuren</span>
          </div>
          <div className="col-xl-10 offset-xl-1 col-lg-8 offset-lg-4 col-md-8 offset-md-4 col-sm-12 col-12 offsetMd5">
            <div className="p-3">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <button
                type="button"
                onClick={() => toggle('new', 'new')}
                className="btn btn-success rounded-0 shadow-none"
              >
                + Voeg tijdslot toe
              </button>
              <div className="row border border-secondary mt-2">
                <div className="col-sm-12 text-center mt-2">
                  <h4>Openingsuren</h4>
                </div>
                <table className="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th className="control-label font-weight-bold">Dag</th>
                      <th className="control-label font-weight-bold">Vanaf</th>
                      <th className="control-label font-weight-bold">Tot</th>
                      <th className="control-label font-weight-bold">
                        Levering / {isSABrestaurantName ? 'Eet op locatie' : 'Afhaal'}{' '}
                      </th>
                      <th className="control-label font-weight-bold">Actie</th>
                    </tr>
                  </thead>
                  <tbody>
                    {workinghoursData &&
                      workinghoursData.map((workinghour: any, index) => {
                        return (
                          <tr key={index} className="font-weight-bold">
                            <td>{dutchWeekDays.get(workinghour.weekday)}</td>
                            <td>{workinghour.from_hour}</td>
                            <td>{workinghour.to_hour}</td>
                            <td>
                              <div className="levering-afhal d-flex flex-column">
                                <div>
                                  Levering: <b>{workinghour.delivery_option ? 'ON' : 'OFF'}</b>
                                </div>

                                <div>
                                  {' '}
                                  {isSABrestaurantName ? 'Eet op locatie:' : 'Afhaal'}{' '}
                                  <b>{workinghour.pickup_option ? 'ON' : 'OFF'}</b>
                                </div>
                              </div>
                            </td>

                            <td>
                              <i
                                className="fa fa-pencil cursr"
                                aria-hidden="true"
                                id={workinghour.id}
                                onClick={() => toggle(workinghour.id, workinghour)}
                              />
                              <i
                                className="fa fa-trash pl-2 cursr"
                                aria-hidden="true"
                                id={workinghour.id}
                                // onClick={() => deleteWorkinghour(workinghour.id)}
                                onClick={() => toggleDeleteModal(workinghour.id, workinghourobj)}
                              />
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <WorkingHoursModal
        workinghourobj={workinghourobj}
        modal={modal}
        toggle={toggle}
        workinghourid={workinghourId}
        isSAB={isSABrestaurantName}
      />
      <DownloadQR />
      <DeleteModalComponent
        articleopt={workinghourobj}
        isOpen={deleteModal}
        toggle={toggleDeleteModal}
        articleoptId={workinghourId}
        deleteFunction={deleteWorkinghour}
      />
    </>
  );
};
export default WorkingHoursView;
