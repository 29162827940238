export interface Ctg {
    id: number;
    ctg_name: string;
    articles: any[];
  }

export const getOrderCtgs = (orderData: any): Ctg[] => {
  // Get Unique Categories For Current Order
  const ctgs: Ctg[] = [];
  orderData.forEach((item: any) => {
    const currCtg: Ctg = {
      id: item.article.category.id,
      ctg_name: item.article.category.name,
      articles: [],
    };
    if (!ctgs.find((i) => i.id === currCtg.id)) {
      ctgs.push(currCtg);
    }
  });

  return ctgs;
};
