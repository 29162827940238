import React, { FC, useEffect } from 'react';
import { Navbar, NavbarBrand, Card, CardBody, CardTitle, Button } from 'reactstrap';
import MainViewCard from '../../Components/MainViewCard';
import { Link } from 'react-router-dom';
import Footer from '../../Components/Footer';

import './style.css';

let ps: any;

const MainView: FC = () => {
  //   useEffect(() => {
  //     document.body.style.backgroundColor = 'rgb(1, 1, 122)';
  //   }, []);

  const rest_id = localStorage.getItem('rest_id');

  return (
    <div className="main-page min-vh-100">
      <nav className="navbar navbar-dark h-25 mb-0" style={{ backgroundColor: '#222a60' }}>
        <div className="container">
          <a className="navbar-brand" href="#">
            <img
              src="https://res.cloudinary.com/updivision/image/upload/v1/media/Storage/Screenshot_2023-12-08_at_14.45.19_cq0kgc"
              alt=""
              width="50%"
              height="50%"
            />
          </a>
        </div>
      </nav>
      {/* CARDS HERE */}
      <div className="d-flex flex-wrap h-75 m-4 justify-content-center">
        {/* MAIN CARD */}
        <div className="big-card-container h-50 p-2 m-3">
          <div className="card text-center p-3">
            <Link to={'/admin/generalView'}>
              <img
                src="https://res.cloudinary.com/updivision/image/upload/v1/media/Storage/Bewerk_restaurant_hpzeka"
                className="card-img-top"
                alt="..."
              />
              <div className="card-body">
                <h2 className="card-title font-weight-bold" style={{ color: 'rgb(1, 1, 122)' }}>
                  Bewerk jouw webshop
                </h2>
                <a href="/admin/dashboard">
                  <img
                    alt="Arrow"
                    src="https://res.cloudinary.com/updivision/image/upload/v1/media/Storage/ARROW_huyu4b"
                    width="25%"
                    height="auto"
                  />
                </a>
              </div>
            </Link>
          </div>
        </div>
        {/* MAIN CARD ENDS HERE*/}
        <div className="cards-container d-flex flex-wrap w-75 p-2">
          <MainViewCard
            link={'/admin/rightsmanagement'}
            iconUrl={
              'https://res.cloudinary.com/updivision/image/upload/v1/media/Storage/Gebruikers_gehjtc'
            }
            title={'Gebruikers'}
          ></MainViewCard>
          <MainViewCard
            link={'/admin/editArticle'}
            iconUrl={
              'https://res.cloudinary.com/updivision/image/upload/v1/media/Storage/Producten_awmjks'
            }
            title={'Producten'}
          ></MainViewCard>
          <MainViewCard
            link={'/admin/Orders'}
            iconUrl={
              'https://res.cloudinary.com/updivision/image/upload/v1/media/Storage/Bestellingen_ssse1c'
            }
            title={'Bestellingen'}
          ></MainViewCard>
          <MainViewCard
            link={'/admin/promocodes'}
            iconUrl={
              'https://res.cloudinary.com/updivision/image/upload/v1/media/Storage/Promocodes_xbegwe'
            }
            title={'PromoCodes'}
          ></MainViewCard>
          <MainViewCard
            link={`/admin/qrdonwload/${rest_id}`}
            iconUrl={
              'https://res.cloudinary.com/updivision/image/upload/v1/media/Storage/QR_codes_egzqsf'
            }
            title={'QR Codes'}
          ></MainViewCard>
          <MainViewCard
            link={'/admin/printers'}
            iconUrl={
              'https://res.cloudinary.com/updivision/image/upload/v1/media/Storage/Instellingen_hw5j8u'
            }
            title={'Instellingen'}
          ></MainViewCard>
          <MainViewCard
            link={'/admin/gallery'}
            iconUrl={
              'https://res.cloudinary.com/updivision/image/upload/v1/media/Storage/Afbeeldingen_vdsyws'
            }
            title={'Afbeeldingen'}
          ></MainViewCard>
          <MainViewCard
            link={'/admin/forbidden'}
            iconUrl={
              'https://res.cloudinary.com/updivision/image/upload/v1/media/Storage/Uitloggen_b0b5uc'
            }
            title={'Uitloggen'}
          ></MainViewCard>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default MainView;
