import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { toast } from 'react-toastify';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { Button, InputField } from '../index';
import { API } from '../../api';
import moment from 'moment';

interface Props {
  printer: {
    name: string | null;
    epson_id: string | null;
    epson_version: number | null;
    activity_status: string;
    added: string;
    current_order: string | null;
    encodings: string | null;
    id: number | string | null;
    mac_address: string | null;
    poll_interval: number | string | null;
    restaurant: number | string | null;
    unique_id: string;
    printer_type: string | null;
    categories: any[];
  };
  modal: boolean;
  printerId: string;
  categories: any[];
  toggle: () => {};
}

const PrinterModal: FC<any> = (props: Props) => {
  const restId = localStorage.getItem('rest_id') as string;

  const asyncDispatch: ThunkDispatch<StoreRoot, any, AnyAction> = useDispatch();

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    // Call to Printer Update API Here
  };

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    props.toggle && props.toggle();
  };

  const renderCategories = () => {
    if (!props.printer?.categories && !props.categories) return '-';
    const full_categories = props.categories.filter((item: any) =>
      props.printer.categories.includes(item.id)
    );
    return full_categories.map((c: any) => c.value).join(', ');
  };

  const { printer } = props;

  return printer ? (
    <Modal isOpen={props.modal} toggle={props.toggle}>
      <ModalHeader toggle={props.toggle}>Printer #{printer.id}</ModalHeader>
      <form onSubmit={(e) => handleSubmit(e)}>
        <ModalBody>
          <div className="row">
            <div className="col-3 text-left">
              <span>Name</span>
            </div>
            <div className="col-9 text-right">
              <span>{printer.name}</span>
            </div>
          </div>
          <div className="row">
            <div className="col-3 text-left">
              <span>Categories</span>
            </div>
            <div className="col-9 text-right">
              <span>{renderCategories()}</span>
            </div>
          </div>
          <div className="row">
            <div className="col-3 text-left">
              <span>ID</span>
            </div>
            <div className="col-9 text-right">
              <span>{printer.id}</span>
            </div>
          </div>
          <div className="row">
            <div className="col-3 text-left">
              <span>Unique ID</span>
            </div>
            <div className="col-9 text-right">
              <span>{printer.unique_id}</span>
            </div>
          </div>
          <div className="row">
            <div className="col-3 text-left">
              <span>Status</span>
            </div>
            <div className="col-9 text-right">
              <span>{printer.activity_status}</span>
            </div>
          </div>
          <div className="row">
            <div className="col-3 text-left">
              <span>Encodings</span>
            </div>
            <div className="col-9 text-right">
              <span>{printer.encodings}</span>
            </div>
          </div>
          <div className="row">
            <div className="col-3 text-left">
              <span>Order ID</span>
            </div>
            <div className="col-9 text-right">
              <span>{printer.current_order || 'Not printing'}</span>
            </div>
          </div>
          <div className="row">
            <div className="col-3 text-left">
              <span>Mac Address</span>
            </div>
            <div className="col-9 text-right">
              <span>{printer.mac_address}</span>
            </div>
          </div>
          <div className="row">
            <div className="col-3 text-left">
              <span>Poll Interval</span>
            </div>
            <div className="col-9 text-right">
              <span>{printer.poll_interval} seconds</span>
            </div>
          </div>
          <div className="row">
            <div className="col-3 text-left">
              <span>Printer Type</span>
            </div>
            <div className="col-9 text-right">
              <span>{printer.printer_type}</span>
            </div>
          </div>
          <div className="row">
            <div className="col-3 text-left">
              <span>Epson ID</span>
            </div>
            <div className="col-9 text-right">
              <span>{printer.epson_id}</span>
            </div>
          </div>
          <div className="row">
            <div className="col-3 text-left">
              <span>Epson version</span>
            </div>
            <div className="col-9 text-right">
              <span>{printer.epson_version}</span>
            </div>
          </div>
          <div className="row">
            <div className="col-3 text-left">
              <span>Added</span>
            </div>
            <div className="col-9 text-right">
              <span>{moment(printer.added).format('YYYY/MM/DD HH:mm')}</span>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button name="Close" btnLongWidth={false} onClick={handleCancel} type="button" />
        </ModalFooter>
      </form>
    </Modal>
  ) : null;
};
export default PrinterModal;
