import React, { FC, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
  Form,
  FormGroup,
  Label,
  Input,
} from 'reactstrap'
import { Button } from './index';
import {
  addUsetTagToCustomer, getCustomerMainInfo, manageUserTagsItems, removeUsetTagToCustomer,
} from '../api/restaurantApi.service';
import { API } from '../api';

interface IUserTagEditModalProps {
  isOpen: boolean;
  toggle: () => void;
  customerId: number | null;
  restId: string;
  userTagId: number | null;
}

const UserTagEditModal: FC<IUserTagEditModalProps> = (props) => {
  const {
    isOpen, toggle, customerId, restId, userTagId,
  } = props
  const [isLoading, setIsLoading] = useState(true)
  const [customer, setCustomer] = useState<TCustomerMainInfo | null>(null)
  const [userTagsItems, setUserTagsItems] = useState<UserTagItem[]>([])
  const [inputUserTag, setInputUserTag] = useState('')

  useEffect(() => {
    const getCustomerPromise = getCustomerMainInfo;
    const getUserTagsItems = manageUserTagsItems
    const { getUserTagByUserIdRestId, getUserTagItemById } = API.restaurantApi;
    let mounted = true;
    if (customerId) {
      getCustomerPromise(customerId)
        .then((response: any) => {
          if (!mounted) return
          if (!response || !response.success) {
            toast.error(response.message, { containerId: 'B' });
            setIsLoading(false)
            return
          }
          mounted && setCustomer(response.data)
          return getUserTagsItems(+restId, 'GET')
        })
        .then((response: any) => {
          if (!mounted) return
          if (!response || !response.length) {
            toast.error('There are no created Gebruikertags', { containerId: 'B' });
            toggle();
            return
          }
          mounted && setUserTagsItems(response)
          mounted && setInputUserTag(response[0].id)
          return getUserTagByUserIdRestId(customerId, +restId)
            .catch((error) => {
              if (error.request.status !== 404) {
                throw error
              }
            })
        })
        .then((responseUserTag) => {
          if (!responseUserTag || !mounted) {
            setIsLoading(false)
            return
          }
          return getUserTagItemById(responseUserTag.user_tag_item)
        })
        .then((responseUserTagItem) => {
          if (!mounted) return
          if (!responseUserTagItem) {
            return
          }
          setInputUserTag(responseUserTagItem.id.toString())
          setIsLoading(false)
        })
    }
    return () => { mounted = false }
  }, [customerId])

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    if (!customer) {
      toast.error('Missing customer ID', { containerId: 'B' });
      toggle()
      return
    }

    addUsetTagToCustomer(customer.id, +inputUserTag)
      .then((response) => {
        toast.success('UserTag assigned successfully!', { containerId: 'B' });
        toggle()
      })
  }
  const handleDelete = (userTagId: number) => (e: any) => {
    userTagId && removeUsetTagToCustomer(userTagId).then(
      () => {
        toast.success('UserTag removed successfully!', { containerId: 'B' });
        toggle()
      },
    )
  }
  const handleChangeInputUserTag = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setInputUserTag(e.target.value)
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <Form onSubmit={handleSubmit}>
        <ModalHeader toggle={toggle}>
          Klant bewerken
        </ModalHeader>
        {isLoading ? (
          <Spinner
            className="m-5"
            color="primary"
          >
            Loading...
          </Spinner>
        ) : (
          <ModalBody>
            <FormGroup>
              <Label>Selecteer gebruikerstag</Label>
              <Input
                type="select"
                value={inputUserTag}
                onChange={handleChangeInputUserTag}
              >
                {userTagsItems.map((item) => (
                  <option key={item.id} value={item.id}>{item.name}</option>
                ))}
              </Input>
            </FormGroup>
          </ModalBody>

        )}
        <ModalFooter>
          {userTagId
            ? <Button name="Delete" data-id={userTagId} btnLongWidth={false} onClick={handleDelete(userTagId)} type="button" />
            : null}
          <Button name="Cancel" btnLongWidth={false} onClick={toggle} type="button" />
          <Button
            name="Save"
            type="submit"
            dangerButton={true}
            btnLongWidth={false}
          />

        </ModalFooter>

      </Form>
    </Modal>
  )
}

export default UserTagEditModal
