const checkOrderContainsDeliveryTypes = (deliveryTypes: any, order: any) => {
  // Because order_type has no choice such as 'Qr' need to determine
  // true order_type manually
  let determinedOrderType = 'Pickup';
  if (order.tableid !== -1) {
    determinedOrderType = 'Qr';
  } else if (order.order_type === 'Delivery') {
    determinedOrderType = 'Delivery';
  } else {
    determinedOrderType = 'Pickup';
  }

  const typeIdx = deliveryTypes.findIndex((dt: any) => dt.value === determinedOrderType);

  return typeIdx !== -1;
};

export default checkOrderContainsDeliveryTypes;
