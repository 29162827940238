import React, { useEffect, useState, FC } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Button } from './index';

import { toast } from 'react-toastify';
import { API } from '../api';
import { Categories } from '../views/Orders/Categories';
import { getOrderCtgs } from '../views/Orders/getOrderCtgs';
import { TotalingThings } from '../views/Orders/TotalingThings';

interface IProps {
  orderId: number;
}

const OrderDetailsModal: FC<any> = (props) => {
  const id = localStorage.getItem('rest_id') as string;

  // States
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [theOrder, setTheOrder] = useState<any>();
  const [orderData, setOrderData] = useState<any>('');
  const [orderDetails, setOrderDetails] = useState<any>(null);
  const [categories, setCategories] = useState<any>();

  useEffect(() => {
    // console.log('This is orderData', orderData);
    // console.log('This is orderDetails', orderDetails);

    let mounted = true;
    setIsLoading(true);

    if (typeof props.orderId === 'number' && props.orderId != 0) {
      API.restaurantApi
        .getOrder(id, props.orderId.toString())
        .then((responseOrder) => {
          if (!mounted) return;
          setTheOrder(responseOrder.data.orders[0]);
          setOrderData(responseOrder.data.Articles);
          setCategories(getOrderCtgs(responseOrder.data.Articles));
          return API.restaurantApi.ordersDetail(responseOrder.data.orders[0].order_number);
        })
        .then((responseOrdersDetail) => {
          setOrderDetails(responseOrdersDetail);
        })
        .catch(() => {
          toast.error('Cant get order data', { containerId: 'B' });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
    return () => {
      mounted = false;
    };
  }, [props.orderId]);

  return (
    <Modal orderid={props.orderId} toggle={props.toggle} isOpen={props.isOpen}>
      <ModalHeader toggle={props.toggle}>Order details:</ModalHeader>
      <ModalBody>
        {isLoading ? (
          'Loading...'
        ) : (
          <>
            {/* <h1>Order Details for Order Number: {orderData.orderId}</h1> */}
            <div>
              <div>
                <Categories
                  orderData={orderData}
                  orderDetails={orderDetails}
                  categories={categories}
                />
              </div>
              <div>
                {theOrder && (
                  <TotalingThings
                    deliveryCost={theOrder.delivery_cost}
                    discountValue={theOrder.discount_value}
                    serviceCost={theOrder.service_cost}
                    discountUserTagValue={theOrder.discount_userTag_value}
                    totalAmount={theOrder.total_amount}
                  />
                )}
              </div>
            </div>
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <Button name="Cancel" btnLongWidth={false} type="button" onClick={props.toggle}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};
export default OrderDetailsModal;
