const urls_map = [
  {
    url_value: 'mainview',
    url_label: 'Dashboard',
  },
  // Bewerk webshop
  {
    url_value: 'generalview',
    url_label: 'Bewerk webshop',
  },
  {
    url_value: 'orderconfirmation',
    url_label: 'Bestelbevestigin',
  },
  {
    url_value: 'workinghours',
    url_label: 'Openingsuren',
  },
  // Gebruikers
  {
    url_value: 'rightsmanagement',
    url_label: 'Gebruikersbeheer',
  },
  {
    url_value: 'user_tags',
    url_label: 'Gebruiker tags',
  },
  {
    url_value: 'changepassword',
    url_label: 'Pas wachtwoord aan',
  },
  // Producten
  {
    url_value: 'foodcategory',
    url_label: 'Categorieën',
  },
  {
    url_value: 'editarticle',
    url_label: 'Beheer producten',
  },
  {
    url_value: 'articleoptionsname',
    url_label: 'Productoptie groepen',
  },
  {
    url_value: 'articleoptions',
    url_label: 'Beheer productopties',
  },
  {
    url_value: 'articleoption',
    url_label: 'Artikel optie',
  },
  {
    url_value: 'tags',
    url_label: 'Tags',
  },
  // Bestellingen
  {
    url_value: 'orders',
    url_label: 'Bestellingen',
  },
  {
    url_value: 'orders_overview',
    url_label: 'Overzicht bestellingen',
  },
  // Promocodes
  {
    url_value: 'promocodes',
    url_label: 'Promocodes',
  },
  // QR codes
  {
    url_value: 'qrdonwload',
    url_label: 'Qr codes',
  },
  // Instellingen
  {
    url_value: 'csvupload',
    url_label: 'CSV Upload',
  },
  {
    url_value: 'printers',
    url_label: 'Printerinstellingen',
  },
  // Gallery
  {
    url_value: 'gallery',
    url_label: 'Afbeeldingen',
  },
];

const mapUrlToLabel = (url: string) => {
  const url_label = urls_map.filter((item: any) => item.url_value === url.toLowerCase())[0]
    ?.url_label;
  if (url_label) return url_label;
  return url;
};

export default mapUrlToLabel;
