import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { toast } from 'react-toastify';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { Button, InputField } from '../index';
import { API } from '../../api';
import { getCurrentPictureStorage, getCommonPictureStorage } from '../../store/actions';
import PicturesTable from './PicturesTable';
import './art_picture_modal.css';
import Select from 'react-select';

const EditArticlePictureModal: FC<any> = (props) => {
  const id = localStorage.getItem('rest_id') as string;
  const [storageSelectOptions, setStorageSelectOptions] = useState<any[]>([]);
  const [selectedStorage, setSelectedStorage] = useState<any>(null);
  const current_pict_storage: any = useSelector(
    ({ currentPictureStorage }: StoreRoot) => currentPictureStorage
  );
  const common_pict_storages = useSelector(
    ({ commonPictureStorages }: StoreRoot) => commonPictureStorages
  );

  const dispatch = useDispatch();
  const asyncDispatch: ThunkDispatch<StoreRoot, any, AnyAction> = useDispatch();

  useEffect(() => {
    let new_select_options = [];
    const all_opt = {
      id: 0,
      value: '__all__',
      label: 'All storages',
    };
    new_select_options.push(all_opt);
    const curr_storage_opt = {
      id: current_pict_storage.id,
      value: `${current_pict_storage.name} #${current_pict_storage.id}`,
      label: 'Eigen afbeeldingen',
    };
    setSelectedStorage(curr_storage_opt);
    new_select_options.push(curr_storage_opt);
    const other_storages_opt = common_pict_storages.map((item: any) => ({
      id: item.id,
      value: `${item.name} #${item.id}`,
      label: `Algemene afbeeldingen - ${item.name}`,
    }));
    new_select_options = [...new_select_options, ...other_storages_opt];

    setStorageSelectOptions(new_select_options);
  }, [current_pict_storage, common_pict_storages]);

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    const curr_storage_opt = {
      id: current_pict_storage.id,
      value: `${current_pict_storage.name} #${current_pict_storage.id}`,
      label: current_pict_storage.name,
    };
    setSelectedStorage(curr_storage_opt);
    props.toggle(props.article);
  };

  const handleSubmit = (file: any) => {
    if (!file) {
      toast.error('Select file and name it first', { containerId: 'B' });
      return;
    }

    let formData = new FormData();
    if (file) {
      formData.append('picture', file);
      formData.append('name', file.name);
    }
    API.restaurantApi
      .uploadPicture(props.article.id, formData)
      .then((res) => {
        toast.success('Picture uploaded!', { containerId: 'B' });
        dispatch(getCurrentPictureStorage(id));
        dispatch(getCommonPictureStorage(id));
        const curr_storage_opt = {
          id: current_pict_storage.id,
          value: `${current_pict_storage.name} #${current_pict_storage.id}`,
          label: current_pict_storage.name,
        };
        setSelectedStorage(curr_storage_opt);
        props.toggle(props.article);
      })
      .catch((err) => {
        toast.error('Error uploading picture', { containerId: 'B' });
      });
  };

  return (
    <Modal
      isOpen={props.modal}
      toggle={props.toggle}
      contentClassName="edit-article-picture-modal"
      modalClassName="edit-article-picture-dialog"
    >
      <ModalHeader toggle={props.toggle}>
        {props.picture === 'new' ? 'Voeg afbeelding toe' : 'Bewerk afbeelding'}
      </ModalHeader>
      <ModalBody>
        <div className="row">
          <div className="col-sm-12">
            <label htmlFor="file_upload" className="btn btn-primary picture_upload_btn">
              <input
                name="image"
                type="file"
                onChange={(e: any) => {
                  handleSubmit(e.target.files[0]);
                }}
                id="file_upload"
                accept="image/*"
              />
              Selecteer bestand
            </label>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-4">
            <Select
              styles={{ menu: (base) => ({ ...base, zIndex: 10 }) }}
              value={selectedStorage}
              onChange={(val: any) => setSelectedStorage(val)}
              options={storageSelectOptions}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12">
            {selectedStorage?.id === current_pict_storage.id || selectedStorage?.id === 0 ? (
              <>
                {selectedStorage?.id === current_pict_storage.id ? null : (
                  <h4 className="mt-4" style={{ textAlign: 'start' }}>
                    Eigen afbeeldingen - {current_pict_storage.name}
                  </h4>
                )}
                <div className="mt-4 mb-4"></div>
                <PicturesTable
                  storage={current_pict_storage}
                  currImg={props.currImg}
                  setCurrImg={props.setCurrImg}
                  enablePreview={false}
                  enableEdit={false}
                  enablePictSelect={true}
                  handlePreview={() => {}}
                  handleEdit={() => {}}
                  handleDelete={() => {}}
                />
              </>
            ) : null}
            {common_pict_storages.map((pict_storage: any) =>
              selectedStorage?.id === pict_storage.id || selectedStorage?.id === 0 ? (
                <React.Fragment key={pict_storage.id}>
                  <h4 className="mt-4 mb-4" style={{ textAlign: 'start' }}>
                    Algemene afbeeldingen - {pict_storage.name}
                  </h4>
                  <PicturesTable
                    storage={pict_storage}
                    currImg={props.currImg}
                    setCurrImg={props.setCurrImg}
                    enablePreview={false}
                    enableEdit={false}
                    enablePictSelect={true}
                    handlePreview={() => {}}
                    handleEdit={() => {}}
                    handleDelete={() => {}}
                  />
                </React.Fragment>
              ) : null
            )}
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button name="Cancel" btnLongWidth={false} onClick={handleCancel} type="button" />
        <Button
          name="Save"
          type="submit"
          dangerButton={true}
          onClick={(e: any) => props.handleSubmit(e, props.article)}
          btnLongWidth={false}
        />
      </ModalFooter>
    </Modal>
  );
};
export default EditArticlePictureModal;
