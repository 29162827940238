import { AxiosPromise } from 'axios';
import api, { CreateServiceUrl } from './api';
import { IUserTag, IUserTagItem, IUserTagType } from './DTO/restaurantDTO';
import { IPermissionRestaurantSite, IRole } from './DTO/role';
import { ITable } from './DTO/table';
import { ITablesLocation, ITablesLocationWithCapacity } from './DTO/tablesLocation';

const u = CreateServiceUrl('restaurant');

const login = (data: { username: string; password: string }) => {
  const formData = new FormData();
  formData.set('username', data.username);
  formData.set('password', data.password);

  return api.post(u`api/login/`, data);
};

const getRestaurant = (id: string) => {
  return api.get(`${u`${id}`}/`);
};

const getRestaurantWorkingHours = (id: string) => {
  const url = `${id}/workinghours`;
  return api.get(u`${url}`);
};

const deleteRestaurantWorkingHours = (id: string) => {
  const url = `workinghour/delete/${id}`;
  return api.get(u`${url}`);
};

const updateRestaurantData = (data: FormData, id: string) => {
  const url = `restaurantdata/update/${id}`;

  return api.post(u`${url}`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
// Partially update restaurant
// /restaurant/restaurantdata/partial_update/86
const partiallyUpdateRestaurantData = (data: FormData, id: string) => {
  const url = `restaurantdata/partial_update/${id}`;
  return api.put(u`${url}`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

const createRestaurantWorkingHours = (
  data: {
    restaurant: string;
    weekday: string;
    to_hour: string;
    from_hour: string;
    pickup_option: string;
    delivery_option: string;
  },
  id: string
) => {
  const url = `workinghour/create/${id}`;
  return api.post(u`${url}`, data);
};

const updateRestaurantWorkingHours = (
  data: {
    restaurant: string;
    weekday: string;
    to_hour: string;
    from_hour: string;
    pickup_option: string;
    delivery_option: string;
  },
  id: string
) => {
  const url = `workinghour/update/${id}`;
  return api.post(u`${url}`, data);
};

const uploadArticleCSV = (data: FormData, id: string) => {
  const url = `uploadarticlecsv/${id}`;
  return api.post(u`${url}`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

const getArticles = (id: string) => {
  const url = `${id}/getarticles`;
  return api.get(u`${url}`);
};

const getSimpleArticles = (id: string) => {
  const url = `getsimplearticles/?rest_id=${id}`;
  return api.get(u`${url}`);
};

const deleteArticles = (restId: string, id: string) => {
  const url = `${restId}/article/delete/${id}`;
  return api.get(u`${url}`);
};

const getCategories = (id: string) => {
  const url = `${id}/categories/`;
  return api.get(u`${url}`);
};

const getArticleOptionNameModified = (id: string) => {
  const url = `${id}/articleoptionnamemodified`;
  return api.get(u`${url}`);
};

const getPostDataForArticle = (id: string) => {
  const url = `getpostdatagetforarticle/${id}`;
  return api.get(u`${url}`);
};

const createArticle = (data: FormData, id: string) => {
  const url = `${id}/article/create`;
  return api.post(u`${url}`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

const updateArticle = (data: FormData, id: string, restId: string) => {
  const url = `${restId}/article/update/${id}`;
  return api.post(u`${url}`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

const createAssembledArticle = (data: FormData, id: string) => {
  const url = `${id}/article/createassembled`;
  return api.post(u`${url}`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

const updateAssembledArticle = (data: FormData, id: string, restId: string) => {
  const url = `${restId}/article/updateassembled/${id}`;
  return api.post(u`${url}`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

const deleteCategory = (id: string, restId: string) => {
  const url = `${restId}/category/delete/${id}`;
  return api.delete(u`${url}`);
};

const createCategory = (
  data: {
    restaurant: string;
    name: string;
  },
  id: string
) => {
  const url = `${id}/category/create`;
  return api.post(u`${url}`, data);
};

const updateCategory = (
  data: {
    restaurant: string;
    name: string;
  },
  restId: string,
  id: string
) => {
  const url = `${restId}/category/update/${id}`;
  return api.post(u`${url}`, data);
};

const getArticleOptions = (id: string) => {
  const url = `${id}/articleoption`;
  return api.get(u`${url}`);
};

const deleteArticleOption = (restId: string, id: string) => {
  const url = `${restId}/articleoption/delete/${id}`;
  return api.get(u`${url}`);
};

const getArticleOptionNameSelection = (id: string) => {
  const url = `getarticleOptionNameSelection/${id}`;
  return api.get(u`${url}`);
};

const getArticleOptionForModal = (restId: string, id: string) => {
  const url = `${restId}/articleoption/${id}`;
  return api.get(u`${url}`);
};

const getArticleOptionNameMinMax = (id: string) => {
  const url = `getarticleoptionNameMinMax/${id}`;
  return api.get(u`${url}`);
};

const getArticleOptionNames = (id: string) => {
  const url = `${id}/articleoptionname`;
  return api.get(u`${url}`);
};

const createArticleOptionName = (
  data: {
    optionname: string;
    articleoptionnamerestaurant: string;
  },
  id: string
) => {
  const url = `${id}/articleoptionname/create`;
  return api.post(u`${url}`, data);
};

const updateArticleOptionName = (
  data: {
    articleoptionnamerestaurant: string;
    activetab: string;
    optionname: string;
    restaurant: string;
    optionalais: string;
    optionName: string;
    min: string;
    max: string;
    panel: string;
  },
  restId: string,
  id: string
) => {
  const url = `${restId}/articleoptionname/update/${id}`;
  return api.post(u`${url}`, data);
};

const updateNewArticleOption = (
  data: {
    optionname: string;
    articleoptionnamerestaurant: string;
  },
  id: string
) => {
  const url = `${id}/articleoption/update`;
  return api.post(u`${url}`, data);
};

const updateArticleOption = (
  data: {
    articleoptionrestaurant: string;
    optionname: string;
    choice: string;
    choice_price: string;
  },
  restId: string,
  id: string
) => {
  const url = `${restId}/articleoption/update/${id}`;
  return api.post(u`${url}`, data);
};

const deleteArticleOptionName = (restId: string, id: string) => {
  const url = `${restId}/articleoptionname/delete/${id}`;
  return api.get(u`${url}`);
};

const createArticleOption = (
  data: {
    cats: string;
    alias: string;
    description: string;
    min: string;
    max: string;
  },
  id: string,
  _url: string
) => {
  const url = `${id}/articleoption/${_url}/create`;
  return api.post(u`${url}`, data);
};

const getAllOrdersList = (id: string, sdate: string, edate: string) => {
  const url = `allorderslist/${id}?from_date=${sdate}&to_date=${edate}`;
  return api.get(u`${url}`);
};

const getAllCompletedOrdersList = (
  id: string,
  sdate: string | null,
  edate: string | null,
  page: string | number | null
) => {
  const url = `allcompletedorderslist/${id}?from_date=${sdate}&to_date=${edate}&page=${page}`;
  return api.get(u`${url}`);
};

const getOrder = (restId: string, id: string) => {
  const url = `${restId}/order/${id}`;
  return api.get(u`${url}`);
};

const changePassword = (data: FormData) => {
  // console.log(data);
  return api.post(u`change-password/`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

const forgetPassword = (data: FormData) => {
  return api.post(u`forget-password/`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

const confirmPassword = (data: FormData, id: string, code: string) => {
  const url = `${id}/forgot-password-confirm/${code}/`;
  return api.post(u`${url}`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

const resetPassword = (data: FormData, id: string, code: string) => {
  const url = `${id}/forgot-password-reset/${code}/`;
  return api.post(u`${url}`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

const addArticleOption = (
  data: {
    cats: any;
  },
  restId: string,
  id: string
) => {
  const url = `${restId}/articleoption/${id}/add`;
  return api.post(u`${url}`, data);
};

const updateOrderStatus = (orderId: string, data: FormData): Promise<AxiosPromise> => {
  return api.post(u`orderstatus/update/${orderId}`, data);
};

const getPrintersList = (restid: string): Promise<AxiosPromise> => {
  return api.get(u`printer/list/${restid}`);
};

const getPrinterUrl = (restid: string): Promise<AxiosPromise> => {
  return api.get(u`printer/${restid}`);
};

const partialUpdatePrinter = (printerId: string, data: FormData): Promise<AxiosPromise> => {
  return api.put(u`printer/partial_update/${printerId}`, data);
};
const updatePrinterCtgs = (printerId: string, categories: any[]): Promise<AxiosPromise> => {
  return api.post(u`printer/update_categories/${printerId}`, categories);
};

const printOrderNow = (orderId: string): Promise<AxiosPromise> => {
  return api.post(u`printnow/${orderId}`);
};

const removePrinter = (printerId: string): Promise<AxiosPromise> => {
  return api.delete(u`printer/remove/${printerId}`);
};
const changeAutoAcceptOrders = (restid: string, val: boolean) => {
  const data = {
    auto_accept_orders: val,
  };
  return api.put(u`restaurantdata/partialupdate/${restid}`, data);
};

const getRestaurantPanelUsers = (id: string) => {
  const url = `rights/restaurantuserslist/${id}`;
  return api.get(u`${url}`);
};

const getRestaurantAllowedUrls = (id: string) => {
  const url = `rights/listrights/${id}`;
  return api.get(u`${url}`);
};

const updatePanelUser = (
  data: {
    username?: string;
    password?: string;
    allowed_urls?: string[];
    roles?: number[];
  },
  profile_id: string
) => {
  const url = `rights/updateuser/${profile_id}`;
  return api.patch(u`${url}`, data);
};

const createPanelUser = (
  data: {
    username?: string;
    password?: string;
    allowed_urls?: string[];
    roles?: number[];
  },
  restid: string
) => {
  const url = `rights/${restid}`;
  return api.post(u`${url}`, data);
};

const removePanelUser = (userid: string) => {
  const url = `rights/removeuser/${userid}`;
  return api.delete(u`${url}`);
};

const updateRestaurantPanelRights = (
  data: {
    allowed_urls?: string[];
  },
  restid: string
) => {
  const url = `rights/updaterestrights/${restid}`;
  return api.patch(u`${url}`, data);
};

const getRestaurantTags = (id: string) => {
  const url = `tags/list/${id}`;
  return api.get(u`${url}`);
};

const createTag = (
  data: {
    name: string;
  },
  restid: string
) => {
  const url = `tags/create/${restid}`;
  return api.post(u`${url}`, data);
};

const updateTag = (
  data: {
    name: string;
  },
  tag_id: string
) => {
  const url = `tags/update/${tag_id}`;
  return api.post(u`${url}`, data);
};

const removeTag = (tag_id: string) => {
  const url = `tags/delete/${tag_id}`;
  return api.delete(u`${url}`);
};

const createPictureStorage = (rest_id: string, data: FormData) => {
  const url = `picturestorages/create/${rest_id}`;
  return api.post(u`${url}`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
const getPictureStorage = (id: string) => {
  const url = `picturestorages/list/${id}`;
  return api.get(u`${url}`);
};

const getCommonPictureStorages = (id: string) => {
  const url = 'commonpicturestorages/list/';
  return api.get(u`${url}`);
};

const createPicture = (storage_id: string, data: FormData) => {
  const url = `picture/add/${storage_id}`;
  return api.post(u`${url}`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

const createMultiplePictures = (storage_id: string, data: FormData) => {
  const url = `picture/addmany/${storage_id}`;
  return api.post(u`${url}`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

const editPicture = (picture_id: string, data: FormData) => {
  const url = `picture/edit/${picture_id}`;
  return api.post(u`${url}`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

const deletePicture = (picture_id: string) => {
  const url = `picture/remove/${picture_id}`;
  return api.delete(u`${url}`);
};

// Create new picture and set it as Article image
const uploadPicture = (article_id: string, data: FormData) => {
  const url = `picture/upload/${article_id}`;
  return api.post(u`${url}`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
// Create new picture and set it as Article image
const clearPicture = (article_id: string) => {
  const url = `picture/removefromarticle/${article_id}`;
  return api.post(u`${url}`);
};
const addPictureToArticle = (article_id: string, picture_id: string) => {
  const url = `picture/addtoarticle/${article_id}/${picture_id}`;
  return api.post(u`${url}`);
};

const ordersDetail = (order_id: any) => {
  const url = `${order_id}/ordersdetail/`;
  return api.get(u`${url}`);
};

const getPromocodes = (id: string) => {
  const url = `${id}/promocodes/list`;
  return api.get(u`${url}`);
};

const createPromocode = (restid: string, data: FormData) => {
  const url = `${restid}/promocodes/create`;
  return api.post(u`${url}`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

const updatePromocode = (restid: string, promo_id: string, data: FormData) => {
  const url = `${restid}/promocodes/update/${promo_id}`;
  return api.post(u`${url}`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

const deletePromocode = (restid: string, promo_id: string) => {
  const url = `${restid}/promocodes/delete/${promo_id}`;
  return api.delete(u`${url}`);
};

const getChain = (chain_id: any) => {
  const url = `manage_chain/${chain_id}`;
  return api.get(u`${url}`);
};

const updateChain = (chain_id: any, data: FormData) => {
  const url = `manage_chain/${chain_id}`;
  return api.post(u`${url}`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

const createChainRestaurant = (chain_id: any, data: FormData) => {
  const url = `create_chain_restaurant/${chain_id}`;
  return api.post(u`${url}`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

const removeChainRestaurant = (chain_id: any, restid: any) => {
  const url = `remove_chain_restaurant/${chain_id}/${restid}`;
  return api.post(u`${url}`);
};

const cloneChainRestaurant = (restid: any, chain_id: any) => {
  const url = `clone/${restid}/${chain_id}`;
  return api.post(u`${url}`);
};

const delegateRestOwnerRights = (user_id: any, data: FormData) => {
  const url = `delegate_rest_rights/${user_id}`;
  // Takes username and password as data to create new user
  return api.post(u`${url}`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

const updateChainUser = (user_id: any, data: FormData) => {
  const url = `update_chain_user/${user_id}`;
  return api.post(u`${url}`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

const getAllOrdersListChain = (chain_id: string, sdate: string | null, edate: string | null) => {
  const url = `allchainorders/${chain_id}?from_date=${sdate}&to_date=${edate}`;
  return api.get(u`${url}`);
};

const getAllArticlesListChain = (chain_id: string) => {
  const url = `allchainarticles/${chain_id}`;
  return api.get(u`${url}`);
};

const getAllCategoriesListChain = (chain_id: string) => {
  const url = `allchaincategories/${chain_id}`;
  return api.get(u`${url}`);
};

// Tables Locations
const getTablesLocationsByRestId = (restaurant__id: number) => {
  const url = `tables_locations/?restaurant__id=${restaurant__id}`;
  return api.get<null, ITablesLocation[]>(u`${url}`);
};

const getTablesLocationDetail = (id: number) => {
  const url = `tables_locations/${id}`;
  return api.get<null, ITablesLocation>(u`${url}`);
};

const postTablesLocationDetail = (data: Omit<ITablesLocation, 'id'>) => {
  const url = 'tables_locations/';
  return api.post<null, ITablesLocation>(u`${url}`, data);
};

const postTablesLocationWithTableGeneration = (data: ITablesLocationWithCapacity) => {
  const url = 'tables_locations/create_location_with_table_generation/';
  return api.post<null, ITablesLocationWithCapacity>(u`${url}`, data);
};

const putTablesLocation = (id: number, data: Omit<ITablesLocation, 'id'>) => {
  const url = `tables_locations/${id}/`;
  return api.put<null, ITablesLocation>(u`${url}`, data);
};

const deleteTablesLocation = (id: number) => {
  const url = `tables_locations/${id}/`;
  return api.delete<null, ITablesLocation>(u`${url}`);
};
// Tables
const getTablesByLocationId = (location__id: number) => {
  const url = `tables/?location__id=${location__id}`;
  return api.get<null, ITable[]>(u`${url}`);
};

const getTablesDetail = (id: number) => {
  const url = `tables/${id}`;
  return api.get<null, ITable>(u`${url}`);
};

const postTable = (data: Omit<ITable, 'id'>) => {
  const url = 'tables/';
  return api.post<null, ITable>(u`${url}`, data);
};

const putTable = (id: number, data: Omit<ITable, 'id'>) => {
  const url = `tables/${id}/`;
  return api.put<null, ITable>(u`${url}`, data);
};

const deleteTable = (id: number) => {
  const url = `tables/${id}/`;
  return api.delete<null, ITable>(u`${url}`);
};
// User Tags ===================================================================
const manageUserTagsItems = (
  rest_id: number,
  method: 'GET' | 'POST' | 'PUT' | 'DELETE',
  data?: any
) => {
  const url = 'user_tags_items/';
  const urlByRestId = `${url}?restaurant=${rest_id}`;
  switch (method) {
    case 'GET':
      return api.get<IUserTagItem[]>(u`${urlByRestId}`);
    case 'POST':
      return api.post(u`${url}`, data);
    case 'PUT':
      return api.put(u`${`${url}${data.id}/`}`, data);
    case 'DELETE':
      return api.delete(u`${`${url}${data}`}`);
    default:
      return api.get<IUserTagItem[]>(u`${url}`);
  }
};

const getOrCreateFixedUserTag = (value: number): Promise<any> => {
  const url = `fixed_user_tags_items/get_or_create_fixed/?value=${value}`;
  return api.get(u`${url}`);
};

const getOrCreatePercentageUserTag = (value: number): Promise<any> => {
  const url = `percentager_user_tags_items/get_or_create_percentage/?value=${value}`;
  return api.get(u`${url}`);
};

const getOrCreateArticleUserTag = (value: number): Promise<any> => {
  const url = `articles_user_tags_items/get_or_create_article/?value=${value}`;
  return api.get(u`${url}`);
};

const getUsetTagsTypes = () => {
  const url = 'user_tags_items/get_user_tag_types/';
  return api.get<IUserTagType[]>(u`${url}`);
};

const getCustomerByRestId = (
  rest_id: number,
  page?: number,
  page_size?: number,
  search?: string
) => {
  const url = 'customersbyrestid/';
  let concatUrl;
  if (page && page_size && search) {
    concatUrl = `${url}?rest_id=${rest_id}&page=${page}&page_size=${page_size}&search=${search}`;
  } else {
    concatUrl = `${url}?rest_id=${rest_id}`;
  }
  return api.get(u`${concatUrl}`);
};

const getCustomerMainInfo = (id: number) => {
  const url = `customer/${id}`;
  return api.get(u`${url}`);
};

const getUserTagByRestId = (rest_id: number) => {
  const url = `usertagbyrestid/?rest_id=${rest_id}`;
  return api.get(u`${url}`);
};

const addUsetTagToCustomer = (customer: number, user_tag_item: number) => {
  const data = {
    customer,
    user_tag_item,
  };
  const url = 'user_tags/';
  return api.post(u`${url}`, data);
};

const removeUsetTagToCustomer = (userTagId: number) => {
  const url = `user_tags/${userTagId}`;
  return api.delete(u`${url}`);
};

const getUserTagByUserIdRestId = (
  customerId: number,
  restId: number
): Promise<IUserTag | undefined> => {
  const url = `user_tags/get_by_user_id_rest_id/?rest_id=${restId}&customer_id=${customerId}`;
  return api.get(u`${url}`);
};

const getUserTagItemById = (userTagItemId: number): Promise<IUserTagItem> => {
  const url = `user_tags_items/${userTagItemId}/`;
  return api.get(u`${url}`);
};
// Role ========================================================================

const getRolesByRestaurantId = (restId: number): Promise<IRole[]> => {
  const url = `roles/?restaurant=${restId}`;
  return api.get(u`${url}`);
};
const getPermissionsRestaurantSite = (): Promise<IPermissionRestaurantSite[]> => {
  const url = 'permissions_restaurant_site/';
  return api.get(u`${url}`);
};

// Drag And Drop Function (Sort Articles)
const updateArticleIndexes = (updatedData: updatedData[], restId: string) => {
  const url = `${restId}/update-article-indexes/`;
  return api.post(u`${url}`, { updatedData });
};

export {
  login,
  getRestaurant,
  getRestaurantWorkingHours,
  deleteRestaurantWorkingHours,
  updateRestaurantData,
  partiallyUpdateRestaurantData,
  createRestaurantWorkingHours,
  updateRestaurantWorkingHours,
  uploadArticleCSV,
  getArticles,
  getSimpleArticles,
  deleteArticles,
  getCategories,
  getArticleOptionNameModified,
  getPostDataForArticle,
  createArticle,
  updateArticle,
  createAssembledArticle,
  updateAssembledArticle,
  deleteCategory,
  createCategory,
  updateCategory,
  getArticleOptions,
  deleteArticleOption,
  getArticleOptionNameSelection,
  getArticleOptionForModal,
  getArticleOptionNameMinMax,
  createArticleOptionName,
  updateArticleOptionName,
  updateNewArticleOption,
  updateArticleOption,
  getArticleOptionNames,
  deleteArticleOptionName,
  createArticleOption,
  getAllOrdersList,
  getAllCompletedOrdersList,
  getOrder,
  changePassword,
  forgetPassword,
  confirmPassword,
  resetPassword,
  addArticleOption,
  updateOrderStatus,
  getPrintersList,
  getPrinterUrl,
  partialUpdatePrinter,
  removePrinter,
  printOrderNow,
  changeAutoAcceptOrders,
  getRestaurantPanelUsers,
  getRestaurantAllowedUrls,
  updatePanelUser,
  createPanelUser,
  removePanelUser,
  updateRestaurantPanelRights,
  getRestaurantTags,
  createTag,
  updateTag,
  removeTag,
  createPictureStorage,
  getPictureStorage,
  getCommonPictureStorages,
  createPicture,
  createMultiplePictures,
  editPicture,
  deletePicture,
  uploadPicture,
  clearPicture,
  addPictureToArticle,
  updatePrinterCtgs,
  ordersDetail,
  getPromocodes,
  createPromocode,
  updatePromocode,
  deletePromocode,
  getChain,
  updateChain,
  createChainRestaurant,
  removeChainRestaurant,
  delegateRestOwnerRights,
  cloneChainRestaurant,
  getAllOrdersListChain,
  getAllArticlesListChain,
  getAllCategoriesListChain,
  updateChainUser,
  getTablesLocationsByRestId,
  getTablesLocationDetail,
  postTablesLocationDetail,
  postTablesLocationWithTableGeneration,
  putTablesLocation,
  deleteTablesLocation,
  getTablesByLocationId,
  getTablesDetail,
  postTable,
  putTable,
  deleteTable,
  manageUserTagsItems,
  getUsetTagsTypes,
  getOrCreateFixedUserTag,
  getOrCreatePercentageUserTag,
  getOrCreateArticleUserTag,
  getCustomerByRestId,
  getUserTagByRestId,
  getCustomerMainInfo,
  addUsetTagToCustomer,
  removeUsetTagToCustomer,
  getUserTagByUserIdRestId,
  getUserTagItemById,
  getRolesByRestaurantId,
  getPermissionsRestaurantSite,
  updateArticleIndexes,
};
