const calcOrdersRevenue = (orders: any[]) => {
  const revenue = orders.reduce(
    (prev: any, curr: any, idx: any) => {
      return { total_amount: parseFloat(prev.total_amount) + parseFloat(curr.total_amount) };
    },
    { total_amount: 0 }
  );

  return revenue.total_amount;
};

export default calcOrdersRevenue;
