import moment from 'moment';
import DatePicker from 'react-datepicker';
import { CSVLink } from 'react-csv';
import PerfectScrollbar from 'perfect-scrollbar';
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../Components/Loader';
import { API } from '../../api';
import { Header, Sidebar } from '../../Components';
import getRestaurantData from '../../store/actions/getRestaurantData';
import { OrderInfo } from './OrderInfo';
import './style.css';
import dwnIcon from '../../assets/images/download-icon.svg';

import { writeFile, utils } from 'xlsx';

import boxIcon from '../../assets/images/box.svg';
import euroIcon from '../../assets/images/euro-yellow.svg';
import boxYellow from '../../assets/images/box-yellow.svg';
import OrderDetailsModal from '../../Components/OrderDetailsModal';
// import ExcelDownloadButton from '../../Components/ExcelDownloadButton';

let ps: any;

const orderStatusSelectOpt = [
  { value: 'Pending', label: 'Pending' },
  { value: 'Accepted', label: 'Accepted' },
  { value: 'Rejected', label: 'Rejected' },
];

function Orders() {
  const [OrderData, setOrderData] = useState<any>([]);
  const [startDate, setStartDate] = useState<any>(new Date());
  const [endDate, setEndDate] = useState<any>(new Date());
  const [activeOrdersRows, setActiveOrdersRows] = useState<number[]>([]);
  const headers = [
    { label: 'OrderID', key: 'OrderID' },
    { label: 'Statusbestelling', key: 'Statusbestelling' },
    { label: 'Betalingstatus', key: 'Betalingstatus' },
    { label: 'Tafelnummer', key: 'Tafelnummer' },
    { label: 'OpmerkingKlant', key: 'Opmerkingklant' },
    { label: 'NaamKlant', key: 'NaamKlant' },
    { label: 'E_mail', key: 'E_mail' },
    { label: 'GSM', key: 'GSM' },
    { label: 'Adres', key: 'Adres' },
    { label: 'Bedrag', key: 'Bedrag' },
    { label: 'Voorwanneer', key: 'Voorwanneer' },
    { label: 'extra_field_1', key: 'extra_field_1' },
    { label: 'extra_field_2', key: 'extra_field_2' },
    { label: 'Articles', key: 'Articles' },
  ];

  const [data, setCsvData] = useState([]);
  const [is_loading, setIsLoading] = useState<boolean>(false);
  const [filterStatus, setFilterStatus] = useState<{ value: string; label: string } | null>(
    orderStatusSelectOpt[0]
  );
  const [openFirst, setOpenFirst] = useState(true);

  // OrderDetailsModal states
  const [detailsModal, setDetailsModal] = useState(false);
  const [orderNumberDetailsId, setOrderNumberDetailsId] = useState<any>(0);

  const dispatch = useDispatch();
  const restaurant = useSelector(({ restaurantData }: StoreRoot) => restaurantData);
  const [isAutoAcceptOrders, setAutoAcceptOrders] = useState<boolean>(false);

  const mainPanel = useRef<any>();
  const id = localStorage.getItem('rest_id') as string;

  useEffect(() => {
    document.title = 'Orders';
    // @ts-ignore
    // document.getElementById('orders_colorchange').style.color = 'darkred';
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(mainPanel.current);
      document.body.classList.toggle('perfect-scrollbar-on');
    }
    return () => {
      if (navigator.platform.indexOf('Win') > -1) {
        ps.destroy();
        document.body.classList.toggle('perfect-scrollbar-on');
      }
    };
  }, []);

  useEffect(() => {
    if (OrderData.length) {
      const orderFirstStatus = OrderData.find(
        (order: any) => order.order_status === filterStatus?.value
      );
      if (orderFirstStatus) {
        const { order_number } = orderFirstStatus;
        setActiveOrdersRows([order_number]);
        setOpenFirst(false);
      }
    }
  }, [filterStatus]);

  useEffect(() => {
    if (openFirst && OrderData.length) {
      const orderFirstStatus = OrderData.find(
        (order: any) => order.order_status === filterStatus?.value
      );
      if (orderFirstStatus) {
        const { order_number } = orderFirstStatus;
        setActiveOrdersRows([order_number]);
        setOpenFirst(false);
      }
    }
  }, [OrderData]);

  const addOrderNumberToActive = (order_number: number) => {
    const index = activeOrdersRows.indexOf(order_number);
    if (index === -1) {
      setActiveOrdersRows([...activeOrdersRows, order_number]);
    } else {
      setActiveOrdersRows(
        activeOrdersRows.filter((order_number_item) => order_number_item !== order_number)
      );
    }
  };

  const clearActiveOrder = () => {
    setActiveOrdersRows([]);
  };

  // Function to download Excel file

  const getAllOrdersList = async (id: string) => {
    const sDate = moment(startDate.getTime()).format('YYYY-MM-DD');
    const eDate = moment(endDate.getTime()).format('YYYY-MM-DD');

    setIsLoading(true);

    await API.restaurantApi
      .getAllOrdersList(id, sDate, eDate)
      .then((res: any) => {
        console.log('this is response', res);

        setIsLoading(false);

        const temCSVData = res.map((d: any) => {
          // Extracting article information and creating dynamic property names
          const articleProperties: { [key: string]: string } = {};
          d.article.forEach((article: any, index: number) => {
            const articleIndex = index + 1;
            articleProperties[`Article ${articleIndex}`] = article.article.articlename;
            articleProperties[`Total price of article ${articleIndex}`] =
              article.article_total_prize;
            articleProperties[`Tax % of article ${articleIndex}`] = article.article.dineintax + '%';
            articleProperties[`Quantity of article ${articleIndex}`] = article.article_quantity;

            // Extracting article options data
            article.article_options.forEach((option: any, optionIndex: number) => {
              articleProperties[`Option ${optionIndex + 1} of article ${articleIndex}`] =
                option.article_option.choice;
              articleProperties[`Price of Option ${optionIndex + 1} of article ${articleIndex}`] =
                option.choice_price;
            });
          });

          return {
            OrderID: `${d.order_number}`,
            Statusbestelling: String(d.order_status),
            Betalingstatus: String(d.payment_status),
            Tafelnummer: d.tableid === -1 ? '' : `${d.tableid}`,
            OpmerkingKlant: d.user_note,
            NaamKlant: d.customer_name,
            E_mail: d.email,
            GSM: d.phoneNumber,
            Adres: d.address,
            Bedrag: d.total_amount,
            Voorwanneer:
              d.delivery_time && d.tableid === -1
                ? moment(d.delivery_time.split('+')[0]).format('MMMM, DD YYYY HH:mm')
                : '',
            ...articleProperties, // Dynamically added article names with property names like "Article 1", "Article 2", etc.
          };
        });

        // console.log('this is tempCSVDATA', temCSVData);

        setCsvData(temCSVData);
        setOrderData(res);
        console.log(OrderData);
        
      })
      .catch((err) => toast.error(`Error is ${err.message}`, { containerId: 'B' }));
  };

  useEffect(() => {
    const rest_id = localStorage.getItem('rest_id') as string;

    getAllOrdersList(rest_id);
    dispatch(getRestaurantData(rest_id));
  }, [id]);

  useEffect(() => {
    // @ts-ignore
    setAutoAcceptOrders(restaurant.auto_accept_orders);
  }, [restaurant]);

  const getFilterData = async () => {
    const rest_id = localStorage.getItem('rest_id') as string;
    const sDate = moment(startDate.getTime()).format('YYYY-MM-DD');
    const eDate = moment(endDate.getTime()).format('YYYY-MM-DD');

    await API.restaurantApi
      .getAllOrdersList(rest_id, sDate, eDate)
      .then((res: any) => {
        const temCSVData = res.map((d: any) => {
          // Extracting article information and creating dynamic property names
          const articleProperties: { [key: string]: string } = {};
          d.article.forEach((article: any, index: number) => {
            const articleIndex = index + 1;
            articleProperties[`Article ${articleIndex}`] = article.article.articlename;
            articleProperties[`Total price of article ${articleIndex}`] =
              article.article_total_prize;
            articleProperties[`Tax % of article ${articleIndex}`] = article.article.dineintax + '%';
            articleProperties[`Quantity of article ${articleIndex}`] = article.article_quantity;

            // Extracting article options data
            article.article_options.forEach((option: any, optionIndex: number) => {
              articleProperties[`Option ${optionIndex + 1} of article ${articleIndex}`] =
                option.article_option.choice;
              articleProperties[`Price of Option ${optionIndex + 1} of article ${articleIndex}`] =
                option.choice_price;
            });
          });

          return {
            OrderID: `${d.order_number}`,
            Statusbestelling: String(d.order_status),
            Betalingstatus: String(d.payment_status),
            Tafelnummer: d.tableid === -1 ? '' : `${d.tableid}`,
            OpmerkingKlant: d.user_note,
            NaamKlant: d.customer_name,
            E_mail: d.email,
            GSM: d.phoneNumber,
            Adres: d.address,
            Bedrag: d.total_amount,
            Voorwanneer:
              d.delivery_time && d.tableid === -1
                ? moment(d.delivery_time.split('+')[0]).format('MMMM, DD YYYY HH:mm')
                : '',
            ...articleProperties, // Dynamically added article names with property names like "Article 1", "Article 2", etc.
          };
        });

        // console.log('this is tempCSVDATA 2', temCSVData);

        setCsvData(temCSVData);
        setOrderData(res);
      })
      .catch((err) => toast.error(`Error is ${err.message}`, { containerId: 'B' }));
  };

  const onChangeStatus = async (e: React.ChangeEvent<HTMLSelectElement>, order_number: any) => {
    const new_status = e.target.value;
    const formData = new FormData();
    formData.append('order_status', new_status);

    await API.restaurantApi
      .updateOrderStatus(String(order_number), formData)
      .then(() => {
        toast.success('Updated Successfully!', { containerId: 'B' });
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch(() => {
        window.location.reload();
      });
  };

  const renderStatus = (currentStatus: string, order_number: any) => {
    if (currentStatus === 'Pending') {
      return (
        <select
          name="pets"
          id="pet-select"
          onClick={(e) => e.stopPropagation()}
          onChange={(e) => {
            e.stopPropagation();
            onChangeStatus(e, order_number);
          }}
        >
          <option value="Pending" defaultChecked>
            {currentStatus}
          </option>
          <option value="Accepted">Accepted</option>
          <option value="Rejected">Rejected</option>
        </select>
      );
    }
    return currentStatus;
  };

  // Auto refresh Orders List each 7 seconds
  useEffect(() => {
    const refresh_interval = setInterval(() => {
      const rest_id = localStorage.getItem('rest_id') as string;

      getAllOrdersList(rest_id);
    }, 7000);
    return () => {
      clearInterval(refresh_interval);
    };
  }, [getFilterData]);

  const handleFilterStatus = (val: { value: string; label: string } | null) => {
    setFilterStatus(val);
  };

  // Styles For React Select
  const selectStyles = {
    control: (base: any) => ({
      ...base,
      height: '27px',
      minHeight: '27px',
    }),
    valueContainer: (provided: any, state: any) => ({
      ...provided,
      height: '27px',
      padding: '0 6px',
    }),

    input: (provided: any, state: any) => ({
      ...provided,
      margin: '0px',
    }),
    indicatorSeparator: (state: any) => ({
      display: 'none',
    }),
    indicatorsContainer: (provided: any, state: any) => ({
      ...provided,
      height: '27px',
    }),
    option: (base: any) => ({
      ...base,
      height: '27px',
      textAlign: 'start',
      paddingTop: '5px',
    }),
  };

  const handlePrintNow = (order_id: any) => {
    API.restaurantApi
      .printOrderNow(order_id)
      .then(() => {
        toast.success('Order was sent to printer!', { containerId: 'B' });
        const rest_id = localStorage.getItem('rest_id') as string;
        getAllOrdersList(rest_id);
      })
      .catch(() => {
        toast.error('Order was not sent to printer!', { containerId: 'B' });
      });
  };

  const renderPrintButton = (order_id: any, print_now: any) => {
    return (
      <button
        type="button"
        onClick={(e: any) => {
          e.stopPropagation();
          handlePrintNow(order_id);
        }}
        disabled={print_now}
        className="btn btn-danger btn-sm"
      >
        Print
      </button>
    );
  };
  const handleAutoAcceptOrders = async (val: boolean) => {
    // @ts-ignore
    const res = await API.restaurantApi.changeAutoAcceptOrders(restaurant.id, val);
  };

  const toggleAutoAcceptOrders = () => {
    handleAutoAcceptOrders(!isAutoAcceptOrders);
    setAutoAcceptOrders(!isAutoAcceptOrders);
  };

  const handleShowOrderDetails = (order_number: any) => () => {
    addOrderNumberToActive(order_number);
  };

  function getTotalAmount(filteredOrders: any) {
    const sum = filteredOrders.reduce((total: any, item: any) => {
      return total + Number(item.total_amount);
    }, 0);
    return sum.toFixed(2);
  }

  // OrderDetailsModal
  const toggleOrderDetailsModal = (order_number: any) => {
    if (typeof order_number === 'object') {
      setOrderNumberDetailsId(0);
    }
    setDetailsModal(!detailsModal);
    setOrderNumberDetailsId(order_number);
  };

  const downloadExcel = () => {
    const ws = utils.json_to_sheet(data);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, 'Orders');
    writeFile(wb, `orderdata_${new Date()}.xlsx`);
  };

  return (
    <>
      <Sidebar />
      <div id="border-top-radius" className="container-fluid main-panel" ref={mainPanel}>
        <Header />

        {/* 25th January changes */}
        <div className="container pt-5 pl-5 mt-5">
          <div className="d-flex justify-content-between">
            <div
              className="card text-white rounded"
              style={{ width: '18rem', backgroundColor: '#ffd204' }}
            >
              <div className="card-body">
                <h6 className="card-subtitle mb-2 text-muted">
                  <img src={boxIcon} alt="box-icon" style={{ height: '30px', width: '30px' }} />
                </h6>
                <h5 className="card-title">Totaal # Bestellingen</h5>
                <h3 className="card-text font-weight-bold">{OrderData.length || 0}</h3>
              </div>
            </div>
            <div
              className="card text-white rounded"
              style={{ width: '18rem', backgroundColor: '#252d79' }}
            >
              <div className="card-body">
                <h6 className="card-subtitle mb-2 text-muted">
                  <img src={euroIcon} alt="box-icon" style={{ height: '30px', width: '30px' }} />
                </h6>
                <h5 className="card-title">Bruto Totaal</h5>
                <h3 className="card-text font-weight-bold">€{getTotalAmount(OrderData)}</h3>
              </div>
            </div>
            <div
              className="card text-white rounded"
              style={{ width: '18rem', backgroundColor: '#252d79' }}
            >
              <div className="card-body">
                <h6 className="card-subtitle mb-2 text-muted">
                  <img src={boxYellow} alt="box-icon" style={{ height: '30px', width: '30px' }} />
                </h6>
                <h5 className="card-title">Totaal Openstaande</h5>
                <h3 className="card-text font-weight-bold">
                  {OrderData.filter((order: any) => order.order_status == 'Pending').length}
                </h3>
              </div>
            </div>
            {/* <div style={{ marginTop: '11px' }} className="csv-data">
              <CSVLink
                data={data}
                headers={headers}
                target="_blank"
                className="btn btn-primary btn-success btn-sm"
                filename={`orderdata_${new Date()}.csv`}
              >
            
                <span>
                  <img src={dwnIcon} alt="downloadIcon" height={'15px'} width={'15px'} />
                </span>
                Exporteren naar csv
              </CSVLink>
            </div> */}
          </div>
        </div>
        {/* 25th January changes */}

        <div className="row mt-5 pt-5">
          <div className="col-xl-10 offset-xl-1 col-lg-8 offset-lg-3 col-md-8 offset-md-4 col-sm-12 col-12 text-center">
            <span className="h4 mb-2"> Overzicht bestellingen</span>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <div style={{ display: 'flex' }} className="mt-3">
              <form
                style={{ display: 'flex' }}
                onSubmit={(e) => {
                  e.preventDefault();
                  clearActiveOrder();
                  setOpenFirst(true);
                  getFilterData();
                }}
              >
                <div>
                  <label
                    htmlFor="sel1"
                    className="control-label font-weight-bold m-0 mb-2 mt-3"
                    style={{ padding: '2px 10px 0px' }}
                  >
                    Start Date
                  </label>
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    required
                  />
                </div>
                <div>
                  <label
                    htmlFor="sel1"
                    className="control-label font-weight-bold m-0 mb-2 mt-3"
                    style={{ padding: '2px 10px 0px' }}
                  >
                    End Date
                  </label>
                  <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} required />
                </div>

                <div style={{ minWidth: '10rem', marginTop: '0.8rem', marginLeft: '1.5rem' }}>
                  <Select
                    styles={selectStyles}
                    onChange={handleFilterStatus}
                    options={orderStatusSelectOpt}
                    defaultValue={filterStatus}
                  />
                </div>

                <div style={{ padding: '0px 20px 8px' }}>
                  <button
                    type="submit"
                    className="btn btn-success  shadow-none"
                    style={{
                      padding: '3px 33px 3px',
                      marginTop: '12px',
                      borderRadius: '7px',
                    }}
                  >
                    Filter
                  </button>
                </div>
              </form>

              {/* <div style={{ marginTop: '11px' }} className="csv-data">
                <CSVLink
                  data={data}
                  headers={headers}
                  target="_blank"
                  className="btn btn-primary btn-success btn-sm"
                  filename={`orderdata_${new Date()}.csv`}
                >
                  Download CSV
                </CSVLink>
              </div> */}

              <div style={{ marginTop: '11px' }} className="csv-data">
                <button onClick={downloadExcel} className="btn btn-primary btn-success btn-sm">
                  Download Excel
                </button>
              </div>
            </div>
            <div className="table-responsive">
              {is_loading && <Loader />}
              <table className="table table-striped table-bordered table-hover mt-3">
                <thead>
                  <tr>
                    <th className="small"> </th>
                    <th className="small">Products</th>
                    <th className="small">Order ID</th>
                    <th className="small">Status bestelling</th>
                    <th className="small">Betalingstatus</th>
                    <th className="small">Tafelnummer</th>
                    <th className="small">Opmerking klant</th>
                    <th className="small">Naam klant</th>
                    <th className="small">E-mail</th>
                    <th className="small">GSM</th>
                    <th className="small">Adres</th>
                    <th className="small">Bedrag</th>
                    <th className="small">Voorwanneer </th>
                  </tr>
                </thead>
                <tbody>
                  {OrderData &&
                    OrderData.map((order: any, index: any) => {
                      if (order.order_status !== filterStatus?.value) return null;
                      return (
                        <React.Fragment key={index}>
                          <tr
                            className="order-details"
                            // onClick={handleShowOrderDetails(order.order_number)}
                            onClick={() => toggleOrderDetailsModal(order.order_number)}
                          >
                            <td
                              className={
                                order.created_with_permission === "Bartender's access"
                                  ? 'small bar-row'
                                  : 'small'
                              }
                            >
                              {renderPrintButton(order.order_number, order.print_now)}
                            </td>
                            <td className="small" key={`article-${index}`}>
                              {order.article.map((article: any, index: any) => (
                                <React.Fragment key={`${article.article_quantity}-${article.article.articlename}`}>
                                  {article.article_quantity}x {article.article.articlename}
                                  {index !== order.article.length - 1 && ', '}
                                </React.Fragment>
                              ))}
                            </td>
                            <td className="small">{order.order_number}</td>
                            <td className="small">
                              {renderStatus(order.order_status, order.order_number)}
                            </td>
                            <td className="small">{String(order.payment_status)}</td>
                            <td className="small">{order.tableid === -1 ? '' : order.tableid}</td>
                            <td className="small">{order.user_note ? order.user_note : ''}</td>
                            <td className="small">
                              {order.customer_name ? order.customer_name : ''}
                            </td>
                            <td className="small">{order.email ? order.email : ''}</td>
                            <td className="small">{order.phoneNumber ? order.phoneNumber : ''}</td>
                            <td className="small">{order.address ? order.address : ''}</td>
                            <td className="small">{order.total_amount}</td>
                            <td className="small">
                              {order.delivery_time && order.tableid === -1
                                ? `${moment(order.delivery_time.split('+')[0]).format(
                                    'MMMM, DD YYYY HH:mm'
                                  )}`
                                : ''}
                            </td>
                          </tr>
                          {activeOrdersRows.indexOf(order.order_number) !== -1 && (
                            <tr>
                              {/* <td
                                colSpan={12}
                                className={
                                  order.created_with_permission === "Bartender's access"
                                    ? 'bar-row'
                                    : ''
                                }
                              >

                              </td> */}
                            </tr>
                          )}
                        </React.Fragment>
                      );
                    })}
                  <tr style={{ height: '2rem' }} />
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <OrderDetailsModal
        orderId={orderNumberDetailsId}
        toggle={toggleOrderDetailsModal}
        isOpen={detailsModal}
      />
    </>
  );
}

export default Orders;
