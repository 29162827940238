import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { toast } from 'react-toastify';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { Button, InputField } from '../index';
import { API } from '../../api';

const PicturePreview: FC<any> = (props) => {
  const [picture, setPicture] = useState<any>('');
  const [pictureId, setPictureId] = useState<any>(false);
  const restId = localStorage.getItem('rest_id') as string;
  const [pictureName, setPictureName] = useState('');

  const asyncDispatch: ThunkDispatch<StoreRoot, any, AnyAction> = useDispatch();

  useEffect(() => {
    setPictureId(props.pictureId);
    if (typeof props.picture === 'undefined') {
      setPicture('');
    } else {
      setPicture(props.picture?.name || '');
    }
  }, [props]);

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    props.toggle && props.toggle();
  };

  useEffect(() => {
    if (!props.modal) window.removeEventListener('keydown', handleArrows);
    if (props.modal) window.addEventListener('keydown', handleArrows);
    return () => {
      window.removeEventListener('keydown', handleArrows);
    };
  }, [props]);

  const handleArrows = (e: any) => {
    if (e.keyCode === 37) {
      props.prev(props.picture);
    } else if (e.keyCode === 39) {
      props.next(props.picture);
    }
  };

  return (
    <Modal isOpen={props.modal} toggle={props.toggle}>
      <ModalHeader toggle={props.toggle}>Picture preview</ModalHeader>
      <ModalBody>
        <div className="picture-preview-wrapper">
          <i
            onClick={(e) => props.prev(props.picture)}
            className={`fa fa-chevron-left ${
              props.index <= 0 || props.storage_length === 1 ? 'text-muted' : ''
            }`}
            aria-hidden="true"
          ></i>
          <div className="picture-block">
            <img src={props.picture?.image} alt="" />
            <span>{props.picture?.name}</span>
          </div>
          <i
            onClick={(e) => props.next(props.picture)}
            className={`fa fa-chevron-right ${
              props.index >= props.storage_length - 1 || props.storage_length === 1
                ? 'text-muted'
                : ''
            }`}
            aria-hidden="true"
          ></i>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button name="Close" btnLongWidth={false} onClick={handleCancel} type="button" />
      </ModalFooter>
    </Modal>
  );
};
export default PicturePreview;
