import PerfectScrollbar from 'perfect-scrollbar';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Switch from 'react-switch';
import { getRestaurantData } from '../../store/actions';
import { API } from '../../api';
import { API_BASE_URL, MAIN_APP_URL } from '../../services/urls';
import {
  Button,
  DownloadQR,
  Header,
  InputField,
  Sidebar,
  WorkingHoursModal,
} from '../../Components';
import './style.css';
import DeleteModalComponent from '../../Components/DeleteModalComponent';
import VisitUrl from '../../Components/VisitUrl/VisitUrl';

let ps: any;

const PaymentView: FC = () => {
  const [modal, setModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const [qrmodal, setQrModal] = useState(false);
  const [restaurantData, setRestaurantData] = useState<any>([]);
  const [workinghoursData, setworkinghoursData] = useState([]);
  const [workinghourId, setworkinghourId] = useState(0);
  const [workinghourobj, setworkinghourobj] = useState<any>(0);
  const { id } = useParams<any>();
  const restid = localStorage.getItem('rest_id') as string;
  const dutchWeekDays = new Map([
    ['Monday', 'Maandag'],
    ['Tuesday', 'Dinsdag'],
    ['Wednesday', 'Woensdag'],
    ['Thursday', 'Donderdag'],
    ['Friday', 'Vrijdag'],
    ['Saturday', 'Zaterdag'],
    ['Sunday', 'Zondag'],
  ]);
  const mainPanel = useRef<any>();

  const restaurant = useSelector(({ restaurantData }: StoreRoot) => restaurantData);

  const dispatch = useDispatch();

  const getWorkingHours = () => {
    API.restaurantApi
      .getRestaurantWorkingHours(restid)
      .then((res) => {
        setworkinghoursData(res.data);
      })
      .catch((err) => console.log('Error is', err.message));
  };

  useEffect(() => {
    setRestaurantData(restaurant);
  }, [restaurant]);

  useEffect(() => {
    // @ts-ignore
    document.getElementById('dashboard_colorchange').style.color = 'darkred';
    dispatch(getRestaurantData(restid));
    getWorkingHours();

    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(mainPanel.current);
      document.body.classList.toggle('perfect-scrollbar-on');
    }
    return () => {
      if (navigator.platform.indexOf('Win') > -1) {
        ps.destroy();
        document.body.classList.toggle('perfect-scrollbar-on');
      }
    };
  }, [qrmodal, modal, id, restid]);

  const toggle = (id: any, workinghour: any) => {
    setModal(!modal);
    setworkinghourId(id);
    setworkinghourobj(workinghour);
    getWorkingHours();
  };
  const toggleDeleteModal = (id: any, articleopt: any) => {
    setDeleteModal(!deleteModal);
    setworkinghourId(id);
    setworkinghourobj(articleopt);
  };

  const deleteWorkinghour = async (id: any) => {
    await API.restaurantApi.deleteRestaurantWorkingHours(id).then((res) => {
      toast.success('Deleted Successfully!', { containerId: 'B' });
      getWorkingHours();
    });
  };

  const changerestaurantdata = (e: any) => {
    setRestaurantData({
      ...restaurantData,
      [e.target.name]: [e.target.value],
    });
  };

  const handleChangeStatus = () => {
    let valueA;
    if (restaurantData.Availability === 'OFFLINE') {
      valueA = 'ONLINE';
    } else {
      valueA = 'OFFLINE';
    }
    setRestaurantData({
      ...restaurantData,
      Availability: valueA,
    });
  };

  const handleChangeDelivery = (e: any) => {
    let valueA;
    if (restaurantData.pickup_option === false) {
      valueA = false;
    } else {
      valueA = true;
    }
    setRestaurantData({
      ...restaurantData,
      delivery_option: e,
    });
  };

  const handleChangePickup = (e: any) => {
    let valueA;

    if (restaurantData.pickup_option === false) {
      valueA = false;
    } else {
      valueA = true;
    }
    setRestaurantData({
      ...restaurantData,
      pickup_option: e,
    });
  };

  const SubmitRestaurantdata = async (e: any) => {
    e.preventDefault();
    const payment_method_type = e.target.elements.payment_method_type.value || 'PAYNL';
    const paynl_service_id = e.target.elements.paynl_service_id?.value || '';
    const paynl_token_code = e.target.elements.paynl_token_code?.value || '';
    const apikey = e.target.elements.apikey.value;
    const slug = e.target.elements.slug.value;

    let meta_titles = [];
    if (e.target.elements.meta_titles.value.replaceAll(/,/g, ' ').split(' ').pop() === '') {
      meta_titles = e.target.elements.meta_titles.value
        .replaceAll(/,/g, ' ')
        .split(' ')
        .slice(0, -1);
    } else {
      meta_titles = e.target.elements.meta_titles.value.replaceAll(/,/g, ' ').split(' ');
    }

    const formData = new FormData();

    formData.append('Availability', restaurantData.Availability);

    formData.append('apikey', apikey);
    formData.append('payment_method_type', payment_method_type);
    if (payment_method_type === 'PAYNL') {
      formData.append('paynl_service_id', paynl_service_id);
      formData.append('paynl_token_code', paynl_token_code);
    } else {
      formData.append('paynl_service_id', '');
      formData.append('paynl_token_code', '');
    }
    formData.append('slug', slug);
    formData.append('meta_titles', JSON.stringify(meta_titles));

    await API.restaurantApi
      .updateRestaurantData(formData, restid)
      .then(() => {
        toast.success('Updated Successfully!', { containerId: 'B' });
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch(() => {
        window.location.reload();
      });
  };

  const handleChangePaymentMethod = (e: any) => {
    // Change method and clear fields
    setRestaurantData({
      ...restaurantData,
      payment_method_type: e.target.value,
      apikey: '',
      paynl_service_id: '',
      paynl_token_code: '',
    });
  };

  return (
    <>
      <Sidebar />
      <div id="border-top-radius" className="container-fluid main-panel" ref={mainPanel}>
        <Header />
        <div className="row mr-md-5 mt-5 pt-5">
          <div className="col-xl-10 offset-xl-1 col-lg-9 offset-lg-3 col-md-8 offset-md-4 col-sm-12 col-12 offsetMd5 text-center">
            <span className="h4 mb-3">Bewerk betaling webshop</span>
          </div>
          <div className="col-xl-10 offset-xl-1 col-lg-8 offset-lg-4 col-md-8 offset-md-4 col-sm-12 col-12 mt-3 offsetMd5">
            <form onSubmit={(e) => SubmitRestaurantdata(e)} className="mb-5 payment-view-form">
              <div className="form-group">
                <label
                  htmlFor="availibility"
                  className="control-label font-weight-bold m-0 mb-2 mt-3"
                >
                  Status:
                </label>
                <div className="form-group">
                  <Switch
                    onChange={() => handleChangeStatus()}
                    onColor="#40bf26"
                    name="Availability"
                    checked={restaurantData.Availability === 'ONLINE'}
                  />
                </div>
              </div>

              {/* Hidden until another payment methods added */}
              <div className="form-group d-none">
                <label htmlFor="paynow" className="control-label font-weight-bold m-0 mb-2 mt-3">
                  Choose payment method
                </label>
                <select
                  value={restaurantData.payment_method_type}
                  onChange={(e) => handleChangePaymentMethod(e)}
                  name="payment_method_type"
                  className="form-control"
                  id="payment_method_type"
                >
                  <option value="PAYNL">Pay.nl</option>
                </select>
              </div>

              <div className="form-group">
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label className="control-label font-weight-bold m-0 mb-2 mt-3">
                  <span style={{ textTransform: 'capitalize' }}>
                    {restaurantData.payment_method_type}
                  </span>{' '}
                  online betalingen API:
                </label>
                <input
                  name="apikey"
                  className="form-control"
                  type="input"
                  id="api_id"
                  placeholder="Enter api key"
                  value={restaurantData.apikey || ''}
                  onChange={(e) => changerestaurantdata(e)}
                />
              </div>

              <div className="form-group">
                <label className="control-label font-weight-bold m-0 mb-2 mt-3">
                  Paynl service id (SL-....-....)
                </label>
                <input
                  name="paynl_service_id"
                  className="form-control"
                  type="input"
                  id="paynl_service_id"
                  placeholder="Enter service id"
                  value={restaurantData.paynl_service_id || ''}
                  onChange={(e) => changerestaurantdata(e)}
                />
              </div>

              <div className="form-group">
                <label className="control-label font-weight-bold m-0 mb-2">
                  Paynl token code (AT-....-....)
                </label>
                <input
                  name="paynl_token_code"
                  className="form-control"
                  type="input"
                  id="paynl_token_code"
                  placeholder="Enter token code"
                  value={restaurantData.paynl_token_code || ''}
                  onChange={(e) => changerestaurantdata(e)}
                />
              </div>
              <label htmlFor="sel1" className="control-label font-weight-bold m-0 mb-2 mt-3">
                Meta title:
              </label>
              <InputField
                className="form-control mt-1"
                rows={1}
                name="meta_titles"
                placeholder="Meta titles "
                value={restaurantData.RestaurantName || ''}
                onChange={(e: any) => changerestaurantdata(e)}
              />
              <label htmlFor="slug" className="control-label font-weight-bold m-0 mb-2 mt-3">
                Slug
              </label>
              <InputField
                required
                name="slug"
                placeholder="Slug"
                // value={(restaurantData?.RestaurantName?.trim() || '').replace(/\s+/g, '-')}
                value={restaurantData.slug || ''}
                onChange={(e: any) => changerestaurantdata(e)}
              />
              {/* <div className="text-right p-3">
                <Button name="Save" htmlType="submit" dangerButton={true} btnLongWidth={false} />
              </div> */}
              <VisitUrl />
            </form>
          </div>
        </div>
      </div>
      <WorkingHoursModal
        workinghourobj={workinghourobj}
        modal={modal}
        toggle={toggle}
        workinghourid={workinghourId}
      />
      <DownloadQR />
      <DeleteModalComponent
        articleopt={workinghourobj}
        isOpen={deleteModal}
        toggle={toggleDeleteModal}
        articleoptId={workinghourId}
        deleteFunction={deleteWorkinghour}
      />
    </>
  );
};
export default PaymentView;
