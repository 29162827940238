import React, { FC, useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import {
  Modal, ModalBody, ModalFooter, ModalHeader,
  Form, FormGroup, Label, Input, FormText,
} from 'reactstrap';
import { Button } from './index';
import { SelectWithValidate } from './SelectWithValidate';
import {
  getSimpleArticles, getOrCreateArticleUserTag, getOrCreateFixedUserTag, getOrCreatePercentageUserTag, getUsetTagsTypes, manageUserTagsItems,
} from '../api/restaurantApi.service';
import InputField from './InputField'
import { IUserTagType } from '../api/DTO/restaurantDTO';

interface UserTagModalProps {
  isOpen: boolean;
  toggle: () => void;
  restId: string;
  editUserTagItem: UserTagItem | null;
  clearEditMode: () => void;
}

const userTagTypesMap: {[s: string]: string} = {
  fixed: 'Vast bedrag',
  percentage: 'Percentage',
  product: 'Gratis products',
}

type TOption = {value: number; label: string}

const UserTagItemsModal: FC<UserTagModalProps> = (props) => {
  const {
    isOpen, toggle, restId, clearEditMode, editUserTagItem,
  } = props
  const [itemTypeCurrent, setItemTypeCurrent] = useState<IUserTagType | undefined>(undefined)
  const [itemTypeList, setItemTypeList] = useState<IUserTagType[]>([])

  const [optionProductList, setOptionProductList] = useState<TOption[]>([])
  const [optionTypeList, setOptionTypeList] = useState<TOption[]>([])

  const [formArticleOption, setFormArticleOption] = useState < TOption|null>(null)
  const [formTypeOption, setFormTypeOption] = useState < TOption|null>(null)

  const [name, setName] = useState<string>('')
  const [formFixedValue, setFormFixedValue] = useState<number >(0)
  const [formPercentageValue, setFormPercentageValue] = useState<number>(0)
  const [loading, setLoading] = useState(true);

  // Form Error Messages
  const [productErrorMessage, setProductErrorMessage] = useState<string>('');
  const [userTagTypeErrorMessage, setUserTagTypeErrorMessage] = useState<string>('');

  const convertProductToOption = (response: Article[]): TOption[] => {
    return response.map<TOption>((product): TOption => {
      return { value: product.id, label: product.articlename }
    })
  }

  const convertTypeToOption = (response: IUserTagType[]): TOption[] => {
    return response.map<TOption>((tagType): TOption => {
      return { value: tagType.id, label: userTagTypesMap[tagType.name] }
    })
  }

  useEffect(() => {
    let mounted = true;
    getUsetTagsTypes()
      .then((response: any) => {
        if (!mounted) return
        if (response && response.length) {
          const tOptions = convertTypeToOption(response)
          setOptionTypeList(tOptions)
          setFormTypeOption(tOptions[0])
          setItemTypeList(response)
          setItemTypeCurrent(response[0])
        }
        return getSimpleArticles(restId)
      })
      .then((response: any) => {
        if (!mounted) return
        setLoading(false)
        if (response && response.length) {
          const pOptions = convertProductToOption(response);
          setFormArticleOption(pOptions[0])
          setOptionProductList(pOptions)
        }

        if (!editUserTagItem) {
          return
        }

        const itemType = itemTypeList.find((item) => item.id === editUserTagItem.user_tag_content_type);
        if (!itemType) {
          toast.error('No item type', { containerId: 'B' });
          return
        }
        setName(editUserTagItem.name)
        setItemTypeCurrent(itemType)
        const currentTypeOption = optionTypeList.find((option: TOption) => option.value === itemType.id);
        currentTypeOption && setFormTypeOption(currentTypeOption)

        switch (itemType.name) {
          case ('fixed'):
            setFormFixedValue(+editUserTagItem.user_tag_concrete)
            break;
          case ('percentage'):
            setFormPercentageValue(+editUserTagItem.user_tag_concrete)
            break;
          case ('product'):
            if (response && response.length) {
              const pOptions2 = convertProductToOption(response)
              const optionUserTagItem = pOptions2.find((option) => {
                return option.label === editUserTagItem.user_tag_concrete
              })
              optionUserTagItem && setFormArticleOption(optionUserTagItem)
            }
            break;
        }
      })
    return () => {
      mounted = false;
      setItemTypeCurrent(undefined);
      setItemTypeList([]);
      setName('');
      setFormFixedValue(0);
      setFormPercentageValue(0);
      setFormFixedValue(0);
      setFormPercentageValue(0);
      setFormArticleOption(null)
      setLoading(true);
    };
  }, [editUserTagItem, isOpen])

  const clearForm = () => {
    setItemTypeCurrent(undefined)
    setName('')
    setFormFixedValue(0)
    setFormPercentageValue(0)
    setFormArticleOption(null)
    setLoading(true)
    clearEditMode()
  }

  const handleChangeSelectOptionType = (option: TOption | null) => {
    if (!option) {
      return
    }
    setUserTagTypeErrorMessage('')
    setFormTypeOption(option)
    const current = itemTypeList.find((item) => item.id === option.value)
    setItemTypeCurrent(current)
  }

  const handleChangeProductOption = (option: TOption | null) => {
    option && setProductErrorMessage('')
    setFormArticleOption(option)
  }

  const handleToggle = () => {
    if (!loading) {
      clearForm()
      toggle()
    }
  }

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (!name) {
      toast.error('Please enter gebruikertag name', { containerId: 'B' });
      return
    }
    if (!itemTypeCurrent) {
      toast.error('No gebruikertag type', { containerId: 'B' });
      setUserTagTypeErrorMessage('Select gebruikertag type is required')
      return
    }
    let userTagItem;
    switch (itemTypeCurrent.name) {
      case ('fixed'): {
        const fixed = formFixedValue < 0 ? 0 : formFixedValue
        setFormFixedValue(fixed)
        userTagItem = getOrCreateFixedUserTag(fixed)
        break;
      }
      case ('percentage'): {
        let percentage
        if (+formPercentageValue > 100) {
          percentage = 100
        } else if (+formPercentageValue < 0) {
          percentage = 0
        } else {
          percentage = formPercentageValue
        }
        setFormPercentageValue(percentage)
        userTagItem = getOrCreatePercentageUserTag(percentage)
        break;
      }
      case ('product'):
        if (!formArticleOption) {
          setProductErrorMessage('Article data is required')
          return
        }
        userTagItem = getOrCreateArticleUserTag(formArticleOption.value)
        break;
      default:
        toast.error('Cant recognaze gebruikertag type', { containerId: 'B' })
        throw new Error('Cant recognaze gebruikertag type');
    }

    if (editUserTagItem) {
      userTagItem.then((response) => manageUserTagsItems(+restId, 'PUT', {
        id: editUserTagItem.id,
        name,
        restaurant: restId,
        user_tag_id: response.id,
        user_tag_content_type: itemTypeCurrent.id,
      }))
        .then(() => {
          toast.success('UserTag updated successfully!', { containerId: 'B' })
          handleToggle()
        })
    } else {
      userTagItem
        .then((response) => {
          return manageUserTagsItems(+restId, 'POST', {
            name,
            restaurant: restId,
            user_tag_id: response.id,
            user_tag_content_type: itemTypeCurrent.id,
          })
        })
        .then(() => {
          toast.success('UserTag added successfully!', { containerId: 'B' });
          handleToggle()
        })
        .catch((reject) => {
          if (reject && reject.response) {
            const { data } = reject.response
            if (data.name) {
              toast.error(data.name[0], { containerId: 'B' })
            } else {
              toast.error('Unknown Error while submit', { containerId: 'B' });
            }
          }
        })
    }
  }

  const renderTypeInput = () => {
    if (!itemTypeCurrent || !itemTypeList.length) {
      return null
    }
    let input = null;
    switch (itemTypeCurrent.name) {
      case 'fixed':
        input = (
          <FormGroup>
            <Label for="fixed">Vast bedrag</Label>
            <Input
              value={formFixedValue}
              onChange={(event: any) => {
                setFormFixedValue(event.target.value);
              }}
              type="text"
              name="fixed"
              id="fixed"
              placeholder="Enter vast bedrag"
            />
          </FormGroup>
        )
        break;

      case 'percentage':
        input = (
          <FormGroup>
            <Label for="percentage">Percentage</Label>
            <Input
              value={formPercentageValue}
              onChange={(event: any) => {
                setFormPercentageValue(event.target.value);
              }}
              type="text"
              name="percentage"
              id="percentage"
              placeholder="Enter percentage"
            />
          </FormGroup>
        )
        break;

      case 'product':
        input = (
          <FormGroup>
            <SelectWithValidate
              label="Gratis products"
              className="basic-single"
              value={formArticleOption || null}
              isSearchable={true}
              options={optionProductList}
              onChange={handleChangeProductOption}
              errorMessage={productErrorMessage}
            />
          </FormGroup>
        )
        break;

      default:
        break;
    }

    return input
  }

  return (
    <Modal isOpen={isOpen} toggle={handleToggle}>
      <Form onSubmit={handleSubmit}>
        <ModalHeader toggle={handleToggle}>
          Voeg gebruikertag toe
        </ModalHeader>
        {loading ? (
          <div className="loading">Loading...</div>
        ) : (
          <ModalBody>
            <FormGroup>
              <Label for="userTagItemName">Naam tag</Label>
              <InputField
                value={name}
                className="form-control"
                onChange={(event: any) => {
                  setName(event.target.value);
                }}
                type="text"
                name="userTagItem"
                id="userTagItemName"
                placeholder="naam"
                required
              />
            </FormGroup>
            <FormGroup>
              <SelectWithValidate
                label="Kortingtype"
                className="basic-single"
                value={formTypeOption || null}
                isSearchable={false}
                options={optionTypeList}
                onChange={handleChangeSelectOptionType}
                errorMessage={userTagTypeErrorMessage}
              />
            </FormGroup>
            {renderTypeInput()}
          </ModalBody>
        )}
        <ModalFooter>
          <Button name="Cancel" btnLongWidth={false} onClick={handleToggle} type="button" />
          <Button
            name="Save"
            type="submit"
            dangerButton={true}
            btnLongWidth={false}
          />
        </ModalFooter>
      </Form>
    </Modal>
  )
}

export default UserTagItemsModal
