import PerfectScrollbar from 'perfect-scrollbar';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { API_BASE_URL } from '../../services/urls';
import { EditArticleModel, Header, Sidebar } from '../../Components';
import EditArticlePictureModal from '../../Components/Pictures/EditArticlePictureModal';
import { API } from '../../api';
import {
  getArticles,
  getCurrentPictureStorage,
  getCommonPictureStorage,
} from '../../store/actions';
import DeleteModalComponent from '../../Components/DeleteModalComponent';
import { updateArticleIndexes } from '../../api/restaurantApi.service';

// dragNdrop task
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

//css
import './styles.css';

let ps: any;

const EditArticle: FC = () => {
  const [modal, setModal] = useState(false);
  const [pictureModal, setPictureModal] = useState(false);
  const [articleId, setArticleId] = useState(0);
  const [Articleobj, setArticleobj] = useState(0);
  const [deleteModal, setDeleteModal] = useState(false);

  const current_pict_storage: any = useSelector(
    ({ currentPictureStorage }: StoreRoot) => currentPictureStorage
  );
  const common_pict_storages = useSelector(
    ({ commonPictureStorages }: StoreRoot) => commonPictureStorages
  );

  const [currImg, setCurrImg] = useState<any>(null);

  const articleOptionsName = useSelector(({ articles }: StoreRoot) => articles);

  // (dragNdrop task)
  // const [tempSortedList, setTempSortedList] = useState([...articleOptionsName]);
  const [tempSortedList, setTempSortedList] = useState<Article[]>([]);
  const [changesMade, setChangesMade] = useState(false);

  const dispatch = useDispatch();

  const mainPanel = useRef<any>();
  const id = localStorage.getItem('rest_id') as string;

  useEffect(() => {
    document.title = 'Edit Article';
    // @ts-ignore
    // document.getElementById('editarticle_colorchange').style.color = 'darkred';
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(mainPanel.current);
      document.body.classList.toggle('perfect-scrollbar-on');
    }
    return () => {
      if (navigator.platform.indexOf('Win') > -1) {
        ps.destroy();
        document.body.classList.toggle('perfect-scrollbar-on');
      }
    };
  }, [modal]);

  useEffect(() => {
    dispatch(getCurrentPictureStorage(id));
    dispatch(getCommonPictureStorage(id));
    dispatch(getArticles(id));
  }, [id]);

  useEffect(() => {
    // (dragNdrop task)
    // setTempSortedList([...articleOptionsName]);
    const deepCopy = JSON.parse(JSON.stringify(articleOptionsName));
    setTempSortedList(deepCopy);
  }, [articleOptionsName, changesMade]);

  const toggle = (id: any, articleopt: any) => {
    setModal(!modal);
    setArticleId(id);
    setArticleobj(articleopt);
  };

  const closeModal = (e: any) => {
    e.preventDefault();
    setModal(!modal);
  };

  const articledelete = async (id: string) => {
    const rest_id = localStorage.getItem('rest_id') as string;
    await API.restaurantApi
      .deleteArticles(rest_id, id)
      .then((res) => {
        toast.success('Deleted Successfully!', { containerId: 'B' });
        dispatch(getArticles(rest_id));
      })
      .catch((err) => {
        dispatch(getArticles(rest_id));
      });
  };

  const renderTags = (article: any) => {
    return article?.tags.length > 0 ? article.tags.map((item: any) => item.name).join(', ') : '';
  };

  const renderDeliveryTypes = (article: any) => {
    return article?.delivery_type?.length > 0
      ? article.delivery_type.map((item: any) => item.toLowerCase()).join(', ')
      : '';
  };

  const togglePictureModal = (article: any) => {
    const rest_id = localStorage.getItem('rest_id') as string;
    setArticleId(article.id);
    setArticleobj(article);
    setPictureModal(!pictureModal);
    setCurrImg(null);
    dispatch(getArticles(rest_id));
  };

  const deleteArticlePicture = (art_id: any) => {
    API.restaurantApi
      .clearPicture(art_id)
      .then((res) => {
        const rest_id = localStorage.getItem('rest_id') as string;
        toast.success('Picture removed!', { containerId: 'B' });
        dispatch(getArticles(rest_id));
      })
      .catch((err) => {
        toast.error('Error removing picture', { containerId: 'B' });
      });
  };

  const handleSubmitArticlePicture = (e: any, art: any) => {
    if (!currImg) {
      toast.error('Please select a picture', { containerId: 'B' });
      return;
    }
    API.restaurantApi
      .addPictureToArticle(art.id, currImg.id)
      .then((res) => {
        const rest_id = localStorage.getItem('rest_id') as string;
        toast.success('Picture added to article!', { containerId: 'B' });
        setCurrImg(null);
        dispatch(getArticles(rest_id));
        togglePictureModal(art);
      })
      .catch((err) => {
        toast.error('Error adding picture to article', { containerId: 'B' });
      });
  };

  const toggleDeleteModal = (id: any, articleopt: any) => {
    setDeleteModal(!deleteModal);
    setArticleId(id);
    setArticleobj(articleopt);
  };

  function dragEnded(result: any) {
    if (!result.destination) return;

    const items = Array.from(tempSortedList);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    // Update the indexes after the drag and drop operation
    const updatedItems = items.map((item, index) => ({ ...item, index }));

    setTempSortedList(updatedItems);
  }

  const handleUpdate = async () => {
    const rest_id = localStorage.getItem('rest_id') as string;

    // Create a new array with only id and index
    const updatedDataArray: updatedData[] = tempSortedList.map((article) => ({
      id: article.id,
      new_index: article.index,
    }));

    // Send a post request to the backend
    await API.restaurantApi
      .updateArticleIndexes(updatedDataArray, rest_id)
      .then((response) => {
        toast.success('Articles updated successfully!', { containerId: 'B' });
      })
      .catch((error) => {
        toast.error('Error updating articles', { containerId: 'B' });
      });

    setChangesMade(false); // Reset changesMade after successful update
  };

  return (
    <>
      <Sidebar />
      <div id="border-top-radius" className="container-fluid main-panel" ref={mainPanel}>
        <Header />
        {articleOptionsName && (
          <div className="row mt-5 pt-5">
            <div className="col-xl-10 offset-xl-1 col-lg-8 offset-lg-3 col-md-8 offset-md-4 col-sm-12 col-12 text-center">
              <div className="table-responsive">
                <span className="h4 mb-3"> Beheer producten</span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <button
                  type="button"
                  onClick={() => toggle('new', 'new')}
                  className="btn btn-yellow shadow-none"
                >
                  Voeg product toe
                </button>
                {/* <button onClick={() => testSortedList()}>Test sorted list</button> */}
                {!changesMade && (
                  <button
                    className="btn ml-2 btn-success rounded-0 shadow-none"
                    onClick={handleUpdate}
                  >
                    Update Articles Order
                  </button>
                )}
                <table className="table table-striped table-bordered mt-3 product-table">
                  <thead>
                    <tr>
                      <th className="small">Naam product</th>
                      <th className="small">Prijs</th>
                      <th className="small">Categorie</th>
                      <th className="small">BTW % bij pickup/levering</th>
                      <th className="small">BTW % bij ter plaatse</th>
                      <th className="small">Meer informatie</th>
                      {/* <th className="small">Ingredienten</th> */}
                      <th className="small">Tags</th>
                      <th className="small">Delivery Type</th>
                      <th className="small">Afbeelding</th>
                      <th className="small">Actie</th>
                    </tr>
                  </thead>
                  <DragDropContext onDragEnd={dragEnded}>
                    <Droppable droppableId="comments-wrapper">
                      {(provided, snapshot) => (
                        <tbody {...provided.droppableProps} ref={provided.innerRef}>
                          {tempSortedList.map((articleopt: any, index: number) => {
                            return (
                              <Draggable
                                draggableId={`${articleopt.id}`}
                                index={index}
                                key={articleopt.id}
                              >
                                {(_provided, _snapshot) => (
                                  <tr
                                    ref={_provided.innerRef}
                                    {..._provided.draggableProps}
                                    {..._provided.dragHandleProps}
                                  >
                                    <td className="small">{articleopt.articlename}</td>
                                    <td className="small">{articleopt.price}</td>
                                    <td className="small">
                                      {articleopt.category ? articleopt.category.name : ''}
                                    </td>
                                    <td className="small">{articleopt.pickuptax}</td>
                                    <td className="small">{articleopt.dineintax}</td>
                                    <td className="small">{articleopt.description}</td>
                                    {/* <td>
                                      {articleopt.ingredient
                                        ? articleopt.ingredient.map(
                                            (ingred: any, index: number) => (
                                              <div key={index}>{`${ingred.name},`}</div>
                                            )
                                          )
                                        : ''}
                                    </td> */}
                                    <td className="small">{renderTags(articleopt)}</td>
                                    <td className="small">{renderDeliveryTypes(articleopt)}</td>
                                    <td>
                                      {articleopt.image && (
                                        <img
                                          className="restaurant_images"
                                          id="blah"
                                          src={articleopt.image?.image}
                                          alt=""
                                        />
                                      )}
                                      <div className="ml-2 d-flex flex-column align-items-center">
                                        {articleopt.image ? (
                                          <i
                                            className="fa pb-2 fa-pencil cursr"
                                            aria-hidden="true"
                                            onClick={() => togglePictureModal(articleopt)}
                                          />
                                        ) : (
                                          <i
                                            className="fa fa-plus cursr"
                                            aria-hidden="true"
                                            onClick={() => togglePictureModal(articleopt)}
                                          ></i>
                                        )}
                                        {articleopt.image && (
                                          <i
                                            className="fa fa-trash cursr"
                                            aria-hidden="true"
                                            onClick={() => deleteArticlePicture(articleopt.id)}
                                          />
                                        )}
                                      </div>
                                    </td>
                                    {/* <td className='small'>{articleopt.ingredient}</td> */}
                                    <td className="small">
                                      <i
                                        className="fa fa-pencil cursr"
                                        aria-hidden="true"
                                        onClick={() => toggle(articleopt.id, articleopt)}
                                      />
                                      <i
                                        className="fa fa-trash pl-2 cursr"
                                        aria-hidden="true"
                                        // onClick={() => articledelete(articleopt.id)}
                                        onClick={() => toggleDeleteModal(articleopt.id, articleopt)}
                                      />
                                    </td>
                                  </tr>
                                )}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </tbody>
                      )}
                    </Droppable>
                  </DragDropContext>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
      <EditArticleModel
        article={Articleobj}
        modal={modal}
        setModal={setModal}
        toggle={closeModal}
        articleId={articleId}
      />
      <EditArticlePictureModal
        article={Articleobj}
        modal={pictureModal}
        toggle={togglePictureModal}
        currImg={currImg}
        setCurrImg={setCurrImg}
        handleSubmit={handleSubmitArticlePicture}
      />
      <DeleteModalComponent
        articleopt={setArticleobj}
        isOpen={deleteModal}
        toggle={toggleDeleteModal}
        articleoptId={articleId}
        deleteFunction={articledelete}
      />
    </>
  );
};

export default EditArticle;
