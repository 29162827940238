import React from 'react'

const renderOptionsForIncluded = (included: any, order_article: any, orderDetails: any) => {
  let opts_for_current: any[] = [];
  if (orderDetails?.data?.order_combo_options?.length > 0) {
    opts_for_current = orderDetails.data?.order_combo_options.filter(
      (item: any) => item.included_article === included.id && item.order_article === order_article.id,
    );
  }

  return opts_for_current?.length > 0 ? (
    <div className="col-12 text-left text-muted">
      (
      {opts_for_current.map((item: any, index: any) => (
        <span key={`${included.id} ${item.id}`}>
          {item.article_option.choice}
          {index + 1 < opts_for_current.length ? ', ' : ''}
        </span>
      ))}
      )
    </div>
  ) : null;
};

type Props = {
  article: any;
  orderDetails: any;
}

const IncludedArticles = (props: Props) => {
  const { article, orderDetails } = props

  let combo_opts_for_current: any[] = [];
  if (orderDetails?.data?.order_combo_options) {
    combo_opts_for_current = orderDetails?.data?.order_combo_options.filter(
      (item: any) => article.id === item.order_article,
    );
  }

  if (!article || !article.article || !article.article.included_articles) return null

  return article.article?.included_articles?.map((item: any, index: any) => (
    <div key={`${item.id} ${index}`}>
      <div className="col-12 text-left">
        {item.articlename}
      </div>
      <div className="mb-1">
        {renderOptionsForIncluded(item, article, orderDetails)}
      </div>
    </div>
  ))
}

export { IncludedArticles }
