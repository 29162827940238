import PerfectScrollbar from 'perfect-scrollbar';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Header, Sidebar } from '../../Components';
import { API } from '../../api';
import {
  getCurrentPictureStorage,
  getCommonPictureStorage,
  getRestaurantData,
} from '../../store/actions';
import PictureModal from '../../Components/Pictures/PictureModal';
import PicturesTable from '../../Components/Pictures/PicturesTable';
import PicturePreview from '../../Components/Pictures/PicturePreview';
import Select from 'react-select';

let ps: any;
const PictureGallery: FC = () => {
  const [modal, setModal] = useState(false);
  const mainPanel = useRef<any>();
  const id = localStorage.getItem('rest_id') as string;
  const dispatch = useDispatch();

  const [createStorageName, setCreateStorageName] = useState('');

  const [pictureId, setPictureId] = useState(null);
  const [pictureObj, setPictureObj] = useState(null);

  const [storageSelectOptions, setStorageSelectOptions] = useState<any[]>([]);
  const [selectedStorage, setSelectedStorage] = useState<any>(null);

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewPictIndex, setPreviewPictIndex] = useState<any>(null);
  const [previewStorageLength, setPreviewStorageLength] = useState<any>(null);

  const current_pict_storage: any = useSelector(
    ({ currentPictureStorage }: StoreRoot) => currentPictureStorage
  );
  const common_pict_storages = useSelector(
    ({ commonPictureStorages }: StoreRoot) => commonPictureStorages
  );

  useEffect(() => {
    document.title = 'Afbeeldingen';
    // @ts-ignore
    // document.getElementById('gallery_colorchange').style.color = 'darkred';
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(mainPanel.current);
      document.body.classList.toggle('perfect-scrollbar-on');
    }
    return () => {
      if (navigator.platform.indexOf('Win') > -1) {
        ps.destroy();
        document.body.classList.toggle('perfect-scrollbar-on');
      }
    };
  }, [modal, id]);

  useEffect(() => {
    dispatch(getCurrentPictureStorage(id));
    dispatch(getCommonPictureStorage(id));
  }, [id]);

  useEffect(() => {
    let new_select_options = [];
    const all_opt = {
      id: 0,
      value: '__all__',
      label: 'All storages',
    };
    new_select_options.push(all_opt);
    const curr_storage_opt = {
      id: current_pict_storage.id,
      value: `${current_pict_storage.name} #${current_pict_storage.id}`,
      label: 'Eigen afbeeldingen',
    };
    setSelectedStorage(curr_storage_opt);
    new_select_options.push(curr_storage_opt);
    const other_storages_opt = common_pict_storages.map((item: any) => ({
      id: item.id,
      value: `${item.name} #${item.id}`,
      label: `Algemene afbeeldingen - ${item.name}`,
    }));
    new_select_options = [...new_select_options, ...other_storages_opt];

    setStorageSelectOptions(new_select_options);
  }, [current_pict_storage, common_pict_storages]);

  const toggle = (id: any, tag: any) => {
    if (id?.preventDefault) {
      id.preventDefault();
    }

    setModal(!modal);
    setPictureId(id);
    setPictureObj(tag);
  };

  const findStorage = (image: any) => {
    let storage;
    if (current_pict_storage.id === image.storage) {
      storage = current_pict_storage;
    } else {
      storage = common_pict_storages.find((item: any) => item.id === image.storage);
    }
    return storage;
  };

  const handlePreview = (e: any, image: any) => {
    const storage = findStorage(image);
    const curr_idx = storage.storage_pictures.findIndex((item: any) => item.id === image.id);
    setPreviewPictIndex(curr_idx);
    setPreviewStorageLength(storage.storage_pictures.length);
    setPreviewOpen(true);
  };

  const togglePreview = () => {
    setPreviewOpen(!previewOpen);
  };

  const handleNextPicture = (image: any) => {
    const storage = findStorage(image);
    const curr_idx = storage.storage_pictures.findIndex((item: any) => item.id === image.id);
    let next_picture;

    setPreviewPictIndex(curr_idx + 1);
    setPreviewStorageLength(storage.storage_pictures.length);

    if (storage.storage_pictures.length - 1 > curr_idx) {
      next_picture = storage.storage_pictures[curr_idx + 1];
    } else {
      next_picture = image;
    }

    setPictureObj(next_picture);
  };
  const handlePrevPicture = (image: any) => {
    const storage = findStorage(image);
    const curr_idx = storage.storage_pictures.findIndex((item: any) => item.id === image.id);
    let prev_picture;
    setPreviewPictIndex(curr_idx - 1);
    setPreviewStorageLength(storage.storage_pictures.length);

    if (curr_idx > 0) {
      prev_picture = storage.storage_pictures[curr_idx - 1];
    } else {
      prev_picture = image;
    }

    setPictureObj(prev_picture);
  };

  const handleEdit = (image: any) => {
    setModal(true);
    setPictureId(image.id);
    setPictureObj(image);
  };

  const handleDelete = (image: any) => {
    API.restaurantApi
      .deletePicture(image.id)
      .then((res) => {
        toast.success('Picture removed!', { containerId: 'B' });
        dispatch(getCurrentPictureStorage(id));
        dispatch(getCommonPictureStorage(id));
      })
      .catch((err) => {
        toast.error('Error removing picture', { containerId: 'B' });
      });
  };

  const handleCreatePictureStorage = (e: any) => {
    console.log('handleCreatePictureStorage');
    const formData = new FormData();
    if (createStorageName) {
      formData.append('name', createStorageName);
    }
    API.restaurantApi
      .createPictureStorage(id, formData)
      .then((res) => {
        toast.success('Storage created', { containerId: 'B' });
        dispatch(getCurrentPictureStorage(id));
      })
      .catch((err) => {
        console.log('err', err);
        toast.error('Error creating storage', { containerId: 'B' });
      });
  };

  return (
    <>
      <Sidebar />
      <div id="border-top-radius" className="container-fluid main-panel mb-5" ref={mainPanel}>
        <Header />
        <div className="row ml-lg-5 mt-5 pt-5">
          <div className="col-xl-10 offset-xl-1 col-lg-8 offset-lg-3 col-md-8 offset-md-4 col-sm-12 col-12 text-center">
            {!current_pict_storage?.id ? (
              <div className="create-storage row">
                <div className="col-sm-4">
                  <input
                    value={createStorageName}
                    onChange={(e) => setCreateStorageName(e.target.value)}
                    type="text"
                    name="storage-name"
                    id="storage-name"
                    className="form-control"
                  />
                </div>
                <div className="col-sm-3">
                  <button
                    type="button"
                    onClick={handleCreatePictureStorage}
                    className="form-control btn btn-danger rounded-0 shadow-none"
                  >
                    Maak map aan
                  </button>
                </div>
              </div>
            ) : (
              <>
                <span className="h4">Afbeeldingen</span>
                &nbsp;&nbsp;&nbsp;
                <button
                  type="button"
                  onClick={() => toggle('new', 'new')}
                  className="btn btn-yellow shadow-none"
                >
                  Voeg afbeelding toe
                </button>
              </>
            )}
            <div className="table-responsive mt-4" style={{ overflow: 'visible' }}>
              <div className="col-sm-4 m-0 p-0">
                <Select
                  styles={{ menu: (base) => ({ ...base, zIndex: 10 }) }}
                  value={selectedStorage}
                  onChange={(val: any) => setSelectedStorage(val)}
                  options={storageSelectOptions}
                />
              </div>

              {selectedStorage?.id === current_pict_storage.id || selectedStorage?.id === 0 ? (
                <>
                  {selectedStorage?.id === current_pict_storage.id ? null : (
                    <h4 className="mt-4" style={{ textAlign: 'start' }}>
                      Eigen afbeeldingen - {current_pict_storage.name}
                    </h4>
                  )}
                  <div className="mt-4 mb-4"></div>
                  <PicturesTable
                    storage={current_pict_storage}
                    currImg={pictureObj}
                    setCurrImg={setPictureObj}
                    enablePreview={true}
                    enableEdit={true}
                    enablePictSelect={false}
                    handlePreview={handlePreview}
                    handleEdit={handleEdit}
                    handleDelete={handleDelete}
                  />
                  <br />
                </>
              ) : null}
              {common_pict_storages.map((pict_storage: any) =>
                selectedStorage?.id === pict_storage.id || selectedStorage?.id === 0 ? (
                  <React.Fragment key={pict_storage.id}>
                    <h4 className="mt-4 mb-4" style={{ textAlign: 'start' }}>
                      Algemene afbeeldingen - {pict_storage.name}
                    </h4>
                    <PicturesTable
                      storage={pict_storage}
                      currImg={pictureObj}
                      setCurrImg={setPictureObj}
                      enablePreview={true}
                      enableEdit={false}
                      enablePictSelect={false}
                      handlePreview={handlePreview}
                      handleEdit={handleEdit}
                      handleDelete={handleDelete}
                    />
                  </React.Fragment>
                ) : null
              )}
            </div>
          </div>
        </div>
      </div>
      <PictureModal picture={pictureObj} pictureId={pictureId} modal={modal} toggle={toggle} />
      <PicturePreview
        picture={pictureObj}
        modal={previewOpen}
        toggle={togglePreview}
        next={handleNextPicture}
        prev={handlePrevPicture}
        index={previewPictIndex}
        storage_length={previewStorageLength}
      />
    </>
  );
};

export default PictureGallery;
