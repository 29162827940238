import PerfectScrollbar from 'perfect-scrollbar';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Switch from 'react-switch';
import { getRestaurantData } from '../../store/actions';
import { API } from '../../api';
import { API_BASE_URL, MAIN_APP_URL } from '../../services/urls';
import { Header } from '../../Components';
import ChainSidebar from '../../Components/ChainComponents/ChainSidebar';
import ChainModal from '../../Components/ChainComponents/ChainModal';
import EditChainModal from '../../Components/ChainComponents/EditChainModal';
import getChainRestaurants from '../../Components/ChainComponents/helpers/getChainRestaurants';
import getChain from '../../Components/ChainComponents/helpers/getChain';
import DelegateOwnerModal from '../../Components/ChainComponents/DelegateOwnerModal';
import './style.css';
import ChainEditUserModal from '../../Components/ChainComponents/ChainEditUserModal';

let ps: any;

const PickupPoints = () => {
  const mainPanel = useRef<any>();
  const chain = localStorage.getItem('chain');
  const [modal, setModal] = useState(false);
  const [modalChain, setModalChain] = useState(false);
  const [chainRestaurants, setChainRestaurants] = useState<any[]>([]);
  const [chainLoaded, setChainLoaded] = useState<any>(null);
  const [editUserModal, setEditUserModal] = useState<boolean>(false);
  const [editUser, setEditUser] = useState<any>(null);

  // Delegate owner rights
  const [isDelegating, setIsDelegating] = useState<boolean>(false);
  const [curentUser, setCurrentUser] = useState<any>(null);

  // Just a refresh flag
  const [refresh, setRefresh] = useState<boolean>(false);

  useEffect(() => {
    document.title = 'Pickuplocatie';
    const sidebar_link = document.getElementById('pickup_points_colorchange');
    if (sidebar_link) {
      sidebar_link.style.color = 'darkred';
    }

    const parsed_chain = chain ? JSON.parse(chain) : null;

    getChainRestaurants(parsed_chain?.id, setChainRestaurants);
    getChain(parsed_chain?.id, setChainLoaded);

    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(mainPanel.current);
      document.body.classList.toggle('perfect-scrollbar-on');
    }
    return () => {
      if (navigator.platform.indexOf('Win') > -1) {
        ps.destroy();
        document.body.classList.toggle('perfect-scrollbar-on');
      }
    };
  }, [modal, modalChain, editUserModal, chain, refresh]);

  useEffect(() => {
    const not_parsed_user = localStorage.getItem('current_user');
    let current_user: any = null;
    if (not_parsed_user) {
      current_user = JSON.parse(not_parsed_user);
      setCurrentUser(current_user);
      if (current_user.is_owner) {
        setIsDelegating(true);
      }
    }
  }, []);

  const toggle = () => {
    setModal(!modal);
  };

  const toggleEditChain = (e: any) => {
    setModalChain(!modalChain);
  };

  const toggleDelegateRights = () => {
    setIsDelegating(!isDelegating);
  };

  const toggleEditUser = (user?: any) => {
    if (user) setEditUser(user);
    setEditUserModal(!editUserModal);
  };

  const deleteRestaurant = (restid: any) => {
    API.restaurantApi
      .removeChainRestaurant(chainLoaded?.id, restid)
      .then((res) => {
        toast.success('Restaurant removed', { containerId: 'B' });
        setRefresh(!refresh);
      })
      .catch((err) => {
        console.log('error', err);
        toast.error('Restaurant not removed', { containerId: 'B' });
      });
  };

  const cloneRestaurant = (restid: any) => {
    const parsed_chain = chain ? JSON.parse(chain) : null;
    if (!parsed_chain?.id) return;
    API.restaurantApi
      .cloneChainRestaurant(restid, parsed_chain.id)
      .then((res) => {
        toast.success('Restaurant cloned', { containerId: 'B' });
        setRefresh(!refresh);
      })
      .catch((err) => {
        toast.error('Cloning failure', { containerId: 'B' });
      });
  };

  return (
    <>
      <ChainSidebar />
      <div id="border-top-radius" className="container-fluid main-panel" ref={mainPanel}>
        <Header />
        <div className="row mr-md-5 mt-5 pt-5">
          <div className="col-xl-10 offset-xl-1 col-lg-9 offset-lg-3 col-md-8 offset-md-4 col-sm-12 col-12 offsetMd5 text-center">
            <h1 className="pickup-points-heading">
              <div>{chainLoaded?.name}</div>

              <i
                className="fa fa-pencil cursr edit-chain-icon"
                aria-hidden="true"
                onClick={toggleEditChain}
              />
              {curentUser?.is_owner ? (
                <button
                  title="In order to complete chain creation you must delegate owner rights for restaurant to another user"
                  className="delegate-btn btn btn-warning rounded-0"
                  onClick={() => setIsDelegating(true)}
                >
                  Delegate rights
                  <i className="fa fa-exclamation-triangle ml-2" aria-hidden="true"></i>
                </button>
              ) : null}
            </h1>
            <span className="h4 mb-3">Pickuplocatie</span>
            &nbsp;&nbsp;&nbsp;
            <button
              type="button"
              onClick={() => toggle()}
              className="btn btn-danger rounded-0 shadow-none"
            >
              + Voeg restaurant toe
            </button>
            <div className="table-responsive mt-4">
              <table className="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th className="small">Naam</th>
                    <th className="small">Gebruiker</th>
                    <th className="small">Actie</th>
                  </tr>
                </thead>
                <tbody>
                  {chainRestaurants.map((net_rest: any) => {
                    return (
                      <tr key={net_rest.id}>
                        <td>{net_rest.RestaurantName}</td>
                        <td>{net_rest.user.username} </td>
                        <td>
                          <i
                            className="fa fa-pencil cursr"
                            aria-hidden="true"
                            id={net_rest.id}
                            onClick={() => toggleEditUser(net_rest.user)}
                          />

                          <i
                            className="fa fa-files-o pl-2 cursr"
                            title="Clone restaurant, password will be kept the same"
                            onClick={() => cloneRestaurant(net_rest.id)}
                            aria-hidden="true"
                          ></i>

                          <i
                            className="fa fa-trash pl-2 cursr"
                            aria-hidden="true"
                            onClick={() => deleteRestaurant(net_rest.id)}
                            id={net_rest.id}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-xl-10 offset-xl-1 col-lg-8 offset-lg-4 col-md-8 offset-md-4 col-sm-12 col-12 mt-3 offsetMd5"></div>
          <div className="col-xl-10 offset-xl-1 col-lg-8 offset-lg-4 col-md-8 offset-md-4 col-sm-12 col-12 offsetMd5">
            <div className="p-3">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
          </div>
        </div>
      </div>
      <ChainModal toggle={toggle} modal={modal} chain_id={chainLoaded?.id} />
      <EditChainModal
        chain={chainLoaded}
        chainId={chainLoaded?.id}
        toggle={toggleEditChain}
        modal={modalChain}
      />
      <DelegateOwnerModal modal={isDelegating} toggle={toggleDelegateRights} />
      <ChainEditUserModal modal={editUserModal} toggle={toggleEditUser} user={editUser} />
    </>
  );
};

export default PickupPoints;
