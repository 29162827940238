import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { toast } from 'react-toastify';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { Button, InputField } from '../index';
import { API } from '../../api';
import moment from 'moment';
import Select from 'react-select';

interface Props {
  printer: {
    name: string | null;
    epson_id: string | null;
    epson_version: number | null;
    activity_status: string;
    added: string;
    current_order: string | null;
    encodings: string | null;
    id: number | string | null;
    mac_address: string | null;
    poll_interval: number | string | null;
    restaurant: number | string | null;
    unique_id: string;
    printer_type: string | null;
    categories: any[];
  };
  modal: boolean;
  printerId: string;
  categories: any[];
  toggle: () => {};
  updatePrinter: (printer_id: any, data: any) => any;
}

const StarEditModal: FC<any> = (props: Props) => {
  const restId = localStorage.getItem('rest_id') as string;
  const [name, setName] = useState<string>('');
  const [categories, setCategories] = useState<any[]>([]);

  useEffect(() => {
    if (props.printer?.categories && categories) {
      const prev_categories = props.categories.filter((item: any) =>
        props.printer.categories.includes(item.id)
      );
      setCategories(prev_categories);
    }

    if (props.printer?.name) {
      setName(props.printer.name);
    } else {
      setName('');
    }
  }, [props]);

  const asyncDispatch: ThunkDispatch<StoreRoot, any, AnyAction> = useDispatch();

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    // Call to Printer Update API Here
    const formData = new FormData();
    if (name) {
      formData.append('name', name);
    }

    props.updatePrinter(props.printer.id, formData);
    API.restaurantApi
      // @ts-ignore
      .updatePrinterCtgs(props.printer.id, categories)
      .then((res: any) => {
        /* TODO */
      })
      .catch((err: any) => {
        toast.error('Error updating printer categories!');
      });

    props.toggle && props.toggle();
  };

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    props.toggle && props.toggle();
  };

  const { printer } = props;

  return printer ? (
    <Modal isOpen={props.modal} toggle={props.toggle}>
      <ModalHeader toggle={props.toggle}>Printer #{printer.id}</ModalHeader>
      <form onSubmit={(e) => handleSubmit(e)}>
        <ModalBody>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              className="form-control"
              id="name"
              placeholder="Enter printer name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="name">Categories</label>
            <Select
              isMulti
              name="categories"
              options={props.categories}
              className="basic-multi-select"
              classNamePrefix="select"
              value={categories}
              onChange={(val: any) => setCategories(val)}
            />
            <small id="categoriesHelp" className="form-text text-muted">
              Select articles categories which will be printed
            </small>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button name="Close" btnLongWidth={false} onClick={handleCancel} type="button" />
          <button type="submit" className="btn btn-primary">
            Save
          </button>
        </ModalFooter>
      </form>
    </Modal>
  ) : null;
};
export default StarEditModal;
