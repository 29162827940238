import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader,
} from 'reactstrap';

import { API } from '../api';
import mapUrlToLabel from '../helpers/mapUrlToLabel';
import { Button } from './index';

const RestRightsModal: FC<any> = (props) => {
  const [allowedUrls, setAllowedUrls] = useState<any>([]);
  const restaurant = useSelector(({ restaurantData }: StoreRoot) => restaurantData);

  useEffect(() => {
    if (restaurant.length === 0) return;
    // @ts-ignore
    const restaurant_urls = restaurant.allowed_urls;

    if (!restaurant_urls) {
      setAllowedUrls([]);
      return;
    }

    if (!restaurant_urls?.length) return;
    if (restaurant_urls == null) return;
    const generic_allowed_urls = props.restaurant_allowed_urls;
    const checked_allowed_urls = generic_allowed_urls.filter((item: any) => {
      return restaurant_urls.includes(item.url_name);
    });

    setAllowedUrls(checked_allowed_urls);
  }, [props, restaurant]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    // Submit rest rights here
    // let restid = props.restaurant_allowed_urls[0].restaurant;
    const restid = localStorage.getItem('rest_id') || '';
    const new_allowed_urls = allowedUrls.map((item: any) => item.url_name);
    const data = {
      allowed_urls: new_allowed_urls,
    };
    await API.restaurantApi
      .updateRestaurantPanelRights(data, restid)
      .then((res) => {
        // Works only with this "B" id
        toast.success('Restaurant rights items updated successfully!', { containerId: 'B' });

        props.toggle();
      })
      .catch((err) => {
        toast.error('Error updating restaurant rights items', { containerId: 'B' });

        props.toggle();
      });
  };

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    props.toggle && props.toggle();
  };

  const handleDeselectAll = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setAllowedUrls([]);
  };
  const handleSelectAll = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setAllowedUrls(props.restaurant_allowed_urls);
  };

  const renderChecked = (id: any) => {
    if (restaurant?.length === 0) return false;
    if (!allowedUrls) return false;
    const current_item = allowedUrls.filter((item: any) => item.id === id)[0];

    if (current_item) return true;

    return false;
  };

  const handleCheckItem = (id: any) => {
    if (!props.restaurant_allowed_urls) return;
    const item_to_add = props.restaurant_allowed_urls.find((item: any) => item.id === id);
    const already_added = allowedUrls.find((item: any) => item.id === id);
    if (already_added) {
      const new_allowed = allowedUrls.filter((item: any) => item.id !== id);
      setAllowedUrls(new_allowed);
    } else {
      const new_allowed = [...allowedUrls, item_to_add];
      setAllowedUrls(new_allowed);
    }
  };

  const renderPanelUrls = () => {
    return props.restaurant_allowed_urls.map((item: any) => (
      <FormGroup check key={item.id}>
        <Label check>
          <Input
            style={{ opacity: 1, visibility: 'visible' }}
            type="checkbox"
            value={item.url_name}
            onChange={(e: any) => handleCheckItem(item.id)}
            checked={renderChecked(item.id)}
          />{' '}
          {mapUrlToLabel(item.url_name)}
        </Label>
      </FormGroup>
    ));
  };

  return (
    <Modal isOpen={props.modal} toggle={props.toggle}>
      <ModalHeader toggle={props.toggle}>Select urls</ModalHeader>
      <form onSubmit={(e) => handleSubmit(e)}>
        <ModalBody>
          <h4>Selecteer toegestane rechten</h4>
          {renderPanelUrls()}
        </ModalBody>
        <ModalFooter>
          <Button name="Check All" btnLongWidth={false} onClick={handleSelectAll} type="button" />
          <Button
            name="Uncheck All"
            btnLongWidth={false}
            onClick={handleDeselectAll}
            type="button"
          />
          <Button name="Cancel" btnLongWidth={false} onClick={handleCancel} type="button" />
          <Button name="Save" type="submit" dangerButton={true} btnLongWidth={false} />
        </ModalFooter>
      </form>
    </Modal>
  );
};
export default RestRightsModal;
