import PerfectScrollbar from 'perfect-scrollbar';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { RightsModal, RestRightsModal, Header, Sidebar } from '../../Components';
import {
  getRestaurantPanelUsers,
  getRestaurantAllowedUrls,
  getRestaurantData,
} from '../../store/actions';
import { API } from '../../api';
import DeleteModalComponent from '../../Components/DeleteModalComponent';

let ps: any;
const RightsManagement = () => {
  const [modal, setModal] = useState(false);
  const [modal_restaurant, setModalRestaurant] = useState(false);
  const [userObj, setUserObj] = useState<0 | 'new' | RestaurantUser>(0);
  const [userId, setUserId] = useState(0);
  const mainPanel = useRef<any>();
  const id = localStorage.getItem('rest_id') as string;
  const restaurantUsers = useSelector(({ restaurantUsers }: StoreRoot) => restaurantUsers);
  const restaurantAllowedUrls = useSelector(
    ({ restaurantAllowedUrls }: StoreRoot) => restaurantAllowedUrls
  );
  const restaurant = useSelector(({ restaurantData }: StoreRoot) => restaurantData);
  const dispatch = useDispatch();

  const [deleteModal, setDeleteModal] = useState(false);

  const toggle = (id: any, user: any) => {
    if (id?.preventDefault) {
      id.preventDefault();
    }

    setModal(!modal);
    setUserId(id);
    setUserObj(user);
  };

  const toggleRestaurantRightsModal = () => {
    setModalRestaurant(!modal_restaurant);
    dispatch(getRestaurantPanelUsers(id));
    dispatch(getRestaurantAllowedUrls(id));
    dispatch(getRestaurantData(id));
  };

  const deleteUser = async (user_id: string) => {
    await API.restaurantApi
      .removePanelUser(user_id)
      .then((res) => {
        toast.success('User successfully removed', { containerId: 'B' });

        dispatch(getRestaurantPanelUsers(id));
        dispatch(getRestaurantAllowedUrls(id));
      })
      .catch((err) => {
        toast.error('Error removing user', { containerId: 'B' });
      });
  };

  useEffect(() => {
    document.title = 'Rights Management';
    // @ts-ignore
    // document.getElementById('rightsmanagement_colorchange').style.color = 'darkred';

    dispatch(getRestaurantPanelUsers(id));
    dispatch(getRestaurantAllowedUrls(id));
    dispatch(getRestaurantData(id));
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(mainPanel.current);
      document.body.classList.toggle('perfect-scrollbar-on');
    }
    return () => {
      if (navigator.platform.indexOf('Win') > -1) {
        ps.destroy();
        document.body.classList.toggle('perfect-scrollbar-on');
      }
    };
  }, [modal, userId]);

  const handleToggleCreateUserModal = () => {
    // @ts-ignore
    const restaurant_urls = restaurant.allowed_urls;

    if (!restaurant_urls || restaurant_urls.length === 0) {
      toast.error('You need select restaurant rights items first!', { containerId: 'B' });
    } else {
      toggle('new', 'new');
    }
  };

  const toggleDeleteModal = (id: any, articleopt: any) => {
    setDeleteModal(!deleteModal);
    setUserId(id);
    setUserObj(articleopt);
  };

  return (
    <>
      <Sidebar />
      <div id="border-top-radius" className="container-fluid main-panel" ref={mainPanel}>
        <Header />

        <div className="row ml-lg-5 mt-5 pt-5">
          <div className="col-xl-10 offset-xl-1 col-lg-8 offset-lg-3 col-md-8 offset-md-4 col-sm-12 col-12 text-center">
            <span className="h4">Gebruikersbeheer</span>
            &nbsp;&nbsp;&nbsp;
            <button type="button" onClick={handleToggleCreateUserModal} className="btn btn-yellow ">
              + Voeg gebruiker toe
            </button>
            <div className="table-responsive mt-4">
              <table className="table table-striped table-bordered">
                <thead>
                  <tr className="d-flex justify-content-center">
                    {/* <th className='small'>Category ID</th> */}
                    <th className="small">Naam</th>
                    <th className="small">Actie</th>
                  </tr>
                </thead>
                <tbody>
                  {restaurantUsers.map((restUser: any) => {
                    return (
                      <tr
                        key={restUser.id}
                        className={
                          restUser.is_owner
                            ? 'table-success d-flex justify-content-center'
                            : 'd-flex justify-content-center'
                        }
                      >
                        <td>{restUser.user.username}</td>
                        {restUser.is_owner ? (
                          <td>
                            <i className="fa fa-star text-danger" aria-hidden="true" />
                          </td>
                        ) : (
                          <td>
                            <i
                              className="fa fa-pencil cursr"
                              aria-hidden="true"
                              id={restUser.id}
                              onClick={() => toggle(restUser.id, restUser)}
                            />
                            <i
                              className="fa fa-trash pl-2 cursr"
                              aria-hidden="true"
                              onClick={() => toggleDeleteModal(restUser.user.id, restUser)}
                              // onClick={() => deleteUser(restUser.user.id)}
                              id={restUser.id}
                            />
                          </td>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div className="btn-group" role="group" aria-label="Basic example">
                <button
                  type="button"
                  onClick={() => toggleRestaurantRightsModal()}
                  className="btn btn-sm btn-yellow ml-3 "
                >
                  Selecteer restaurantrechtenitems
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <RightsModal
        user={userObj}
        modal={modal}
        toggle={toggle}
        userId={userId}
        restaurant_allowed_urls={restaurantAllowedUrls}
      />
      <RestRightsModal
        modal={modal_restaurant}
        toggle={toggleRestaurantRightsModal}
        restaurant_allowed_urls={restaurantAllowedUrls}
      />
      <DeleteModalComponent
        articleopt={setUserObj}
        isOpen={deleteModal}
        toggle={toggleDeleteModal}
        articleoptId={userId}
        deleteFunction={deleteUser}
      />
    </>
  );
};

export default RightsManagement;
