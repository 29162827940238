import React, { FC, useState, useEffect } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { toast } from 'react-toastify';
import { Button, InputField } from '../index';
import { API } from '../../api';

// Modal to delegate Chain manager's restaurant owner rights to another user
// It is shown when Chain manager has owner rights for the restaurant zero
const DelegateOwnerModal: FC<any> = (props) => {
  const [curentUser, setCurrentUser] = useState<any>(null);

  const [username, setUsername] = useState<string>('');
  const [password1, setPassword1] = useState<string>('');
  const [password2, setPassword2] = useState<string>('');

  useEffect(() => {
    const not_parsed_user = localStorage.getItem('current_user');
    let current_user: any = null;
    if (not_parsed_user) {
      current_user = JSON.parse(not_parsed_user);
      console.log('current_user', current_user);
      setCurrentUser(current_user);
    }
  }, []);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!curentUser?.id) {
      toast.error('Your login data is incorrect. Please re-login', { containerId: 'B' });
      return;
    }

    if (password1 !== password2) {
      toast.error("Password don't match", { containerId: 'B' });
      return;
    }

    // Check if manager typed in his own email
    if (username === curentUser?.user?.username) {
      toast.error('Please, enter an email different from yours', { containerId: 'B' });
      return;
    }

    if (!username || !password1 || !password2) {
      toast.error('Please, fill in all the fields', { containerId: 'B' });
      return;
    }

    // Create Restaurant here
    const formData = new FormData();
    formData.append('username', username);
    formData.append('password', password1);

    API.restaurantApi
      .delegateRestOwnerRights(curentUser.user.id, formData)
      .then((res) => {
        toast.success('Restaurant rights delegated', { containerId: 'B' });
        const current_user_modified = { ...curentUser };
        delete current_user_modified.restaurant;
        current_user_modified.is_owner = false;
        localStorage.setItem('current_user', JSON.stringify(current_user_modified));
        window.location.reload();
        props.toggle();
      })
      .catch((err) => {
        toast.error('Error delegating rights', { containerId: 'B' });
        props.toggle();
      });
  };

  const clearFields = () => {
    setUsername('');
    setPassword1('');
    setPassword2('');
  };

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    clearFields();
    props.toggle && props.toggle();
  };

  return (
    <Modal isOpen={props.modal} toggle={handleCancel}>
      <ModalHeader toggle={props.toggle}>Delegate restaurant owner rights</ModalHeader>
      <form onSubmit={(e) => handleSubmit(e)}>
        <ModalBody>
          <div className="form-group text-left">
            <label htmlFor="" className="small">
              E-mail
            </label>
            <InputField
              required
              type="email"
              name="username"
              placeholder="email"
              value={username}
              onChange={(e: any) => setUsername(e.target.value)}
            />
          </div>

          <div className="form-group text-left">
            <label htmlFor="" className="small">
              Wachtwoord
            </label>
            <InputField
              required
              type="password"
              name="password1"
              placeholder="Wachtwoord"
              pattern=".{8,}"
              value={password1}
              onChange={(e: any) => setPassword1(e.target.value)}
            />
            <small id="password_help" className="form-text text-muted">
              minimum 8 characters
            </small>
          </div>

          <div className="form-group text-left">
            <label htmlFor="" className="small">
              Bevestig wachtwoord
            </label>
            <InputField
              required
              type="password"
              name="password2"
              placeholder="Bevestig wachtwoord"
              pattern=".{8,}"
              value={password2}
              onChange={(e: any) => setPassword2(e.target.value)}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button name="Cancel" btnLongWidth={false} onClick={handleCancel} type="button" />
          <Button name="Save" type="submit" dangerButton={true} btnLongWidth={false} />
        </ModalFooter>
      </form>
    </Modal>
  );
};
export default DelegateOwnerModal;
