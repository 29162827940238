import React, {
  FC, useEffect, useState,
} from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader,
} from 'reactstrap';
import { API } from '../api';
import { IRole } from '../api/DTO/role';
import mapUrlToLabel from '../helpers/mapUrlToLabel';
import { Button, InputField } from './index';

interface IRoleListProps {
  roles: IRole[];
  selectedRoles: number[];
  handleOnChange: (id: number) => () => void;
}

const RoleList: FC<IRoleListProps> = (props) => {
  const { roles, handleOnChange, selectedRoles } = props
  return (
    <div>
      <h4>Lijst met rollen</h4>
      {roles.map((role) => (
        <FormGroup key={role.id} check>
          <Label check>
            <Input
              style={{ opacity: 1, visibility: 'visible' }}
              type="checkbox"
              onChange={handleOnChange(role.id)}
              checked={!!selectedRoles.find((roleId) => roleId === role.id)}
            />
            {role.name}
          </Label>
        </FormGroup>
      ))}
    </div>
  )
}

interface IProps {
  modal: boolean;
  user: 0 | 'new' | RestaurantUser;
  userId: 'new' | number;
  toggle: (id?: number, user?: RestaurantUser) => void;
  restaurant_allowed_urls: RestaurantAllowedUrl[];
}

const RightsModal: FC<IProps> = (props) => {
  const { user, userId } = props
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [allowedUrls, setAllowedUrls] = useState<any>(null);
  const restaurant = useSelector(({ restaurantData }: StoreRoot) => restaurantData);

  const [roles, setRoles] = useState<IRole[]>([])
  const [selectedRoles, setSelectedRoles] = useState<number[]>([])

  const restId = localStorage.getItem('rest_id');

  useEffect(() => {
    if (user) {
      if (typeof user === 'number' || typeof user === 'string') {
        setUsername('');
        setPassword('');
        setAllowedUrls([]);
        return
      }

      setUsername(user.user.username);
      setSelectedRoles(user.roles)
      setAllowedUrls(user.allowed_urls);
    }
  }, [props]);

  useEffect(() => {
    let mounted = true;
    if (restId) {
      API.restaurantApi.getRolesByRestaurantId(+restId)
        .then((responseRoles) => {
          if (!mounted) return
          setRoles(responseRoles)
        })
        .catch(() => toast.error('Attempt to load roles failed', { containerId: 'B' }))
    }

    return () => {
      mounted = false
    }
  }, [restId])

  const handleSelectRole = (id: number) => () => {
    const roleIndex = selectedRoles.indexOf(id)
    if (roleIndex < 0) {
      setSelectedRoles([...selectedRoles, id])
      return
    }
    setSelectedRoles(selectedRoles.filter((selectedId) => selectedId !== id))
  }

  const handleCancel = () => {
    props.toggle && props.toggle();
    setSelectedRoles([])
    setAllowedUrls([])
    setUsername('')
    setPassword('')
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (userId === 'new') {
      const restId = localStorage.getItem('rest_id');
      if (!restId) return;
      const new_allowed_urls = allowedUrls.map((item: any) => item.url_name);
      const data = {
        username,
        password,
        allowed_urls: new_allowed_urls,
        roles: selectedRoles,
      };
      await API.restaurantApi
        .createPanelUser(data, restId)
        .then((res) => {
          toast.success('User Created Successfully!', { containerId: 'B' });
        })
        .catch((err) => {
          toast.error('Error Creating User', { containerId: 'B' });
        })
        .finally(() => handleCancel())
    } else {
      // Update user
      const new_allowed_urls = allowedUrls.map((item: any) => item.url_name);
      const data = {
        username,
        password,
        allowed_urls: new_allowed_urls,
        roles: selectedRoles,
      };
      await API.restaurantApi
        .updatePanelUser(data, `${userId}`)
        .then((res) => {
          // Works only with this "B" id
          toast.success('User Updated Successfully!', { containerId: 'B' });

          props.toggle();
        })
        .catch((err) => {
          toast.error('Error Updating User', { containerId: 'B' });

          props.toggle();
        });
    }
  };

  const handleDeselectAll = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setAllowedUrls([]);
  };
  const handleSelectAll = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    // @ts-ignore
    const restaurant_urls = restaurant.allowed_urls;
    const generic_allowed_urls = props.restaurant_allowed_urls;

    if (!restaurant_urls) return;
    const new_allowed = generic_allowed_urls.filter((item: any) => {
      const res = restaurant_urls.includes(item.url_name);
      return res;
    });

    setAllowedUrls(new_allowed);
  };

  const renderChecked = (id: any) => {
    if (!allowedUrls) return false;
    const current_item = allowedUrls.filter((item: any) => item.id === id)[0];

    if (current_item) return true;

    return false;
  };

  const handleCheckItem = (id: any) => {
    if (!allowedUrls || !props.restaurant_allowed_urls) return;
    const item_to_add = props.restaurant_allowed_urls.find((item: any) => item.id === id);
    const already_added = allowedUrls.find((item: any) => item.id === id);
    if (already_added) {
      const new_allowed = allowedUrls.filter((item: any) => item.id !== id);
      setAllowedUrls(new_allowed);
    } else {
      const new_allowed = [...allowedUrls, item_to_add];
      setAllowedUrls(new_allowed);
    }
  };

  const renderPanelUrls = () => {
    if (restaurant.length === 0) return;
    // @ts-ignore
    const restaurant_urls = restaurant.allowed_urls;
    if (restaurant_urls?.length === 0 || restaurant_urls === null) return;
    return props.restaurant_allowed_urls.map((item: any) => (restaurant_urls.includes(item.url_name) ? (
      <FormGroup check key={item.id}>
        <Label check>
          <Input
            style={{ opacity: 1, visibility: 'visible' }}
            type="checkbox"
            value={item.url_name}
            onChange={(e: any) => handleCheckItem(item.id)}
            checked={renderChecked(item.id)}
          />{' '}
          {mapUrlToLabel(item.url_name)}
        </Label>
      </FormGroup>
    ) : null));
  };

  return (
    <Modal isOpen={props.modal} toggle={handleCancel}>
      <ModalHeader toggle={handleCancel}>
        {props.user === 'new' ? 'Create User' : 'Edit User'}
      </ModalHeader>
      <form onSubmit={(e) => handleSubmit(e)}>
        <ModalBody>
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group text-left">
                <label htmlFor="username" className="small">
                  Naam
                </label>
                <InputField
                  type="email"
                  name="username"
                  placeholder="e-mail"
                  value={username}
                  onChange={(e: any) => {
                    setUsername(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group text-left">
                <label htmlFor="password" className="small">
                  Wachtwoord
                </label>
                <InputField
                  type="password"
                  name="password"
                  placeholder="wachtwoord"
                  value={password}
                  onChange={(e: any) => {
                    setPassword(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
          <RoleList
            handleOnChange={handleSelectRole}
            selectedRoles={selectedRoles}
            roles={roles}
          />
          <h4>Selecteer toegestane rechten</h4>
          {renderPanelUrls()}
        </ModalBody>
        <ModalFooter>
          <Button name="Check All" btnLongWidth={false} onClick={handleSelectAll} type="button" />
          <Button
            name="Uncheck All"
            btnLongWidth={false}
            onClick={handleDeselectAll}
            type="button"
          />
          <Button name="Cancel" btnLongWidth={false} onClick={handleCancel} type="button" />
          <Button
            name={props.userId === 'new' ? 'Create' : 'Save'}
            type="submit"
            dangerButton={true}
            btnLongWidth={false}
            // onClick={props.toggle}
          />
        </ModalFooter>
      </form>
    </Modal>
  );
};
export default RightsModal;
