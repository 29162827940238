import React, { useState, useEffect } from 'react'
import {
  Modal, ModalBody, ModalHeader, ModalFooter,
} from 'reactstrap';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { ITablesLocation } from '../../api/DTO/tablesLocation';
import { ITablesObject } from '.';

import { API } from '../../api';
import Button from '../../Components/Button';
import { ITable } from '../../api/DTO/table';

type Props = {
  tablesLocations: ITablesLocation[];
  tables: ITablesObject;
  tableId: number;
  handleUpdatePage: () => void;
  moveTableModalIsOpen: boolean;
  handleCloseModal: () => void;
  locationId: number;
}

type TOptionType = {
  value: number | string; label: string; }

export const MoveTableModal = (props: Props) => {
  const {
    tableId, handleUpdatePage,
    moveTableModalIsOpen, tables, tablesLocations, handleCloseModal,
    locationId,
  } = props

  const options = tablesLocations.map((location) => {
    return { label: location.name === 'default_empty' ? 'Tafels zonder locatie' : location.name, value: location.id }
  })

  const [formLocationOption, setFormLocationOption] = useState<TOptionType>({ value: '', label: '' })
  const [location, setLocation] = useState<ITablesLocation>({ id: -1, name: 'Empty', restaurant: -1 })
  const [tableObject, setTableObject] = useState<ITable>({ id: tableId, name: '', location: locationId })

  useEffect(() => {
    let mounted = true
    const l = tablesLocations.find((location) => location.id === locationId)
    if (!l) return
    setFormLocationOption({ value: l.id, label: l.name === 'default_empty' ? 'Tafels zonder locatie' : l.name })
    setLocation(l)
    const table = tables[l.id].find((table) => table.id === tableId)
    table && setTableObject(table)
    return () => {
      mounted = false
    }
  }, [tableId])

  const handleChange: any = (newOption: TOptionType) => {
    setFormLocationOption(newOption)
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const tableObject = tables[location.id].find((table) => table.id === tableId)
    if (!tableObject) {
      throw new Error('Table object not found!')
    }
    const { id, ...tableObjectWithoutID } = tableObject
    API.restaurantApi.putTable(tableId, { ...tableObjectWithoutID, location: +formLocationOption.value })
      .then((response) => {
        handleUpdatePage()
        handleCloseModal()
        toast.success('De tabel is succesvol bijgewerkt', { containerId: 'B' })
      })
  }
  return !tableId || !locationId ? (
    <Modal isOpen={moveTableModalIsOpen} toggle={handleCloseModal} />
  ) : (
    <Modal isOpen={moveTableModalIsOpen} toggle={handleCloseModal}>
      <ModalHeader toggle={handleCloseModal}>
        De tafel verplaatsen
      </ModalHeader>
      <form onSubmit={handleSubmit}>
        <ModalBody>
          <>
            <div className="row">
              <div className="col-sm-12">
                <div className="form-group text-left">
                  <label htmlFor="locations" className="small">
                    <b>Tafelnummer {tableObject.name}</b>.Verplaatsen van locatie {location.name}  naar locatie ...
                  </label>
                  <Select
                    name="locations"
                    required
                    options={options}
                    value={formLocationOption}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
          </>
        </ModalBody>
        <ModalFooter>
          <Button name="Cancel" btnLongWidth={false} onClick={handleCloseModal} type="button" />
          <Button
            name="Save"
            type="submit"
            dangerButton={true}
            btnLongWidth={false}
          />
        </ModalFooter>
      </form>
    </Modal>
  )
}
