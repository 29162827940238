import { toast } from 'react-toastify';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InputField, Button, Header, Sidebar } from '../../Components';
import { API_BASE_URL } from '../../services/urls';
import { API } from '../../api';
import PerfectScrollbar from 'perfect-scrollbar';

import './style.css';

const { updateRestaurantData, partiallyUpdateRestaurantData } = API.restaurantApi;
import { getRestaurantData } from '../../store/actions';
import VisitUrl from '../../Components/VisitUrl/VisitUrl';

let ps: any;

const OrderConfirmationView: FC = () => {
  const mainPanel = useRef<any>();
  const restid = localStorage.getItem('rest_id') as string;

  const dispatch = useDispatch();
  const restaurant = useSelector(({ restaurantData }: any) => restaurantData);

  const [chosenLogo, setChosenLogo] = useState<any>(null);
  const [successLogo, setSuccessLogo] = useState<any>('');

  const [successTitle, setSuccessTitle] = useState<any>('');
  const [successText, setSuccessText] = useState<any>('');
  const [successSign, setSuccessSign] = useState<any>('');

  useEffect(() => {
    document.title = 'Final Screen';
    // @ts-ignore
    // document.getElementById('finalscreen_colorchange').style.color = 'darkred';
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(mainPanel.current);
      document.body.classList.toggle('perfect-scrollbar-on');
    }
    return () => {
      if (navigator.platform.indexOf('Win') > -1) {
        ps.destroy();
        document.body.classList.toggle('perfect-scrollbar-on');
      }
    };
  }, []);

  useEffect(() => {
    // Set input fields values
    restaurant?.final_screen_logo && setSuccessLogo(restaurant?.final_screen_logo);
    restaurant?.final_screen_title && setSuccessTitle(restaurant?.final_screen_title);
    restaurant?.final_screen_text && setSuccessText(restaurant?.final_screen_text);
    restaurant?.final_screen_signature && setSuccessSign(restaurant?.final_screen_signature);
  }, [restaurant]);

  useEffect(() => {
    const res_id = localStorage.getItem('rest_id') as string;
    dispatch(getRestaurantData(res_id));
  }, []);

  const handleLogoChange = (e: any) => {
    setChosenLogo(URL.createObjectURL(e.target.files[0]));
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    // Add/Update Logo or Text
    const title = e.target.elements.title.value;
    // All \n are saved in text variable and can be restored
    const text = e.target.elements.text.value;
    const signature = e.target.elements.team.value;
    let logo = null;
    if (e.target.elements.logo.files[0]) {
      logo = e.target.elements.logo.files[0];
    }

    const formData = new FormData();

    formData.append('pk', restid);
    if (logo) {
      formData.append('final_screen_logo', logo);
    }
    formData.append('final_screen_title', title);
    formData.append('final_screen_text', text);
    formData.append('final_screen_signature', signature);

    await API.restaurantApi
      .partiallyUpdateRestaurantData(formData, restid)
      .then((res) => {
        toast.success('Updated Successfully!', { containerId: 'B' });
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((err) => {
        window.location.reload();
        console.log('err', err);
      });
  };

  const handleTextLineBreak = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter') {
      e.stopPropagation();
    }
  };

  const handleRemoveLogo = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('pk', restid);
    formData.append('final_screen_logo', '');
    await API.restaurantApi
      .partiallyUpdateRestaurantData(formData, restid)
      .then((res) => {
        toast.success('Updated Successfully!', { containerId: 'B' });
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((err) => {
        window.location.reload();
        console.log('err', err);
      });
  };

  const renderLogoImage = () => {
    if (chosenLogo) {
      return <img className="img-thumbnail mb-3" src={chosenLogo} alt="" />;
    } else if (successLogo) {
      return <img className="img-thumbnail mb-3" src={successLogo} alt="" />;
    } else {
      return (
        <div>
          <h5>No logo is set</h5>
          <img
            className="restaurant_images"
            id="blah"
            src={
              chosenLogo
                ? chosenLogo
                : 'https://res.cloudinary.com/updivision/image/upload/v1/media/Storage/file-upload-image_cmfw18'
            }
            alt="logo image"
          />
        </div>
      );
    }
  };

  return (
    <>
      <Sidebar />
      <div id="border-top-radius" className="container-fluid main-panel" ref={mainPanel}>
        <Header />
        <div className="row mr-md-5 mt-5 pt-5">
          <div className="col-xl-10 offset-xl-1 col-lg-9 offset-lg-3 col-md-8 offset-md-4 col-sm-12 col-12 offsetMd5 text-center">
            <span className="h4 mb-3">Eindscherm</span>
          </div>
          <div className="order-confirmation-holder col-xl-10 offset-xl-1 col-lg-8 offset-lg-4 col-md-8 offset-md-4 col-sm-12 col-12 mt-3 offsetMd5">
            <form onSubmit={(e) => handleSubmit(e)}>
              <div className="image-upload-holder order-confirmation-img-upload d-flex flex-wrap position-relative">
                <div className="image-upload">
                  <h6 className="mt-3 mr-2">Logo:</h6>

                  <label htmlFor="logo">{renderLogoImage()}</label>

                  <input
                    onChange={handleLogoChange}
                    id="logo"
                    name="logo"
                    type="file"
                    className="form-control"
                  />
                </div>
                <div className="text-left my-4 position-absolute remove-img-btn">
                  <button
                    type="button"
                    onClick={(e) => handleRemoveLogo(e)}
                    name="remove_logo"
                    className="btn text-danger"
                  >
                    verwijderen
                  </button>
                </div>
              </div>
              <label htmlFor="title" className="control-label font-weight-bold m-0 mb-2 mt-3">
                Titel:
              </label>
              <input
                name="title"
                className="form-control"
                type="input"
                id="title"
                placeholder="Bv. Bedankt voor jouw bestelling!"
                value={successTitle}
                onChange={(e) => setSuccessTitle(e.target.value)}
              />
              <label htmlFor="text" className="control-label font-weight-bold m-0 mb-2 mt-3">
                Tekst:
              </label>
              <textarea
                className="form-control mt-1"
                rows={6}
                name="text"
                id="text"
                placeholder="Bv. Wij gaan meteen aan de slag met jouw bestelling."
                value={successText}
                onChange={(e) => setSuccessText(e.target.value)}
                onKeyPress={(e) => handleTextLineBreak(e)}
              />
              <label htmlFor="team" className="control-label font-weight-bold m-0 mb-2 mt-3">
                Voettekst:
              </label>
              <input
                name="team"
                className="form-control"
                type="input"
                id="team"
                placeholder="Bv. Team Bodega"
                value={successSign}
                onChange={(e) => setSuccessSign(e.target.value)}
              />
              <VisitUrl />
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderConfirmationView;
