import moment from 'moment';
import DatePicker from 'react-datepicker';
import { CSVLink } from 'react-csv';
import PerfectScrollbar from 'perfect-scrollbar';
import React, { FC, useEffect, useRef, useState } from 'react';
import { Header } from '../../Components';
import { API } from '../../api';
import { toast } from 'react-toastify';
import 'react-datepicker/dist/react-datepicker.css';
import Loader from '../../Components/Loader';
import Select from 'react-select';
import { useDispatch } from 'react-redux';
import './style.css';
import checkOrderContainsCtgs from '../../helpers/checkOrderContainsCtgs';
import checkOrderContainsDeliveryTypes from '../../helpers/checkOrderContainsDeliveryTypes';
import checkOrderContainsProducts from '../../helpers/checkOrderContainsProducts';
import calcOrdersRevenue from '../../helpers/calcOrdersRevenue';
import ChainSidebar from '../../Components/ChainComponents/ChainSidebar';
import getChainRestaurants from '../../Components/ChainComponents/helpers/getChainRestaurants';
import getChain from '../../Components/ChainComponents/helpers/getChain';
import checkOrderFromRestaurants from '../../Components/ChainComponents/helpers/checkOrderFromRestaurant';
import getChainArticles from '../../Components/ChainComponents/helpers/getChainArticles';
import getChainCtgs from '../../Components/ChainComponents/helpers/getChainCtgs';
import ChainOrdersOverviewRow from '../../Components/ChainComponents/ChainOrdersOverviewRow';
import ChainOrderModal from '../../Components/ChainComponents/ChainOrderModal';
import squashSelectOptions from '../../helpers/squashSelectOptions';

let ps: any;

const orderStatusSelectOpt = [
  { value: 'All', label: 'Alle' },
  { value: 'Accepted', label: 'Accepted' },
  { value: 'Rejected', label: 'Rejected' },
  { value: 'Pending', label: 'Pending' },
];

const deliveryTypeSelectOpt = [
  { value: 'Delivery', label: 'Delivery' },
  { value: 'Pickup', label: 'Pickup' },
  { value: 'Qr', label: 'Qr' },
];

function ChainOrdersOverview() {
  const not_parsed_chain = localStorage.getItem('chain');
  const chain = not_parsed_chain ? JSON.parse(not_parsed_chain) : null;

  const [modal, setModal] = useState(false);
  const [OrderData, setOrderData] = useState([]);
  const [orderId, setOrderId] = useState(0);
  const [orderRestId, setOrderRestId] = useState<any>(null);
  const [Articleobj, setArticleobj] = useState(0);
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const headers = [
    { label: 'OrderID', key: 'OrderID' },
    { label: 'Statusbestelling', key: 'Statusbestelling' },
    { label: 'Betalingstatus', key: 'Betalingstatus' },
    { label: 'Tafelnummer', key: 'Tafelnummer' },
    { label: 'OpmerkingKlant', key: 'Opmerkingklant' },
    { label: 'NaamKlant', key: 'NaamKlant' },
    { label: 'E_mail', key: 'E_mail' },
    { label: 'GSM', key: 'GSM' },
    { label: 'Adres', key: 'Adres' },
    { label: 'Bedrag', key: 'Bedrag' },
    { label: 'Voorwanneer', key: 'Voorwanneer' },
  ];
  const [data, setCsvData] = useState<any>([]);
  const [is_loading, setIsLoading] = useState<boolean>(false);
  const [filterStatus, setFilterStatus] = useState<{ value: string; label: string } | null>(
    orderStatusSelectOpt[0]
  );
  const [deliveryType, setDeliveryType] = useState<any>(null);
  const [optionArticles, setOptionArticles] = useState<any>([]);
  const [selectedArticles, setSelectedArticles] = useState<any>(null);
  const [selectedCategories, setSelectedCategories] = useState<any>(null);

  const [optionRestaurants, setOptionRestaurants] = useState<any>(null);
  const [selectedRestaurants, setSelectedRestaurants] = useState<any>(null);
  const [chainRestaurants, setChainRestaurants] = useState<any[]>([]);
  const [chainLoaded, setChainLoaded] = useState<any>(null);
  const [chainArticles, setChainArticles] = useState<any>(null);
  const [chainCategories, setChainCategories] = useState<any>(null);
  const [categoriesOptions, setCategoriesOptions] = useState<any>(null);

  const dispatch = useDispatch();
  const [isAutoAcceptOrders, setAutoAcceptOrders] = useState<boolean>(false);

  const mainPanel = useRef<any>();
  const id = localStorage.getItem('rest_id') as string;

  // Pagination and Info
  const [nextPage, setNextPage] = useState<any>(null);
  const [prevPage, setPrevPage] = useState<any>(null);
  const [num_pages, setNumPages] = useState<any>(null);
  const [countOrders, setCountOrders] = useState<any>(null);
  const [totalRevenue, setTotalRevenue] = useState<any>(null);
  const [pageSelected, setPageSelected] = useState<any>(1);
  const [filteredCount, setFilteredCount] = useState<number | string>(0);
  const [filteredRevenue, setFilteredRevenue] = useState<number | string>(0);
  const [filteredData, setFilteredData] = useState<any>(null);

  // Reset button refresh
  const [refreshFlag, setRefreshFlag] = useState<boolean>(false);

  // Table rows loading
  const showRowsStep = 25;
  const initialShowingRows = 25;
  const [showingRows, setShowingRows] = useState<number>(initialShowingRows);

  useEffect(() => {
    document.title = 'Orders overview';
    const sidebar_link = document.getElementById('chain_orders_overview_colorchange');
    if (sidebar_link) {
      sidebar_link.style.color = 'darkred';
    }

    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(mainPanel.current);
      document.body.classList.toggle('perfect-scrollbar-on');
    }
    return () => {
      if (navigator.platform.indexOf('Win') > -1) {
        ps.destroy();
        document.body.classList.toggle('perfect-scrollbar-on');
      }
    };
  }, [modal, id]);

  useEffect(() => {
    if (chainArticles) {
      const not_assembled = chainArticles.filter((item: any) => !item.is_assembled);
      const options_articles = not_assembled.map((item: any) => ({
        id: item.id,
        price: item.price,
        label: item.articlename,
        value: item.id,
        descrition: item.description,
        image: item?.image?.image || '',
        count: 1,
      }));
      const squashedArtOptions = squashSelectOptions(options_articles);
      setOptionArticles(squashedArtOptions);
    }
  }, [chainArticles]);

  useEffect(() => {
    if (chainCategories) {
      const netCtgs = chainCategories.map((netCtg: any) => ({
        id: netCtg.id,
        value: netCtg.id,
        label: netCtg.name,
      }));
      const squashedCtgsOpts = squashSelectOptions(netCtgs);
      setCategoriesOptions(squashedCtgsOpts);
    }
  }, [chainCategories]);

  const getAllOrdersList = async (id: string) => {
    let sdate = '';
    let edate = '';
    if (startDate) sdate = moment(startDate.getTime()).format('YYYY-MM-DD HH:mm');
    if (endDate) edate = moment(endDate.getTime()).format('YYYY-MM-DD HH:mm');

    setIsLoading(true);

    if (!chain?.id) return;

    await API.restaurantApi
      .getAllOrdersListChain(chain.id, sdate, edate)
      .then((res: any) => {
        setCountOrders(res?.length);
        setTotalRevenue(res?.total_price);
        setPrevPage(res?.previous);
        setNextPage(res?.next);
        setNumPages(res?.num_pages);
        setPageSelected(res?.page);

        setIsLoading(false);
        const temCSVData = res.map((d: any) => ({
          OrderID: `${d.order_number}`,
          Statusbestelling: String(d.order_status),
          Betalingstatus: String(d.payment_status),
          Tafelnummer: d.tableid === -1 ? '' : `${d.tableid}`,
          OpmerkingKlant: d.user_note,
          NaamKlant: d.customer_name,
          E_mail: d.email,
          GSM: d.phoneNumber,
          Adres: d.address,
          Bedrag: d.total_amount,
          Voorwanneer:
            d.delivery_time && d.tableid === -1
              ? moment(d.delivery_time.split('+')[0]).format('MMMM, DD YYYY HH:mm')
              : '',
        }));

        setCsvData(temCSVData);
        setOrderData(res);
      })
      .catch((err) => console.log('Error is', err.message));
  };

  useEffect(() => {
    getChainRestaurants(chain?.id, setChainRestaurants);
    getChain(chain?.id, setChainLoaded);
    getChainArticles(chain?.id, setChainArticles);
    getChainCtgs(chain?.id, setChainCategories);
  }, [chainLoaded?.id]);

  useEffect(() => {
    const restOptions = chainRestaurants.map((nRest: any) => ({
      id: nRest.id,
      value: nRest.id,
      label: nRest.RestaurantName,
    }));
    setOptionRestaurants(restOptions);
  }, [chainRestaurants]);

  const getFilterdata = async (pageNum: any) => {
    const rest_id = localStorage.getItem('rest_id') as string;
    let sdate = '';
    let edate = '';
    if (startDate) sdate = moment(startDate.getTime()).format('YYYY-MM-DD HH:mm');
    if (endDate) edate = moment(endDate.getTime()).format('YYYY-MM-DD HH:mm');

    if (!chain?.id) return;

    setIsLoading(true);
    setShowingRows(initialShowingRows);
    await API.restaurantApi
      .getAllOrdersListChain(chain.id, sdate, edate)
      .then((res: any) => {
        setCountOrders(res?.length);
        setTotalRevenue(res?.total_price);
        setPrevPage(res?.previous);
        setNextPage(res?.next);
        setNumPages(res?.num_pages);
        setPageSelected(res?.page);

        const temCSVData = res.map((d: any) => ({
          OrderID: `${d.order_number}`,
          Statusbestelling: String(d.order_status),
          Betalingstatus: String(d.payment_status),
          Tafelnummer: d.tableid === -1 ? '' : `${d.tableid}`,
          OpmerkingKlant: d.user_note,
          NaamKlant: d.customer_name,
          E_mail: d.email,
          GSM: d.phoneNumber,
          Adres: d.address,
          Bedrag: d.total_amount,
          Voorwanneer:
            d.delivery_time && d.tableid === -1
              ? moment(d.delivery_time.split('+')[0]).format('MMMM, DD YYYY HH:mm')
              : '',
        }));
        setCsvData(temCSVData);
        setOrderData(res);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log('Error is', err.message);
        setIsLoading(false);
      });
  };

  const toggle = (id: any, restid: any) => {
    setModal(!modal);
    setOrderId(id);
    setOrderRestId(restid);
  };

  const closeModal = (e: any) => {
    e.preventDefault();
    setModal(!modal);
  };

  const onChangeStatus = async (e: React.ChangeEvent<HTMLSelectElement>, order_number: any) => {
    const new_status = e.target.value;
    const formData = new FormData();
    formData.append('order_status', new_status);

    await API.restaurantApi
      .updateOrderStatus(String(order_number), formData)
      .then(() => {
        toast.success('Updated Successfully!', { containerId: 'B' });
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch(() => {
        window.location.reload();
      });
  };

  const renderStatus = (currentStatus: string, order_number: any) => {
    if (currentStatus === 'Pending') {
      return (
        <select name="pets" id="pet-select" onChange={(e) => onChangeStatus(e, order_number)}>
          <option value="Pending" defaultChecked>
            {currentStatus}
          </option>
          <option value="Accepted">Accepted</option>
          <option value="Rejected">Rejected</option>
        </select>
      );
    } else {
      return currentStatus;
    }
  };

  // Autorefresh Orders List each 7 seconds
  useEffect(() => {
    const refres_interval = setInterval(() => {
      const rest_id = localStorage.getItem('rest_id') as string;
      getAllOrdersList(rest_id);
    }, 7000);
    return () => {
      clearInterval(refres_interval);
    };
  }, [getFilterdata]);

  const handleFilterStatus = (val: { value: string; label: string } | null) => {
    setFilterStatus(val);
  };

  const handleDeliveryType = (value: any, actionMeta: any | null) => {
    setDeliveryType(value);
  };

  const handleFilterCategories = (value: any, actionMeta: any) => {
    setSelectedCategories(value);
  };

  const handleSelectArticles = (val: any) => {
    setSelectedArticles(val);
  };

  const handleRestaurantChange = (val: any) => {
    setSelectedRestaurants(val);
  };

  useEffect(() => {
    const filtered = getFilteredData();
    // Calculate count and revenue
    const rev = calcOrdersRevenue(filtered);
    setFilteredRevenue(Number(rev).toFixed(2));
    setFilteredCount(filtered?.length || 0);
    setFilteredData(filtered);

    // Set CSV data from filtered instead of all orders
    const temCSVData = filtered.map((d: any) => ({
      OrderID: `${d.order_number}`,
      Statusbestelling: String(d.order_status),
      Betalingstatus: String(d.payment_status),
      Tafelnummer: d.tableid === -1 ? '' : `${d.tableid}`,
      OpmerkingKlant: d.user_note,
      NaamKlant: d.customer_name,
      E_mail: d.email,
      GSM: d.phoneNumber,
      Adres: d.address,
      Bedrag: d.total_amount,
      Voorwanneer:
        d.delivery_time && d.tableid === -1
          ? moment(d.delivery_time.split('+')[0]).format('MMMM, DD YYYY HH:mm')
          : '',
    }));
    setCsvData(temCSVData);

    setRefreshFlag(false);
  }, [
    OrderData,
    filterStatus,
    deliveryType,
    selectedArticles,
    selectedCategories,
    showingRows,
    selectedRestaurants,
  ]);

  useEffect(() => {
    getFilterdata(1);
  }, [refreshFlag]);

  const getFilteredData = () => {
    let filtered: any[] = OrderData || [];
    if (filterStatus?.value !== 'All') {
      filtered = OrderData.filter((item: any) => item.order_status === filterStatus?.value);
    }
    if (selectedCategories && selectedCategories?.length > 0) {
      filtered = filtered.filter((item: any) => checkOrderContainsCtgs(selectedCategories, item));
    }

    if (deliveryType && deliveryType?.length > 0) {
      filtered = filtered.filter((item: any) =>
        checkOrderContainsDeliveryTypes(deliveryType, item)
      );
    }
    if (selectedArticles && selectedArticles?.length > 0) {
      filtered = filtered.filter((item: any) => checkOrderContainsProducts(selectedArticles, item));
    }
    if (selectedRestaurants && selectedRestaurants?.length > 0) {
      filtered = filtered.filter((item: any) =>
        checkOrderFromRestaurants(selectedRestaurants, item)
      );
    }

    return filtered || [];
  };

  const handlePrevPage = (e: any) => {
    if (prevPage) {
      getFilterdata(prevPage);
    }
  };

  const handleNextPage = (e: any) => {
    if (nextPage) {
      getFilterdata(nextPage);
    }
  };

  const handleSetPage = (e: any, idx: any) => {
    setPageSelected(idx);
    getFilterdata(idx);
  };

  const handleReset = (e: any) => {
    setShowingRows(initialShowingRows);
    setStartDate(null);
    setEndDate(null);
    setFilterStatus(orderStatusSelectOpt[0]);
    setDeliveryType(null);
    setSelectedArticles(null);
    setSelectedCategories(null);
    setSelectedRestaurants(null);
    setRefreshFlag(true);
  };

  return (
    <>
      <ChainSidebar />
      <div
        id="border-top-radius"
        className="container-fluid main-panel min-vh-100 d-flex flex-column"
        ref={mainPanel}
      >
        <Header />
        <div className="row mt-5 pt-5 h-100 overview-row">
          <div className="col-xl-10 offset-xl-1 col-lg-8 offset-lg-3 col-md-8 offset-md-4 col-sm-12 col-12 text-center">
            <div className="table-responsive h-100">
              <span className="h4 mb-3"> Overzicht bestellingen</span>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <div className="overview-filters">
                <div className="overview-filters-left">
                  <div className="row overview-dates">
                    <div className="col-6 overview-filter-item">
                      <label htmlFor="sel1" className="control-label font-weight-bold">
                        Startdatum
                      </label>
                      <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        required
                        className="form-control"
                        dateFormat="dd/MM/yyyy HH:mm"
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        isClearable={true}
                      />
                    </div>
                    <div className="col-6 overview-filter-item">
                      <label htmlFor="sel1" className="control-label font-weight-bold">
                        Einddatum
                      </label>
                      <DatePicker
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        required
                        className="form-control"
                        dateFormat="dd/MM/yyyy HH:mm"
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        isClearable={true}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-6 overview-filter-item">
                      <label htmlFor="sel1" className="control-label font-weight-bold">
                        Status
                      </label>
                      <Select
                        className="overview-type-select"
                        onChange={handleFilterStatus}
                        options={orderStatusSelectOpt}
                        value={filterStatus}
                      />
                    </div>

                    <div className="col-6 overview-filter-item">
                      <label htmlFor="sel1" className="control-label font-weight-bold">
                        Methode
                      </label>
                      <Select
                        className="overview-type-select"
                        isMulti
                        onChange={handleDeliveryType}
                        options={deliveryTypeSelectOpt}
                        value={deliveryType}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-6 overview-filter-item">
                      <label htmlFor="sel1" className="control-label font-weight-bold">
                        Categorieën
                      </label>
                      <Select
                        isDisabled={selectedArticles?.length > 0}
                        className="overview-type-select"
                        isMulti
                        onChange={handleFilterCategories}
                        options={categoriesOptions}
                        value={selectedCategories}
                      />
                    </div>

                    <div className="col-6 overview-filter-item">
                      <label htmlFor="sel1" className="control-label font-weight-bold">
                        Products
                      </label>
                      <Select
                        isDisabled={selectedCategories?.length > 0}
                        name="add_products_select"
                        className="overview-type-select"
                        isMulti
                        options={optionArticles}
                        value={selectedArticles}
                        onChange={handleSelectArticles}
                        styles={{
                          menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
                          menu: (provided) => ({ ...provided, zIndex: 9999 }),
                        }}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 overview-filter-item">
                      <label htmlFor="rest" className="control-label font-weight-bold">
                        Restaurant
                      </label>
                      <Select
                        className="overview-type-select"
                        isMulti
                        onChange={handleRestaurantChange}
                        options={optionRestaurants}
                        value={selectedRestaurants}
                      />
                    </div>
                  </div>
                </div>

                <div className="overview-filters-right">
                  <div className="btn-group-vertical">
                    <button
                      type="button"
                      className="btn btn-success shadow-none"
                      onClick={(e) => getFilterdata(1)}
                    >
                      Filter
                    </button>

                    <button
                      onClick={handleReset}
                      type="button"
                      className="btn btn-danger shadow-none"
                    >
                      Reset
                    </button>

                    <CSVLink
                      type="button"
                      data={data}
                      headers={headers}
                      target="_blank"
                      className="btn btn-success"
                      filename={`orderdata_${new Date()}.csv`}
                    >
                      Download CSV
                    </CSVLink>
                  </div>
                </div>
              </div>
              <div className="overview-totals">
                <div>
                  <b>Aantal bestellingen:</b> {filteredCount}
                </div>
                <div>
                  <b>Omzet</b> €{filteredRevenue}
                </div>
              </div>
              {is_loading && <Loader />}
              <table className="table table-striped table-bordered mt-3">
                <thead>
                  <tr>
                    <th className="small">Order ID</th>
                    <th className="small">Status bestelling</th>
                    <th className="small">Betalingstatus</th>
                    <th className="small">Tafelnummer</th>
                    <th className="small">Opmerking klant</th>
                    <th className="small">Naam klant</th>
                    <th className="small">E-mail</th>
                    <th className="small">GSM</th>
                    <th className="small">Adres</th>
                    <th className="small">Bedrag</th>
                    <th className="small">Voorwanneer </th>
                    <th className="small">Bestelling</th>
                  </tr>
                </thead>
                <tbody>
                  {OrderData &&
                    filteredData
                      ?.filter((order: any, index: any) => {
                        if (index < showingRows) return true;
                        return false;
                      })
                      ?.map((order: any, index: any) => {
                        return (
                          <ChainOrdersOverviewRow
                            key={order.order_number}
                            order={order}
                            toggle={toggle}
                          />
                        );
                      })}
                  <tr style={{ height: '2rem' }}></tr>
                </tbody>
              </table>
              {countOrders > initialShowingRows ? (
                <>
                  <button
                    disabled={showingRows === countOrders}
                    className="btn btn-success rounded-0 mb-5 px-5"
                    onClick={(e) =>
                      showingRows + showRowsStep < countOrders
                        ? setShowingRows(showingRows + showRowsStep)
                        : setShowingRows(countOrders)
                    }
                  >
                    Show more
                  </button>
                  <div>
                    Showing {showingRows}/{countOrders}
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <ChainOrderModal
        modal={modal}
        setModal={setModal}
        toggle={toggle}
        orderId={orderId}
        restId={orderRestId}
      />
    </>
  );
}

export default ChainOrdersOverview;
