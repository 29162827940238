import axios from 'axios';
import PerfectScrollbar from 'perfect-scrollbar';
import React, { FC, useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Nav } from 'reactstrap';
import easyservedLogo from '../assets/images/logo.png';
import line from '../assets/images/line.png';
import { API_BASE_URL, MAIN_APP_URL } from '../services/urls';
import './style.css';
import { getRestaurantData } from '../store/actions';
import { useSelector } from 'react-redux';

let ps: any;

const Sidebar: FC<any> = (props) => {
  const [openOptions, setOpenOptions] = useState(false);
  const rest_id = localStorage.getItem('rest_id');

  const [restaurantData, setRestaurantData] = useState<any>([]);

  const sidebar = useRef<any>();

  const restaurant = useSelector(({ restaurantData }: StoreRoot) => restaurantData);

  // const [expandUserOptions, setExpandUserOptions] = useState(false);
  // const [expandProductOptions, setExpandProductOptions] = useState(false);
  // const [expandOrderOptions, setExpandOrderOptions] = useState(false);
  // const [expandSettings, setExpandSettings] = useState(false);

  const [expandOptions, setExpandOptions] = useState({
    user: false,
    product: false,
    order: false,
    settings: false,
    restaurant: false,
  });

  // const handleExpandState = (expandedKey: string): void => {
  //   setExpandOptions(
  //     (prevOptions: { user: boolean; product: boolean; order: boolean; settings: boolean }) => {
  //       const newOptions: { user: boolean; product: boolean; order: boolean; settings: boolean } = {
  //         ...prevOptions,
  //       };
  //       for (const key in newOptions) {
  //         if (Object.prototype.hasOwnProperty.call(newOptions, key)) {
  //           newOptions[key as keyof typeof newOptions] = key === expandedKey;
  //         }
  //       }
  //       return newOptions;
  //     }
  //   );
  // };

  const handleExpandState = (expandedKey: string): void => {
    setExpandOptions((prevOptions) => {
      const newOptions = { ...prevOptions };

      // Toggle the state of the clicked category
      newOptions[expandedKey as keyof typeof newOptions] =
        !newOptions[expandedKey as keyof typeof newOptions];

      // Collapse the other categories if the clicked category is expanded
      for (const key in newOptions) {
        if (Object.prototype.hasOwnProperty.call(newOptions, key) && key !== expandedKey) {
          newOptions[key as keyof typeof newOptions] = false;
        }
      }

      return newOptions;
    });
  };

  useEffect(() => {
    setRestaurantData(restaurant);
  }, [restaurant]);

  useEffect(() => {
    switch (window.location.pathname) {
      //gebruikers
      case '/admin/rightsmanagement':
      case '/admin/user_tags':
      case '/admin/changePassword':
        handleExpandState('user');
        break;
      //produkten
      case '/admin/foodCategory':
      case '/admin/articleoptionsName':
      case '/admin/articleoptions':
      case '/admin/tags':
      case '/admin/editArticle':
        handleExpandState('product');
        break;
      //bestelingen
      case '/admin/Orders':
      case '/admin/orders_overview':
        handleExpandState('order');
        break;
      //settings
      case '/admin/csvUpload':
      case '/admin/printers':
        handleExpandState('settings');
        break;
      //bewerk webshop
      case '/admin/generalView':
      case '/admin/paymentView':
      case '/admin/OrderConfirmation':
      case '/admin/WorkingHours':
        handleExpandState('restaurant');
        break;
    }
  }, []);

  const sideBarImgs = {
    dashboardImg:
      'https://res.cloudinary.com/updivision/image/upload/v1/media/Storage/Dashboard_yg1zgc',
    logoutImg:
      'https://res.cloudinary.com/updivision/image/upload/v1/media/Storage/Uitloggen_b0b5uc',
    usersImg:
      'https://res.cloudinary.com/updivision/image/upload/v1/media/Storage/Gebruikers_gehjtc',
    productsImg:
      'https://res.cloudinary.com/updivision/image/upload/v1/media/Storage/Producten_awmjks',
    ordersImg:
      'https://res.cloudinary.com/updivision/image/upload/v1/media/Storage/Bestellingen_ssse1c',
    promoCodesImg:
      'https://res.cloudinary.com/updivision/image/upload/v1/media/Storage/Promocodes_xbegwe',
    qrCodesImg:
      'https://res.cloudinary.com/updivision/image/upload/v1/media/Storage/QR_codes_egzqsf',
    printerImg:
      'https://res.cloudinary.com/updivision/image/upload/v1/media/Storage/Instellingen_hw5j8u',
    galleryImg:
      'https://res.cloudinary.com/updivision/image/upload/v1/media/Storage/Afbeeldingen_vdsyws',
    settingsImg:
      'https://res.cloudinary.com/updivision/image/upload/v1/media/Storage/Instellingen_hw5j8u',
    visitUrlImg:
      'https://res.cloudinary.com/updivision/image/upload/v1/media/Storage/Bezoek_jouw_website_ecbj76',
  };

  useEffect(() => {
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(sidebar.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
    return () => {
      if (navigator.platform.indexOf('Win') > -1) {
        ps.destroy();
      }
    };
  });

  const closeSidebar = () => {
    document.documentElement.classList.remove('nav-open');
  };

  const logout = () => {
    localStorage.removeItem('rest_id');
    localStorage.removeItem('jwt');
    localStorage.removeItem('users_list');
    localStorage.removeItem('current_user');

    axios
      .get(`${API_BASE_URL}/restaurant/api/logout/`)
      .catch((err) => console.log('Error is', err.message));
  };

  const onClickArticleOptions = () => {
    closeSidebar();
    setOpenOptions(!openOptions);
  };

  return (
    <div className="sidebar" data-color={props.bgColor} data-active-color={props.activeColor}>
      <div className="logo d-none d-sm-none d-md-block d-lg-block">
        <NavLink to="/admin/mainView" className="simple-text logo-normal">
          <img src={easyservedLogo} className="headerLogo" alt="" />
          {'\u00A0'}
          Restaurant paneel
        </NavLink>
      </div>
      <div className="sidebar-wrapper mt-5" ref={sidebar}>
        <Nav>
          <li onClick={closeSidebar}>
            <NavLink
              to="/admin/mainView"
              className="nav-link rounded border border-primary"
              activeClassName="active"
              id="dashboard_colorchange"
            >
              <img src={sideBarImgs.dashboardImg} alt="" width="40px" height="40px" />
              {'\u00A0'}
              {'\u00A0'}
              <span className="font-weight-bold" style={{ color: 'rgb(1, 1, 122)' }}>
                Dashboard
              </span>
            </NavLink>
          </li>
          <li onClick={closeSidebar}>
            <NavLink
              to="/admin/generalView"
              className={`nav-link rounded border border-primary 
              ${expandOptions.restaurant ? 'bg-primary' : ''}`}
              activeClassName="active"
              id="dashboard_colorchange"
              onClick={() => handleExpandState('restaurant')}
            >
              <img
                src="https://res.cloudinary.com/updivision/image/upload/v1/media/Storage/Bewerk_restaurant_hpzeka"
                alt=""
                width="40px"
                height="40px"
              />
              {'\u00A0'}
              {'\u00A0'}
              <span
                className={`font-weight-bold 
              ${expandOptions.restaurant ? 'text-white' : ''}`}
                style={{ color: 'rgb(1, 1, 122)' }}
              >
                Bewerk webshop
              </span>
            </NavLink>
          </li>
          {expandOptions.restaurant ? (
            <div>
              <li onClick={closeSidebar}>
                <NavLink
                  to="/admin/generalView"
                  className="nav-link"
                  activeClassName="active"
                  id="rightsmanagement_colorchange"
                >
                  <img src={line} alt="" width="40" height="2px" />
                  {'\u00A0'}
                  {'\u00A0'}
                  <span className="font-weight-bold">Algemeen</span>
                </NavLink>
              </li>
              <li onClick={closeSidebar}>
                <NavLink
                  to="/admin/OrderConfirmation"
                  className="nav-link"
                  activeClassName="active"
                  id="rightsmanagement_colorchange"
                >
                  <img src={line} alt="" width="40" height="2px" />
                  {'\u00A0'}
                  {'\u00A0'}
                  <span className="font-weight-bold">Bestelbevestiging</span>
                </NavLink>
              </li>
              <li onClick={closeSidebar}>
                <NavLink
                  to="/admin/WorkingHours"
                  className="nav-link"
                  activeClassName="active"
                  id="tags_colorchange"
                >
                  <img src={line} alt="" width="40px" height="2px" />
                  {'\u00A0'}
                  {'\u00A0'}
                  <span className="font-weight-bold">Openingsuren</span>
                </NavLink>
              </li>

              <li onClick={closeSidebar}>
                <NavLink
                  to="/admin/paymentView"
                  className="nav-link"
                  activeClassName="active"
                  id="rightsmanagement_colorchange"
                >
                  <img src={line} alt="" width="40" height="2px" />
                  {'\u00A0'}
                  {'\u00A0'}
                  <span className="font-weight-bold">Betaling</span>
                </NavLink>
              </li>
            </div>
          ) : null}
          <li onClick={closeSidebar}>
            <NavLink
              to="#"
              className={`nav-link rounded border border-primary 
              ${expandOptions.user ? 'bg-primary' : ''}`}
              activeClassName="active"
              id="dashboard_colorchange"
              onClick={() => handleExpandState('user')}
            >
              <img src={sideBarImgs.usersImg} alt="" width="40px" height="40px" />
              {'\u00A0'}
              {'\u00A0'}
              <span
                className={`font-weight-bold 
              ${expandOptions.user ? 'text-white' : ''}`}
                style={{ color: 'rgb(1, 1, 122)' }}
              >
                Gebruikers
              </span>
            </NavLink>
          </li>
          {/* onclick user options here */}
          {expandOptions.user ? (
            <div>
              <li onClick={closeSidebar}>
                <NavLink
                  to="/admin/rightsmanagement"
                  className="nav-link"
                  activeClassName="active"
                  id="rightsmanagement_colorchange"
                >
                  <img src={line} alt="" width="40" height="2px" />
                  {'\u00A0'}
                  {'\u00A0'}
                  <span className="font-weight-bold">Gebruikersbeheer</span>
                </NavLink>
              </li>
              <li onClick={closeSidebar}>
                <NavLink
                  to="/admin/user_tags"
                  className="nav-link"
                  activeClassName="active"
                  id="tags_colorchange"
                >
                  <img src={line} alt="" width="40px" height="2px" />
                  {'\u00A0'}
                  {'\u00A0'}
                  <span className="font-weight-bold">Gebruiker tags</span>
                </NavLink>
              </li>
              <li onClick={closeSidebar}>
                <NavLink
                  to="/admin/changePassword"
                  className="nav-link"
                  activeClassName="active"
                  id="changePassword"
                >
                  <img src={line} alt="" width="40" height="2px" />
                  {'\u00A0'}
                  {'\u00A0'}
                  <span className="font-weight-bold">Pas wachtwoord aan</span>
                </NavLink>
              </li>
            </div>
          ) : null}
          {/*---------------------------- */}
          <li onClick={closeSidebar}>
            <NavLink
              to="#"
              className={`nav-link rounded border border-primary 
              ${expandOptions.product ? 'bg-primary' : ''}`}
              activeClassName="active"
              id="dashboard_colorchange"
              onClick={() => handleExpandState('product')}
            >
              <img src={sideBarImgs.productsImg} alt="" width="40px" height="40px" />
              {'\u00A0'}
              {'\u00A0'}
              <span
                className={`font-weight-bold 
              ${expandOptions.product ? 'text-white' : ''}`}
                style={{ color: 'rgb(1, 1, 122)' }}
              >
                Producten
              </span>
            </NavLink>
          </li>
          {/* ONCLICK PRODUCT OPTIONS EXPAND HERE */}
          {expandOptions.product ? (
            <div>
              <li onClick={closeSidebar}>
                <NavLink
                  to="/admin/foodCategory"
                  className="nav-link"
                  activeClassName="active"
                  id="foodcategories_colorchange"
                >
                  <img src={line} alt="" width="40px" height="2px" />
                  {'\u00A0'}
                  {'\u00A0'}
                  <span className="font-weight-bold">Categoriën</span>
                </NavLink>
              </li>
              <li onClick={closeSidebar}>
                <NavLink
                  to="/admin/editArticle"
                  className="nav-link"
                  activeClassName="active"
                  id="editarticle_colorchange"
                >
                  <img src={line} alt="" width="40px" height="2px" />
                  {'\u00A0'}
                  {'\u00A0'}
                  <span className="font-weight-bold">Beheer producten</span>
                </NavLink>
              </li>
              <li onClick={onClickArticleOptions}>
                <NavLink
                  to="/admin/articleoptionsName"
                  className="nav-link"
                  activeClassName="active"
                  id="articleoptiongroup_colorchange"
                >
                  <img src={line} alt="" width="40px" height="2px" />
                  {'\u00A0'}
                  {'\u00A0'}
                  <span className="font-weight-bold">Productoptie groepen</span>
                </NavLink>
              </li>
              <li onClick={closeSidebar}>
                <NavLink
                  to="/admin/articleoptions"
                  className="nav-link"
                  activeClassName="active"
                  id="articleoption_colorchange"
                >
                  <img src={line} alt="" width="40px" height="2px" />
                  {'\u00A0'}
                  {'\u00A0'}
                  <span className="font-weight-bold">Beheer productopties</span>
                </NavLink>
              </li>
              <li onClick={closeSidebar}>
                <NavLink
                  to="/admin/tags"
                  className="nav-link"
                  activeClassName="active"
                  id="tags_colorchange"
                >
                  <img src={line} alt="" width="40px" height="2px" />
                  {'\u00A0'}
                  {'\u00A0'}
                  <span className="font-weight-bold">Tags</span>
                </NavLink>
              </li>
            </div>
          ) : null}
          {/* ----------------------------------- */}
          <li onClick={closeSidebar}>
            <NavLink
              to="#"
              className={`nav-link rounded border border-primary 
              ${expandOptions.order ? 'bg-primary' : ''}`}
              activeClassName="active"
              id="dashboard_colorchange"
              onClick={() => handleExpandState('order')}
            >
              <img src={sideBarImgs.ordersImg} alt="" width="40px" height="40px" />
              {'\u00A0'}
              {'\u00A0'}
              <span
                className={`font-weight-bold 
              ${expandOptions.order ? 'text-white' : ''}`}
                style={{ color: 'rgb(1, 1, 122)' }}
              >
                Bestellingen
              </span>
            </NavLink>
          </li>
          {/* EXPAND ORDER OPTIONS HERE */}
          {expandOptions.order ? (
            <div>
              <li onClick={closeSidebar}>
                <NavLink
                  to="/admin/Orders"
                  className="nav-link"
                  activeClassName="active"
                  id="orders_colorchange"
                >
                  <img src={line} alt="" width="40" height="2px" />
                  {'\u00A0'}
                  {'\u00A0'}
                  <span className="font-weight-bold">Bestellingen</span>
                </NavLink>
              </li>
              <li onClick={closeSidebar}>
                <NavLink
                  to="/admin/orders_overview"
                  className="nav-link"
                  activeClassName="active"
                  id="orders_overview_colorchange"
                >
                  <img src={line} alt="" width="40" height="2px" />
                  {'\u00A0'}
                  {'\u00A0'}
                  <span className="font-weight-bold">Statistieken</span>
                </NavLink>
              </li>
            </div>
          ) : null}
          {/* ------------------------- */}
          <li onClick={closeSidebar}>
            <NavLink
              to="/admin/promocodes"
              className="nav-link rounded border border-primary"
              activeClassName="active"
              id="promocodes_colorchange"
            >
              <img src={sideBarImgs.promoCodesImg} alt="" width="40px" height="40px" />
              {'\u00A0'}
              {'\u00A0'}
              <span className="font-weight-bold" style={{ color: 'rgb(1, 1, 122)' }}>
                Promocodes
              </span>
            </NavLink>
          </li>
          <li onClick={closeSidebar}>
            <NavLink
              to={`/admin/qrdonwload/${rest_id}`}
              className="nav-link rounded border border-primary"
              activeClassName="active"
              id="restaurant_qrdownload"
            >
              <img src={sideBarImgs.qrCodesImg} alt="" width="40px" height="40px" />
              {'\u00A0'}
              {'\u00A0'}
              <span className="font-weight-bold" style={{ color: 'rgb(1, 1, 122)' }}>
                Qr codes
              </span>
            </NavLink>
          </li>
          <li onClick={closeSidebar}>
            <NavLink
              to="#"
              className={`nav-link rounded border border-primary 
              ${expandOptions.settings ? 'bg-primary' : ''}`}
              activeClassName="active"
              id="restaurant_qrdownload"
              onClick={() => handleExpandState('settings')}
            >
              <img src={sideBarImgs.settingsImg} alt="" width="40px" height="40px" />
              {'\u00A0'}
              {'\u00A0'}
              <span
                className={`font-weight-bold 
              ${expandOptions.settings ? 'text-white' : ''}`}
                style={{ color: 'rgb(1, 1, 122)' }}
              >
                Instellingen
              </span>
            </NavLink>
          </li>
          {/* EXPAND SETTINGS HERE */}
          {expandOptions.settings ? (
            <div>
              <li onClick={closeSidebar}>
                <NavLink
                  to="/admin/csvUpload"
                  className="nav-link"
                  activeClassName="active"
                  id="csv_colorchange"
                >
                  <img src={line} alt="" width="40" height="2px" />
                  {'\u00A0'}
                  {'\u00A0'}
                  <span className="font-weight-bold">CSV Upload</span>
                </NavLink>
              </li>
              <li onClick={closeSidebar}>
                <NavLink
                  to="/admin/printers"
                  className="nav-link"
                  activeClassName="active"
                  id="printers_colorchange"
                >
                  <img src={line} alt="" width="40" height="2px" />
                  {'\u00A0'}
                  {'\u00A0'}
                  <span className="font-weight-bold">Printerinstellingen</span>
                </NavLink>
              </li>
              {/* <li onClick={closeSidebar}>
                <NavLink
                  to="/admin/finalscreen"
                  className="nav-link"
                  activeClassName="active"
                  id="finalscreen_colorchange"
                >
                  <img src={line} alt="" width="40" height="2px" />
                  {'\u00A0'}
                  {'\u00A0'}
                  <span className="font-weight-bold">Eindscherm</span>
                </NavLink>
              </li> */}
            </div>
          ) : (
            ''
          )}
          {/* -------------------- */}
          <li onClick={closeSidebar}>
            <NavLink
              to="/admin/gallery"
              className="nav-link rounded border border-primary"
              activeClassName="active"
              id="gallery_colorchange"
            >
              <img src={sideBarImgs.galleryImg} alt="" width="40px" height="40px" />
              {'\u00A0'}
              {'\u00A0'}
              <span className="font-weight-bold" style={{ color: 'rgb(1, 1, 122)' }}>
                Afbeeldingen
              </span>
            </NavLink>
          </li>
          <li onClick={closeSidebar}>
            <NavLink
              to={{ pathname: `${MAIN_APP_URL}/restaurant/${restaurantData.slug}` }}
              target="_blank"
              className="nav-link rounded border border-primary"
              activeClassName="active"
              id="gallery_colorchange"
            >
              <img src={sideBarImgs.visitUrlImg} alt="" width="40px" height="40px" />
              {'\u00A0'}
              {'\u00A0'}
              <span className="font-weight-bold" style={{ color: 'rgb(1, 1, 122)' }}>
                Visit Your URL!
              </span>
            </NavLink>
          </li>
          <li onClick={logout}>
            <NavLink
              to="/auth/login"
              className="nav-link rounded border border-primary"
              activeClassName="active"
              id="logout_colorchange"
            >
              <img src={sideBarImgs.logoutImg} alt="" width="40" height="40px" />
              {'\u00A0'}
              {'\u00A0'}
              <span className="font-weight-bold" style={{ color: 'rgb(1, 1, 122)' }}>
                Logout
              </span>
            </NavLink>
          </li>
        </Nav>
      </div>
    </div>
  );
};
export default Sidebar;
