import React, { useState, useEffect } from 'react';
import {
  Modal, ModalBody, ModalHeader, ModalFooter,
} from 'reactstrap';
import { toast } from 'react-toastify';
import InputField from '../../Components/InputField';
import { ITablesLocation } from '../../api/DTO/tablesLocation';
import { API } from '../../api';
import Button from '../../Components/Button';

interface IProps {
  tablesLocationId: number | null;
  restId: number;
  locationModalIsOpen: boolean;
  handleLocationModalToggle: () => void;
  handleUpdatePage: () => void;
}

export function TablesLocationsModal(props: IProps) {
  const {
    locationModalIsOpen, handleLocationModalToggle, tablesLocationId, restId, handleUpdatePage,
  } = props;

  const [tablesLocation, setTablesLocation] = useState<ITablesLocation>(
    {
      id: -1, name: '', restaurant: restId,
    },
  )
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    let mounted = true
    if (tablesLocationId) {
      setIsLoading(true)
      API.restaurantApi.getTablesLocationDetail(tablesLocationId)
        .then((responseTablesLocation) => {
          if (!mounted) return
          setIsLoading(false)
          responseTablesLocation && setTablesLocation(responseTablesLocation)
        })
    }

    return () => {
      mounted = false
      setTablesLocation({
        id: -1, name: '', restaurant: restId,
      })
    }
  }, [tablesLocationId])

  // handlers
  const handleCloseModal = () => {
    handleLocationModalToggle()
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTablesLocation({ ...tablesLocation, [event.target.name]: event.target.value });
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const { id, ...EditedTablesLocationData } = tablesLocation
    API.restaurantApi.putTablesLocation(id, EditedTablesLocationData)
      .then((response) => {
        if (response) toast.success('Location updated', { containerId: 'B' })
        handleCloseModal()
        handleUpdatePage()
      })
      .catch(() => {
        toast.error('Location update fail', { containerId: 'B' })
      })
  }

  return (
    <Modal isOpen={locationModalIsOpen} toggle={handleCloseModal}>
      <ModalHeader toggle={handleCloseModal}>
        Locatie Bewerken
      </ModalHeader>
      <form onSubmit={handleSubmit}>
        <ModalBody>
          {!isLoading
            ? (
              <>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-group text-left">
                      <label htmlFor="name" className="small">
                        Naam
                      </label>
                      <InputField
                        required
                        placeholder="naam"
                        name="name"
                        value={tablesLocation.name}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div>Loading...</div>
            )}
        </ModalBody>
        <ModalFooter>
          <Button name="Cancel" btnLongWidth={false} onClick={handleCloseModal} type="button" />
          <Button
            name="Save"
            type="submit"
            dangerButton={true}
            btnLongWidth={false}
          />
        </ModalFooter>
      </form>
    </Modal>
  );
}
