import { Route, Redirect, RouteProps } from 'react-router-dom';

const checkIfAllowed = (destination: any) => {
  const not_parsed_user = localStorage.getItem('current_user');
  let current_user = null;
  if (not_parsed_user) {
    current_user = JSON.parse(not_parsed_user);
  }
  if (!current_user) {
    return false;
  }

  // @ts-ignore
  if (current_user.is_owner) return true;
  // @ts-ignore
  let allowed_urls = current_user.allowed_urls;
  allowed_urls = allowed_urls.map((item: any) => item.url_name.toLowerCase());

  const destination_slug = destination.split('/')[2].toLowerCase();

  if (allowed_urls.includes(destination_slug)) {
    return true;
  }

  return false;
};

function PrivateRoute({ component: Component, ...rest }: any) {
  return (
    <Route
      {...rest}
      render={(props) =>
        // @ts-ignore
        checkIfAllowed(rest.location.pathname) ? (
          <Component {...props} />
        ) : (
          <Redirect to="/admin/forbidden" />
        )
      }
    />
  );
}

export default PrivateRoute;
