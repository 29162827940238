import { API } from '../../../api';

const getChainRestaurants = async (chain_id: any, setChainRestaurants: (data: any) => void) => {
  API.siteApi
    .getAllRestaurants(chain_id)
    .then((res: any) => {
      setChainRestaurants(res.data);
    })
    .catch((err) => {
      return null;
    });
};

export default getChainRestaurants;
