import React from 'react';
import { API_BASE_URL } from '../../services/urls';

interface StarTabProps {
  printer_url: string;
  printersList: any[];
  handleActivityStatus: (id: string) => void;
  getActivityStatus: (id: string) => {};
  toggle: (id: string, printer: any) => void;
  toggleStarEditModal: (id: string, printer: any) => void;
  deleteprinter: (id: string) => {};
}

const StarTab = ({
  printer_url,
  printersList,
  handleActivityStatus,
  getActivityStatus,
  toggle,
  toggleStarEditModal,
  deleteprinter,
}: StarTabProps) => {
  return (
    <div className="col-xl-10 offset-xl-1 col-lg-8 offset-lg-3 col-md-8 offset-md-4 col-sm-12 col-12 text-center">
      <span className="h4 mb-3">Printerinstellingen StarPrint</span>
      <br />
      <span className="h5 mb-3">Your URL to add printers</span>{' '}
      <a
        className="h5 mb-3"
        target="_blank"
        href="https://www.star-m.jp/products/s_print/CloudPRNTSDK/Documentation/en/developerguide/clientconfiguration.html"
      >
        <i className="fa fa-info-circle"></i>
      </a>
      <br />
      <span className="h6 mb-3" title="Server URL CloudPRNT setting for your printer">
        {API_BASE_URL}
        {printer_url}
      </span>
      <div className="table-responsive mt-4">
        <table className="table table-striped table-bordered">
          <thead>
            <tr>
              <th className="small">ID</th>
              <th className="small">Name</th>
              <th className="small">Unique ID</th>
              <th className="small">Actie</th>
            </tr>
          </thead>
          <tbody>
            {printersList &&
              printersList.length > 0 &&
              printersList?.map((printer: any) => {
                return (
                  <tr key={printer.id}>
                    <td>{printer.id}</td>
                    <td>{printer.name}</td>
                    <td>{printer.unique_id}</td>
                    <td>
                      <button
                        className="btn btn-primary btn-sm mr-3"
                        onClick={() => handleActivityStatus(printer.id)}
                      >
                        {getActivityStatus(printer.id) ? 'On' : 'Off'}
                      </button>
                      <i
                        className="fa fa-info-circle cursr"
                        aria-hidden="true"
                        id={printer.id}
                        onClick={() => toggle(printer.id, printer)}
                      />
                      <i
                        className="fa fa-pencil pl-2 cursr"
                        aria-hidden="true"
                        id={printer.id}
                        onClick={() => toggleStarEditModal(printer.id, printer)}
                      />
                      <i
                        className="fa fa-trash pl-2 cursr"
                        aria-hidden="true"
                        onClick={() => deleteprinter(printer.id)}
                        id={printer.id}
                      />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default StarTab;
